<template>
    <v-breadcrumbs v-if="isOkToShow()" :items="section" bg-color="#ffffff" style="position: sticky;top: 70px; z-index: 3;">
        <template v-slot:title="{ item }">
            <span @click="Openmenu" class="breadcrump title-case" 
            >
                {{ item.title }}
            </span>
        </template>
    </v-breadcrumbs>
</template>

<script>
import IssueService from '@/api/service/Issue/IssueService'

export default {
    data() {
        return {
            section: [],
            issueSection: []
        }
    },
    computed: {
        isRefreshed() {
            return this.$store.getters.getRefreshBreadCrump
        },
        gettingRoute(){
            return this.$route.query.id
        }
    },
    watch: {
        isRefreshed() {
            this.refreshSection()
        },
        gettingRoute(){
            this.refreshSection()
        }

    },

    created() {
        this.refreshSection()
    },
    methods: {
        async refreshSection() {
            let $wsDetails = localStorage.getItem("_WS_DEFAULTS_")
            if ($wsDetails) {
                $wsDetails = JSON.parse($wsDetails)
                console.log($wsDetails,'fffff')
                this.section = []
                if ($wsDetails.workspace) {
                    this.section.push({
                        title: $wsDetails.workspace.name,
                        disabled: false,
                        href: '/showProjectForWorkspaces'
                    })
                }
                if ($wsDetails.project) {
                    this.section.push({
                        title: $wsDetails.project.name,
                        disabled: false,
                        //href: '/showCategoryForWorkspaces?workSpaceId='+$wsDetails.workspace.id+"&accountId="+$wsDetails.account.id+"&projectId="+$wsDetails.project.id
                        href: '/showAccountForWorkspaces'
                    })
                }
                if ($wsDetails.account) {
                    this.section.push({
                        title: $wsDetails.account.name,
                        disabled: false,
                        href: '/issueConsolidated'
                    })
                }
               
                if ($wsDetails.category) {
                    this.section.push({
                        title: $wsDetails.category.name,
                        disabled: false,
                        href: '/issueList?workSpaceId=' + $wsDetails.workspace.id + "&accountId=" + $wsDetails.account.id + "&projectId=" + $wsDetails.project.id + "&categoryId=" + $wsDetails.categoryId
                    })
                }
                if (this.$route.query.id) {
                    this.issueSection =[]
                    this.issueSection =[...this.issueSection]
                    await this.refreTheIssueData(this.$route.query.id)
                }
                // if($wsDetails.issue) {
                //     this.section.push({
                //         title: $wsDetails.issue.name,
                //         disabled: false,
                //         href: '/issueView2?id='+$wsDetails.issue.id,
                //         bold:true
                //     })
                // }
                // if($wsDetails.child_issue) {
                //     this.section.push({
                //         title: $wsDetails.child_issue.name,
                //         disabled: false,
                //         href: '/issueView2?id='+$wsDetails.child_issue.id,
                //         bold:true
                //     })
                // } 
            }
            if(this.section.length > 0){
                this.section[this.section.length-1].disabled =true
            }
            this.$nextTick(() => {
                this.$store.commit("SET_REFRESH_BREADCRUMP", false)
            });

        },
        async getIssueData(id) {
            try {
                let res = await IssueService.findOne(id)
                return res.length > 0 ? res[0] : null
            }
            catch (e) {
                return null
            }
        },
       async  refreTheIssueData(id) {
            // alert(id)
            let opt = await this.getIssueData(id)
            if (opt) {
                this.issueSection.unshift({
                    title: opt.reference_num,
                    disabled: false,
                    href: '/issueView2?id=' + opt.id,
                    bold: true
                })
                if(opt.parent_issue_id){
                    let parentData =await this.getIssueData(opt.parent_issue_id)
                this.issueSection.unshift({
                    title: parentData.reference_num,
                    disabled: false,
                    href: '/issueView2?id=' + parentData.id,
                    bold: true
                })
                }
                for(let i=0;i<this.issueSection.length;i++){
                    let result =this.section.some(k=>k && k.title && k.title.toLowerCase()==this.issueSection[i].title.toLowerCase())
                    // alert(result)
                    if(!result){
                        this.section.push(this.issueSection[i])
                    }
                

                }


            }
        },
        isOkToShow() {
            if (
                this.$route.path.includes("/issue") ||
                this.$route.path.includes("/backlog") ||
                this.$route.path.includes("/board") ||
                this.$route.path.includes("/addQuickIssue") ||
                this.$route.path.includes("/teamDashBoard")||
                this.$route.path.includes("showProjectForWorkspaces") ||
                this.$route.path.includes("showAccountForWorkspaces")) {


                return true;
            }
            else {
                return false;
            }
        }
    }
}
</script>
<style>
.breadcrump {
    font-size: 13px;
    color: #636363;
    text-decoration: none !important;
}
</style>