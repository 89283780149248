<template>
  <div>

    <v-card flat class="pa-0" style="overflow: auto; height: 100%;min-height: 66vh;">
      <div class="cardTitle pl-3 pt-2">
        Time Sheets
      </div>
      <v-tabs v-model="tab">
        <v-tab value="tab-1"> By Date </v-tab>

        <v-tab value="tab-2"> By Account </v-tab>
      </v-tabs>
      <v-card-text class="pt-0">
        <v-window v-model="tab">
          <v-window-item value="tab-1">
            <div v-if="timeData.byDate && timeData.byDate.length > 0">
              <v-card flat v-for="(item, index) in timeData.byDate" :key="index" class="" density="compact">
                <v-row density="compact" class="pa-0 pt-4 ma-0 title"
                  style="display: flex; justify-content: space-between">
                  <v-card-subtitle class="px-0 mb-2">
                    {{
                      moment(item.entry_date).format("MM/DD/YY")
                    }}</v-card-subtitle>
                  <v-card-subtitle class="px-0 mb-2">
                    <!-- {{ timeConvert(item && item.total_hrs ? item.total_hrs : 0)  }} -->
                    {{ item && item.total_hrs ? item.total_hrs.toFixed(2) : 0 }} Minutes
                  </v-card-subtitle>
                </v-row>

                <v-row class="pa-0 my-0" density="compact" v-for="(value, index) in JSON.parse(item.groupData)"
                  :key="index">
                  <v-card-text class="pa-0" style="display: flex" density="compact">
                    <v-col cols="4" class="py-2 start">
                      <div class="chip" v-bind:style="{
                        backgroundColor: getBgColors(value.tasks),
                        color: getColors(value.tasks),
                        // border: '1px solid ' + getBrdrColors(value.tasks),
                      }">
                        <div class="task-text">
                          {{
                            value && value.tasks
                            ? value.tasks.toUpperCase()
                            : ""
                          }}
                        </div>
                        <v-tooltip activator="parent" width="6rem" content-class="chipTAsk" location="start">{{
                          value.tasks }}</v-tooltip>
                      </div>
                    </v-col>
                    <v-col cols="4" style="" class="py-2 px-0 start">
                      {{ value && value.startDate && moment((value.startDate)).format(
                        "hh:mm A") != 'Invalid date'
                        ? moment((value.startDate)).format(
                          "hh:mm A"
                        )
                        : "00-00"
                      }}-
                      {{ value && value.endDate && moment((value.endDate)).format(
                        "hh:mm A") != 'Invalid date'
                        ? moment((value.endDate)).format(
                          "hh:mm A"
                        )
                        : "00-00"
                      }}
                    </v-col>
                    <v-col cols="4" class="py-2 end">{{ value && value.actualHrs ? value.actualHrs.toFixed(2) : 0 }}
                      Minutes
                    </v-col>
                  </v-card-text>
                  <v-col cols="12" class="py-0 start1" v-if="value.issueRefNum != null">
                    {{ value.issueRefNum }} | {{ value.categoryName }} | {{ value.subCategoryName }}
                  </v-col>
                  <v-divider class="divider"></v-divider>
                </v-row>
              </v-card>
            </div>
            <v-row v-else style="display: flex;justify-content: center;align-items: center;">
              <span class="pa-5">There is no timesheet Data </span>
            </v-row>
          </v-window-item>

          <v-window-item value="tab-2">
            <v-card flat v-for="(item, index) in timeData.byAccounts" :key="index" class="" density="compact">
              <v-row density="compact" class="pa-0 pt-4 ma-0 title" style="display: flex; justify-content: space-between">
                <v-card-subtitle class="px-0 mb-2">
                  {{ item.account_name }}</v-card-subtitle>
                <v-card-subtitle class="px-0 mb-2">
                  <!-- {{ timeConvert(item && item.total_hrs ? item.total_hrs : 0)  }} -->
                  {{ item && item.total_hrs ? item.total_hrs.toFixed(2) : 0 }} Minutes
                </v-card-subtitle>
              </v-row>

              <v-row class="pa-0 my-0" density="compact" v-for="(value, index) in JSON.parse(item.groupData)"
                :key="index">
                <v-card-text class="pa-0" style="display: flex" density="compact">
                  <v-col cols="4" class="py-2 start">
                    <div class="chip" v-bind:style="{
                      backgroundColor: getBgColors(value.tasks),
                      color: getColors(value.tasks),
                      // border: '1px solid ' + getBrdrColors(value.tasks),
                    }">
                      <div class="task-text">
                        {{
                          value && value.tasks
                          ? value.tasks.toUpperCase()
                          : ""
                        }}
                      </div>
                      <v-tooltip activator="parent" width="6rem" content-class="chipTAsk" location="start">{{ value.tasks
                      }}</v-tooltip>
                    </div>
                  </v-col>
                  <v-col cols="4" style="" class="py-2 px-0 start">{{ value && value.startDate &&
                    moment((value.startDate)).format(
                      "hh:mm A") != 'Invalid date'
                    ? moment((value.startDate)).format(
                      "hh:mm A"
                    )
                    : "00-00"
                  }} -
                    {{ value && value.endDate && moment((value.endDate)).format(
                      "hh:mm A") != 'Invalid date'
                      ? moment((value.endDate)).format(
                        "hh:mm A"
                      )
                      : "00-00"
                    }}</v-col>
                  <v-col cols="4" class="py-2 end">{{ value && value.actualHrs ? value.actualHrs.toFixed(2) : 0 }} Minutes
                  </v-col>
                </v-card-text>
                <v-col cols="12" class="py-0 start1" v-if="value.issueRefNum != null">
                  {{ value.issueRefNum }} | {{ value.categoryName }} | {{ value.subCategoryName }}
                </v-col>
                <v-divider class="divider"></v-divider>
              </v-row>
            </v-card>
          </v-window-item>
        </v-window>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import moment from "moment";
import commonService from '@/common.service';
import TimeSheetService from "@/api/service/TimeSheet/TimeSheetService";
export default {
  props: ["timeSheetData", "userId", "fromDate", "toDate"],
  data() {
    return {
      timeData: [],
      tab: null,
    };
  },
  watch: {
    reloadValue() {
      if (this.reloadValue) {
        this.getTimeData(this.userId);
      } else if (!this.$store.state.hideTimer) {
        this.getTimeData(this.userId);
      }
    },
    fromDate() {
      if (this.fromDate) {
        this.getTimeData(this.userId)
      }
    },
    toDate() {
      if (this.toDate) {
        this.getTimeData(this.userId)
      }
    },
    timeSheetData() {
      if (this.timeSheetData) {
        console.log(this.timeSheetData, 'alert3333')
      }
    },
    userId() {
      if (this.userId) {

        this.getTimeData(this.userId);
      }
    },

    getRefresh() {
      if (this.getRefresh) {
        this.getTimeData(this.userId);
      }
      else {
        this.getTimeData(this.userId);
      }
    }
  },
  mounted() {
    console.log(this.timeSheetData, 'kkkkkaaaasss')
  },
  created() {
    this.getTimeData(this.userId);
  },
  computed: {
    reloadValue() {
      return this.$store.state.hideTimer;
    },
    getRefresh() {
      return this.$store.getters.getRefreshTimeSheet;
    },
  },
  methods: {
    // timeConvert(hr) {
    //   if (hr) {
    //     let minutes = (hr) * 60;
    //     minutes = minutes.toFixed(1)
    //     return minutes + " minute(s).";
    //   }
    // },
    moment,

    async getTimeData(id) {
      console.log(this.fromDate,'dateDuration', this.toDate)
      let res = await TimeSheetService.getGroupByData({ userId: id, from_date: this.fromDate, to_date: this.toDate });
      console.log(res, 'alert4444')
      this.timeData = res;
      this.timeData.byDate = await commonService.getSort(res.byDate, 'entry_date', 'desc');

      if (this.timeData.byDate) {
        this.sortGroupDataByStartDateDescending(this.timeData.byDate);
      }

      this.timeData.byAccounts = await commonService.getSort(res.byAccounts, 'entry_date', 'desc');
      if (this.timeData.byAccounts) {
        this.sortGroupDataByStartDateDescending(this.timeData.byAccounts)
      }
    },

    sortGroupDataByStartDateDescending(data) {
      data.forEach((entry) => {
        if (entry.groupData) {
          const groupData = JSON.parse(entry.groupData);
          groupData.sort((a, b) => {
            const startDateA = new Date(a.startDate);
            const startDateB = new Date(b.startDate);
            return startDateB - startDateA;
          });
          entry.groupData = JSON.stringify(groupData);
        }
      });
    },


    formatDate(dateString, type) {
      let inputDate = new Date(dateString + " UTC");
      let convertedDateString = inputDate;
      if (!convertedDateString) {
        return ''
      }
      else if (type == 'date') {
        const date = new Date(convertedDateString);
        return date.toLocaleDateString()// moment(date).utcOffset('+05:30')
      }
      else {
        const date = new Date(convertedDateString);
        //return moment(date).toLocal()
        return date.toLocaleString();
      }
    },

    getBgColors(name) {
      if (name == "Login") return "#C8FFFF";
      else if (name == "LogOut") return "#FFABDD";
      else if (name == "Meeting") return "#E2FCE9";
      else if (name == "Break") return "#FFF1E9";
      else return "#F0ECFF ";
    },
    getColors(name) {
      if (name == "Login") return "#046161";
      else if (name == "LogOut") return "#F5189D";
      else if (name == "Meeting") return "#149938";
      else if (name == "Break") return "#9C3B03";
      else return "#3312BF ";
    },
    getBrdrColors(name) {
      if (name == "Login") return "#15E9E9";
      else if (name == "LogOut") return "#FFABDD";
      else if (name == "Meeting") return "#48FC79";
      else if (name == "Break") return "#F39D6C";
      else return "#3312BF ";
    },
  },
};
</script>

<style scoped>
.v-tab.v-tab {
  --v-btn-height: var(--v-tabs-height);
  min-width: 90px;
  margin-left: 14px;
  height: 70%;
  margin-top: 13px;
}

.title {
  font-size: 14px;
}

.start {
  text-align: start;
  font-size: 11px;
  /* font-weight: 500; */
}

.cardTitle {
  color: #272727;
  font-size: 18px;
  font-weight: 700;
}

.start1 {
  position: relative;
  bottom: 5px;
  text-align: start;
  font-size: 12px;
  /* font-weight: 500; */
}

.end {
  text-align: end;
  font-size: 12px;
}

.chip {
  padding: 3px 4px;
  border-radius: 4px;
  height: fit-content;
  width: fit-content;
  display: flex;
  align-items: center;
  font-size: 9px;
  font-weight: 600;
}

.task-text {
  /* white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis;
  /* max-width: 13ch; */
}

.divider {
  border-top: 1px dotted #000000;
  opacity: 0.5;
}

>>>.chipTAsk {
  opacity: 0.8;
  font-size: 0.575rem;
}
</style>
