<template>
  <div class="pr-0">
    <v-row class="pt-9 pb-5 pl-3"><v-text class="sub-title">Comments</v-text></v-row>
    <div ref="commentsContainer">
      <!-- <QuillEditor :data="mentionValues" :content="item.description" :showReadOnly=false editorStyle="'min-height:75px;width:800px'"
          @inputContent="($event) => {
            item.description = $event;
          }" /> -->
      <TextArea autofocus v-if="showTextArea" :data="comment" :showReadOnly="showReadOnly"
        :editorStyle="'min-height:75px'" @textData="($event) => {
          comment = $event;
        }
          "></TextArea>
    </div>
    <TextFieldComponent v-if="!showTextArea" @focus="() => {
      showTextArea = true;
      showSaveButton;
    }" @input="showSaveButton" class="pt-2" placeholder="Add a commment" v-model="comment" />
    <div class="mr-3">
      <v-row class="my-2" justify="start" style="background-color: inherit">
        <div class="pt-3">
          <v-btn v-if="comment && comment.length > 0" class="mr-6 ml-3 save" type="submit"
            @click="saveTheComments()">Save</v-btn>
        </div>

        <div class="pt-3">
          <v-btn v-if="comment && comment.length > 0" @click="cancel()" class="mr-6 cancel">Cancel</v-btn>
        </div>

        <v-col cols="2">
          <uploadFiles v-if="showTextArea && comment && comment.length > 0" @documentsData="documentsData($event)"
            :command="true" keyType="IS" :keyId="issueId" />
        </v-col>
      </v-row>

      <v-row style="margin-left: 0%; margin: 1%" v-if="attachmentData && attachmentData.length > 0">
        <v-cols v-for="(file, index) in attachmentData" :key="index">
          <v-chip class="selectedChip" :close="true" closable close-icon="mdi-close-circle"
            close-transition="scale-transition" @click="remove(index)">
            <span @click="downloadDoc(file)">{{ file.doc_name }}</span>
          </v-chip>
        </v-cols>
      </v-row>
    </div>
    <v-row v-for="item in commentData" :key="item">
      <div v-if="item.showEditor" style="width: 800px">

        <!-- <QuillEditor :data="mentionValues" :content="item.description" :showReadOnly=false editorStyle="'min-height:75px;width:800px'"
          @inputContent="($event) => {
            item.description = $event;
          }" >
        </QuillEditor> -->

        <TextArea :showHite="true" autofocus v-if="item.showEditor" :data="item.description"
          :editorStyle="'min-height:75px;width:800px'" @textData="($event) => {
            item.description = $event;
          }
            ">
          </TextArea>

        <div>
          <v-row class="my-2" justify="start" style="background-color: inherit">
            <v-col cols="12">
              <v-btn class="mr-6 save" type="submit" @click="updateComment(item)">Save</v-btn>
              <v-btn @click="cancel1()" class="mr-6 cancel">Cancel</v-btn>
              <uploadFiles v-if="showTextArea && comment.length > 0" @documentsData="documentsData($event)"
                :command="true" :showHite="true" keyType="IS" :keyId="issueId" />
            </v-col>
          </v-row>
        </div>
      </div>
      <div width="auto" class="mt-5" flat v-if="!item.showEditor && item.description != null">
        <div class="pb-0 ml-5" style="display: flex">
          <span>
            <CustomAvatar2 :fName="item.first_name" :lName="item.last_name" :theme="item.theme" :imgUrl="item.image_url"
              :size="'medium'" />

          </span>

          <span class="ml-2">
            <span class="commentUsername">
              {{ getUserName(item) }}
            </span>

            <span class="modifiedDate">
              {{
                item.modified
                ? dayjs(item.modified).fromNow()
                : null
              }}
            </span>

            <span v-if="item.showEditIcon" class="editBtn">
              <v-btn variant="text" class="btn-color" @click="item.showEditor = !item.showEditor">
                <img src="https://synergy-snaps.s3.ap-south-1.amazonaws.com/public/icons/edit.svg"
                  style="width: 16px; height: 20px" />
              </v-btn>
            </span>
          </span>
        </div>

        <div class="comment bottom">
          <v-card-text v-html="item.description" class="commentDescription pr-2 pt-0">
          </v-card-text>
        </div>

        <!-- <v-card-actions class="commentCardActions ml-10" >
          
        </v-card-actions> -->
      </div>
      <!-- <v-divider color="success"></v-divider> -->
    </v-row>
    <v-row>
      <v-col class="view-all" @click="ViewAll()" v-if="showViewAll">
        <span>View more </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="view-all" @click="ViewLess()" v-if="!showViewAll && this.commentData.length > 3">
        <span>View Less</span>
      </v-col>
    </v-row>

  </div>
</template>

<script>
import CustomAvatar2 from "@/components/ui/CustomAvatar2.vue";
import dayjs from "dayjs";
// import { QuillEditor } from "frontendcomponent";
import TextArea from "@/components/ui/TextArea.vue";
import TextFieldComponent from "@/components/TextFieldComponent.vue";
import ForumService from "@/api/service/Forum/ForumService";
import documentService from "@/api/service/Document/DocumentService";
import uploadFiles from "@/components/uploadFiles.vue";
import IssueService from "@/api/service/Issue/IssueService";
var relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);
export default {
  props: ["issueId"],
  components: {
    CustomAvatar2,
    // QuillEditor,
    // Avatar,
    uploadFiles,
    TextFieldComponent,
    TextArea,
  },
  data() {
    return {
      showTextArea: false,
      comment: "",
      showButton: false,
      commentData: [],
      userData: {},
      showViewAll: false,
      attachmentId: [],
      attachmentData: [],
      displayData: [
        {
          id: 1,
          showEditor: false,
          modified: new Date(),
          description: "Testing for the display",
          users: {
            first_name: "Karpaga",
            last_name: "Moorthy",
            username: "KarpagaMoorthy",
            image_profile_id: null,
            theme: "blue",
          },
        },
      ],
    };
  },
  watch: {
    comment() {
      this.showButton;
    },
    issueId() {
      this.getForumData();
    },
  },
  created() {
    this.userData = JSON.parse(localStorage.getItem("user_details"));
    if (this.issueId) {
      this.getForumData();
    }
  },
  beforeMount() {
    document.addEventListener("click", this.handleOutsideClick);
  },
  mounted() {
    document.addEventListener("click", this.handleOutsideClick);
  },
  methods: {
    dayjs,
    getUserName($item) {
      return $item.first_name && $item.last_name
        ? `${$item.first_name} ${$item.last_name}`
        : $item.first_name;
    },
    async updateComment($item) {
      let $obj = {};
      $obj.id = $item.id;
      $obj.description = $item.description;
      await ForumService.update($obj);
      $item.showEditor = !$item.showEditor;
    },
    getAvatar() {
      let first_name =
        this.issueData && this.issueData.first_Name
          ? this.issueData.first_Name[0]
          : "";
      let last_name =
        this.issueData && this.issueData.last_name
          ? this.issueData.last_name[0]
          : "";
      return first_name + last_name;
    },

    formatDate(dateString, type) {
      if (!dateString) {
        return "";
      } else if (type == "date") {
        const date = new Date(dateString);
        return date.toLocaleDateString();
      } else {
        const date = new Date(dateString);
        // alert(date)
        return date.toLocaleString();
      }
    },

    downloadDoc(item) {
      documentService.download({ id: item.id }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("name", "downloadLink");
        link.setAttribute("download", response.headers["x-suggested-filename"]); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    },

    documentsData($event) {
      this.attachmentId.push($event.id);
      this.getAttachementData();
    },

    remove(index) {
      if (index > -1) {
        this.attachmentId.splice(index, 1);
      }
    },

    async getAttachementData() {
      try {
        let res = await documentService.getAllDocuments({
          doc_id: JSON.stringify(this.attachmentId),
        });
        this.attachmentData = res;
      } catch (e) {
        console.log(e);
      }
    },
    ViewLess() {
      if (!this.showViewAll) {
        this.showViewAll = false;
        this.commentData = this.backupComment.slice(0, 3)
        this.showViewAll = true
      }

    },

    getAvatarName(value) {
      return value.first_name &&
        value.first_name.length > 1 &&
        value.last_name &&
        value.last_name.length > 1
        ? `${value.first_name.substr(0, 1)}${value.last_name.substr(0, 1)}`
        : value.first_name.substr(0, 2);
    },

    showSaveButton() {
      this.showButton = this.comment.length > 0;
    },
    cancel() {
      this.comment = null;
      this.getForumData();
      this.showTextArea = false;
      console.log(this.issueStage, "cdhbcjh")
    },
    cancel1() {
      this.getForumData();
      this.showTextArea = false;
    },
    async saveTheComments() {
      if (this.attachmentId && this.attachmentId.length > 0) {
        let res = await IssueService.findOne(this.issueId);
        res[0].attachment = JSON.parse(res[0].attachment);
        let concateData = res[0].attachment.concat(this.attachmentId);
        let obj = {
          id: res[0].id,
          attachment: JSON.stringify(concateData),
        };
        await IssueService.update(obj);
        this.attachmentData = [];
        this.attachmentId = [];
      }
      let data = {
        key_type: "issue",
        key_id: this.issueId,
        description: this.comment,
      };
      await ForumService.create(data);

      this.comment = "";
      this.showTextArea = false;
      this.showButton = false;
      this.getForumData();
      this.$emit("uploadComment");
    },
    async getForumData() {
      try {
        let res = await ForumService.getForumList({
          key_id: this.issueId,
          key_type: "issue",
        });
        await res.sort(function (a, b) {
          return new Date(b.modified) - new Date(a.modified);
        });
        let $index = 0
        await res.forEach((e) => {
          e.showEditor = false
          if (e.created_by == this.userData.id && $index == 0) {
            e.showEditIcon = true
            $index++
          }
          else {
            e.showEditIcon = false;
          }
          console.log(e, this.userData, "8888888888888")
        });
        this.backupComment = JSON.parse(JSON.stringify(res));
        if (res && res.length > 3) {
          this.commentData = res.slice(0, 3);
          this.showViewAll = true;
        } else {
          this.commentData = res;
        }
        console.log(this.commentData, "hhhkkkkaaa");
        this.$forceUpdate();
        // alert(this.commentData.modified)
      } catch (e) {
        this.commentData = [];
      }
    },
    ViewAll() {
      let data = this.backupComment.slice(
        this.commentData.length,
        this.commentData.length + 3
      );
      this.commentData = this.commentData.concat(data);
      if (this.commentData.length > 3) {
        this.showViewAll =
          this.commentData.length == this.backupComment.length ? false : true;
      } else {
        this.showViewAll = false;
      }
    },

    handleOutsideClick(event) {
      if (!this.$refs.commentsContainer || !this.$refs.commentsContainer.contains(event.target) && (!this.comment || this.comment.length == 0)) {
        this.showTextArea = false;
      }
    }

  },
}
</script>

<style scoped>
.save {
  background-color: #3c5aaa;
  color: #fff;
  width: 90px;
}

.cancel {
  background-color: #e1e1e1;
  width: 90px;
}

.modifiedDate {
  margin-left: 10px;
  font-size: var(--ds-font-size-100, 14px);
  font-style: normal;
  font-weight: 400;
  line-height: var(--ds-font-lineHeight-200, 1.42857142857143);
  -ms-overflow-style: -ms-autohiding-scrollbar;
  text-decoration-skip-ink: auto;
}

.editBtn {
  margin-left: 50px;
}

.commentUsername {
  margin-left: 10px;
  color: var(--ds-text-subtle, #42526e);
  font-weight: var(--ds-font-weight-medium, 500);
  overflow-wrap: break-word;
}

.commentCardActions {
  margin-top: -15px;
  margin-left: 10px;
}

.view-all {
  text-align: center;
  background: #dddbdb70;
  font-weight: bold;
  color: #4f63ff;
  border-radius: 7px;
  cursor: pointer;
}

.commentDescription {
  font-size: 1em;
  line-height: 1.714;
  font-weight: normal;
  margin-top: 0.75rem;
  margin-bottom: 0px;
  letter-spacing: -0.005em;
}

div.comment {
  /* height: 60px; */
  min-width: 200px;
  float: left;
  background-color: #ececec;
  position: relative;
  color: #ccc;
  padding: 0;
  border-radius: 4px;
  margin: 17px;
  min-height: 50px;
}

.comment::before {
  content: "";
  width: 0px;
  height: 0px;
  border: 0.5em solid transparent;
  position: absolute;
}

.comment.bottom::before {
  left: 12px;
  top: -15px;
  border-bottom: 10px solid #ececec;
}
</style>