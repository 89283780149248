<template>
    <div>
      <SingleSessionCard v-if="activeSessions && activeSessions.length==1" 
        :activeSessions="activeSessions"
         v-on:sessioncleared="sessioncleared"
        /> 
     <MultiSessionCard v-if="activeSessions && activeSessions.length>1" 
        :activeSessions="activeSessions"
        v-on:sessioncleared="sessioncleared"/>
      <v-card v-if="!activeSessions" flat class="pa-3" min-height="90vh">

        <v-card-title> Work Timings </v-card-title>
        <v-card-text>
          <v-row no-gutters dense class="rowheader pa-2">
            <v-col cols="5" class="text-left"> Name </v-col>
            <v-col cols="3" class="text-center"> Timing </v-col>
          </v-row>
          <v-row dense v-for="timing in workTimings" :key="timing">
            <v-col cols="5" class="text-left">
              <b>{{ timing.code }}</b> - {{ timing.name }}
              &nbsp; &nbsp;
              <v-chip style="zoom: 80%" v-if="timing.suggested == 1" label>
                Suggested
              </v-chip>
            </v-col>
            <v-col cols="3" class="text-center">
              {{ moment(timing.start_time).format("HH:mm") }} -
              {{ timing.end_time }}
            </v-col>
            <v-col cols="3" class="text-center">
              <v-btn
                flat
                @click="selectTiming(timing)"
                density="compact"
                color="primary"
              >
                Select
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-divider />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
</div>
  </template>
  <script>
  import SingleSessionCard from './SingleSessionCard.vue'
  import MultiSessionCard from './MultiSessionCard.vue';
  import moment from "moment";
  import UserService from "@/api/service/UserService";
  import WorkTimingService from '@/api/service/WorkTiming/WorkTimingService';
  //import { uuid } from "vue-uuid";
  import socket2 from '@/Socket-Client2.js'

  export default {
    components:{
        MultiSessionCard,
        SingleSessionCard
    },
    data() {
      return {
        interval: {},
        value: 0,
        workTimings: null,
        userAgent:"",
        selectedTiming: 0,
        activeSessions: null,
        userDetails: JSON.parse(localStorage.getItem("user_details")),
       user:{
          id:'',
          work_timing_id:''
        }
      };
    },
    beforeUnmount () {
        clearInterval(this.interval)
    },
    async created(){
      await this.getActiveSessions();
      await this.getWorkTimings();

    },
  
 
    async mounted() {
      this.userAgent = window.navigator.userAgent
      this.checkIfActive();
    /* this.interval = setInterval(() => {
      if (this.value === 100) {
        this.value = 0;
        clearInterval(this.interval);
        this.getWorkTiming()
      } else {
        this.value += 10;
      }
    }, 3000);*/
    },
    methods: {
      moment,
      async sessioncleared() {
        let $i=0;
        for(let session of this.activeSessions) {
           if(this.$store.getters.getDeviceId!=session.device_id || localStorage.getItem('sessionId')!=session.session_id) { 
                await UserService.clearSessions({sessionId:session.session_id})  
                this.activeSessions.splice($i,1)     
            } else {
                $i++
            }
        }
        if(this.activeSessions.length==0) {
            this.activeSessions=null
            this.$forceUpdate();
        }
      },
      checkIfActive() {
            this.setTimer(3000)      
        },
    async redirect() {
        if(this.activeSessions && this.activeSessions.length==1 && this.activeSessions[0].device_id==
        localStorage.getItem('deviceId')) {

            await this.getWorkTiming()
            localStorage.setItem('sessionId',this.activeSessions[0].session_id)
            console.log(socket2,"helloooooooooooooooooooooooo")
            socket2.join(this.activeSessions[0].session_id)
            this.$router.push("/")
        }

    },
    setTimer($milliSeconds) {
        let $interval = setInterval(() => {
            clearInterval($interval)
            this.redirect()
        },$milliSeconds);
    },
      async getActiveSessions() {
        try{
        let $sessions = await UserService.getActiveSessions({deviceId:localStorage.getItem("deviceId")});
        if ($sessions && $sessions.length >0 && $sessions[0].active) {
          if (this.$store.getters.device_id == $sessions[0].device_id) {
            this.activeSessions = $sessions;
          } else {
            this.activeSessions = $sessions;
          }
        }
        console.log($sessions,"00000000000000000","uuuuuuuuuuu")
        }
        catch(e){
          console.log(e,"oooooo")
        }
       
      },
      async getWorkTiming(){
          let res = await WorkTimingService.findOne(this.activeSessions[0].work_timing_id)
          if(res && res.length>0){
          localStorage.setItem("_WORK_TIMING_",JSON.stringify({
              id:res[0].id,
              name:res[0].name,
            logDate:res[0].start_time,
  
          }));          
          this.$router.push('/');
        }
      },
      selectTiming($timing) {
        // alert($timing)
        console.log($timing,'worktimingId')
        localStorage.setItem(
          "_WORK_TIMING_",
          JSON.stringify({
            id: $timing.id,
            name: $timing.name,
            logDate: $timing.start_time,
          })
        );
        this.createSession($timing);
        // let userDetails= localStorage.getItem("user_details")
        // let user={
        //   id:userDetails.id,
        //   work_timing_id:$timing.id
        // }
        this.user.id=this.userDetails.id
        this.user.work_timing_id=$timing.id
        UserService.save(this.user)

          UserService.update({
        id: this.user.id,
        stage: 'login',
      });
        this.$router.push("/");
      },
      async getWorkTimings() {
        this.workTimings = await UserService.autoSuggestWorkTiming();
      },
      async createSession($item) {
        let $obj = {};
        console.log($item[moment().format("ddd").toLowerCase()]);
        $obj.user_id = this.userDetails.id;
        $obj.session_id= localStorage.getItem('sessionId')
        //$obj.session_id = uuid.v1();
        $obj.device_id = localStorage.getItem("deviceId");
        $obj.timing = $item[moment().format("ddd").toLowerCase()];
        $obj.work_timing_id = $item.id;
        $obj.proposed_end_date = moment()
          .add($item[moment().format("ddd").toLowerCase()], "hours")
          .format("YYYY-MM-DD HH:mm:ss");
        $obj.actual_hours = null;
        $obj.active = 1;
        $obj.device_info = this.userAgent
        $obj.deleted = 0;
        $obj.created_by = this.userDetails.id;
        $obj.modified_by = this.userDetails.id;
        $obj.created = moment().format("YYYY-MM-DD HH:mm:ss");
        $obj.modified = moment().format("YYYY-MM-DD HH:mm:ss");
        let $data = await UserService.createSessions($obj);
        console.log($data, "after the creation");
        console.log('Joinint the session')
        localStorage.setItem('_SESSION_ID_',$data.session_id)
        localStorage.setItem("USER_SESSIONS", JSON.stringify($data));
      },
    }
  };
  </script>
  <style scoped>
  .center {
    display:block;
    margin:auto auto;
    margin-top:40px;
  }
  .header {
    display: flex;
    text-align: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 400;
  }
  .box {
    background-color: #f4f4f4;
    margin-left: 250px;
    margin-right: 250px;
    /* width: fit-content; */
    border-radius: 6px;
    height: fit-content;
    display: flex;
  }
  .logOut {
    width: 100px;
    height: 32px;
    border-radius: 8px;
    color: white;
    font-weight: 600;
    background: #1258ab;
  }
  .Icon {
    display: flex;
    align-items: center;
    margin-right: 12px;
  }
  </style>