<template>
  <div style="background-color:yellowgreen">
    <SideMenu v-if="showMenu" v-on:toggle-rail="toggleRail = !toggleRail" />
    <TopMenu v-if="showMenu" />
    <v-row no-gutters dense style="background-color:#F3F9FF;min-height: 100vh;">
      <v-col v-if="showMenu" style="margin-left:70px;margin-top:70px;margin-right:5px"
        :style="!toggleRail ? 'margin-left:65px' : 'margin-left:225px'">
        <BreadCrumps v-show="true" />
        <!-- {{ isShiftInDue }} -{{ isLogoutAction }}hello -->

        <RouterView></RouterView>
      </v-col>
      <v-col v-if="!showMenu">
        <BreadCrumps v-show="true" />
        <CardSelection :menuOptions="menuOptions"/>
        <RouterView></RouterView>
      </v-col>
    </v-row>
    <!-- <v-row style="min-height:95vh; margin-top:60px;background-color:#F3F9FF">
    <v-col>
    </v-col>
  </v-row> -->
    <Loader v-if="false"></Loader>
    <OTDialogue v-if="isShiftInDue && logoutAction"></OTDialogue>
    <NormalSession v-if="isShiftInDue == false && logoutAction"></NormalSession>
    <!--  <SessionExist v-if="isSessionExist && !isSessionExpire" ></SessionExist> -->
    <SessionExpire v-if="isSessionExpire"></SessionExpire>
    <IssueView  :issueId="isIssueChanged" v-if="isDrawerOpen" />
    <div v-for="item in notificationData" :key="item">
      <v-snackbar location="right top" v-model="item.show" :timeout="item.time">
        <AppNotification @closeSnackBar="closeSnackBar(item)" :message="item.data" />
      </v-snackbar>
     
    
    </div>
  </div>
</template>

<script>
import IssueView from '../modules/IssueV2/index.vue'
import moment from 'moment-timezone'
import SideMenu from "@/menus/SideMenu.vue";
import socket from '@/Socket-Client.js'
import socket2 from '@/Socket-Client2.js'
import UserService from "@/api/service/UserService";
import BreadCrumps from "./BreadCrumps.vue";
import TopMenu from "../menus/TopMenu.vue";
import TokenService from "@/api/service/Token/TokenService";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import Loader from "@/components/ui/Loader.vue";
import { uuid } from 'vue-uuid';
import OTDialogue from '@/components/others/OtHours.vue'
import NormalSession from '@/components/others/Status.vue'
//import SessionExist from '../components/others/Session.vue';
import SessionExpire from '@/components/others/SessionExpire.vue'
import AppNotification from './AppNotification.vue';
import NotificationService from '@/api/service/Notification/NotificationService';
import CardSelection from 'frontendcomponent/src/components/CardSelection/index.vue'

export default {
  components: {
    TopMenu,
    IssueView,
    SideMenu,
    BreadCrumps,
    Loader,
    OTDialogue,
    NormalSession,
    // SessionExist,
    SessionExpire,
    AppNotification,
    CardSelection
  },
  data() {
    return {
     // showIssueView:false,
      snackbar: true,
      showMenu: true,
      toggleRail: false,
      status: null,
      // notificationData: [
      //   {
      //   data:
      //   {
      //     "id": 422,
      //     "key_type": "IS",
      //     "key_id": 287,
      //     "sender_id": 265,
      //     "user_id": null,
      //     "title": "New Issue Created In Your WorkSpace",
      //     "content": "ZuhaibRizvi created new issue in the Workspace Production Red and Assigend to you ref# BNO-303",
      //     "notification_type": "Issue",
      //     "read_date": null,
      //     "link": "https://synergy.axodesk.com/issueView2?id=287",
      //     "params_text": null,
      //     "accepted": null,
      //     "active": 1,
      //     "created": "2023-08-09T10:49:53.000Z",
      //     "modified": "2023-08-09T10:49:53.000Z",
      //     "created_by": 265,
      //     "modified_by": null,
      //     "deleted": 0,
      //     "users": {
      //       "id": 265,
      //       "username": "ZuhaibRizvi",
      //       "email": "zuhaib@tibura.com",
      //       "password": null,
      //       "company_id": 120,
      //       "default_site_id": 1,
      //       "first_name": "Kashif",
      //       "last_name": "Rizvi",
      //       "name": "Kashif Rizvi",
      //       "token": null,
      //       "token_expires": null,
      //       "api_token": null,
      //       "activation_date": null,
      //       "secret": null,
      //       "secret_verified": null,
      //       "tos_date": null,
      //       "active": 1,
      //       "is_superuser": 1,
      //       "role": "user",
      //       "preferences": null,
      //       "lock_user": 0,
      //       "created": "2023-06-10T00:30:00.000Z",
      //       "modified": "2023-07-31T10:15:20.000Z",
      //       "team": null,
      //       "mobile": "7071900530",
      //       "auth_type": null,
      //       "auth_via": null,
      //       "auth_service_id": 1,
      //       "activation_key": null,
      //       "ldap_string": null,
      //       "default_company_id": null,
      //       "super_user": null,
      //       "user_type": null,
      //       "invalid_attempts": null,
      //       "user_security": null,
      //       "resource_id": null,
      //       "refresh_token": "3da10d14-09a7-11ee-ba14-06f1d5b0ffb2",
      //       "role_id": 8,
      //       "image_profile_id": 780,
      //       "company_list": null,
      //       "created_by": null,
      //       "modified_by": 265,
      //       "color_code": null,
      //       "customer_id": null,
      //       "provider_type": null,
      //       "date_format": null,
      //       "project": null,
      //       "theme": "#ddd9d9",
      //       "time_zone": "Asia/Kolkata",
      //       "uid": "c892fa1682d57449c1fac55b5cf5850efa8187ec82ee5ac583fe7abc4ca9905a",
      //       "work_timings": "[3,1,2]",
      //       "reporting_mgr_id": 306,
      //       "location_id": 1
      //     },
      //   },
      //   time: 5000,
      //   show: true
      // },  
      // {
      //   data:
      //   {
      //     "id": 422,
      //     "key_type": "IS",
      //     "key_id": 287,
      //     "sender_id": 265,
      //     "user_id": null,
      //     "title": "New Issue Created In Your WorkSpace",
      //     "content": "ZuhaibRizvi created new issue in the Workspace Production Red and Assigend to you ref# BNO-303",
      //     "notification_type": "Issue",
      //     "read_date": null,
      //     "link": "https://synergy.axodesk.com/issueView2?id=287",
      //     "params_text": null,
      //     "accepted": null,
      //     "active": 1,
      //     "created": "2023-08-09T10:49:53.000Z",
      //     "modified": "2023-08-09T10:49:53.000Z",
      //     "created_by": 265,
      //     "modified_by": null,
      //     "deleted": 0,
      //     "users": {
      //       "id": 265,
      //       "username": "ZuhaibRizvi",
      //       "email": "zuhaib@tibura.com",
      //       "password": null,
      //       "company_id": 120,
      //       "default_site_id": 1,
      //       "first_name": "Kashif",
      //       "last_name": "Rizvi",
      //       "name": "Kashif Rizvi",
      //       "token": null,
      //       "token_expires": null,
      //       "api_token": null,
      //       "activation_date": null,
      //       "secret": null,
      //       "secret_verified": null,
      //       "tos_date": null,
      //       "active": 1,
      //       "is_superuser": 1,
      //       "role": "user",
      //       "preferences": null,
      //       "lock_user": 0,
      //       "created": "2023-06-10T00:30:00.000Z",
      //       "modified": "2023-07-31T10:15:20.000Z",
      //       "team": null,
      //       "mobile": "7071900530",
      //       "auth_type": null,
      //       "auth_via": null,
      //       "auth_service_id": 1,
      //       "activation_key": null,
      //       "ldap_string": null,
      //       "default_company_id": null,
      //       "super_user": null,
      //       "user_type": null,
      //       "invalid_attempts": null,
      //       "user_security": null,
      //       "resource_id": null,
      //       "refresh_token": "3da10d14-09a7-11ee-ba14-06f1d5b0ffb2",
      //       "role_id": 8,
      //       "image_profile_id": 780,
      //       "company_list": null,
      //       "created_by": null,
      //       "modified_by": 265,
      //       "color_code": null,
      //       "customer_id": null,
      //       "provider_type": null,
      //       "date_format": null,
      //       "project": null,
      //       "theme": "#ddd9d9",
      //       "time_zone": "Asia/Kolkata",
      //       "uid": "c892fa1682d57449c1fac55b5cf5850efa8187ec82ee5ac583fe7abc4ca9905a",
      //       "work_timings": "[3,1,2]",
      //       "reporting_mgr_id": 306,
      //       "location_id": 1
      //     },
      //   },
      //   time: 5000,
      //   show: true
      // }],
      notificationData:[],
      logoutAction: false,
      menuOptions:{

 

"columns":2,

"height":"100px",

"showSearchBox":true,

"outlineOnHover":true,

"outlineOnSelection":true,

"actions":[],

"multiSelection":false,

"selectedValue":"code",

"options":

[

    {

        "title":"ERP & Ecommerce",

        "code":"erp-explore",

        "subTitle":"",

        "description":"I have ERP but need to connect to marketplaces"

    },

    {

        "title":"Only Ecommerce",

        "code":"no-erp",

        "subTitle":"",

        "description":"I  don’t have ERP but have a ecommerce channel'"

    },

    {

        "title":"Trying out",

        "code":"explore",

        "subTitle":"",

        "description":"Exploring the options of e-commerce"

    }



 

]

}
    }
  },
  computed: {
    isDrawerOpen() {
      return this.$store.getters.getIssueViewDrawer
    },
    isIssueChanged() {
      return this.$store.getters.getDefaultIssueInView
    },
    isShiftInDue() {
      return this.$store.getters.getShiftInDue
    },
    isLogoutAction() {
      return this.$store.getters.getLogoutAction
    },
    isLoaderRequired() {
      return this.$store.state.showLoader;
    },
    isOtDialogue() {
      return this.$store.state.showOtDialog;
    },
    isNormalDialoge() {
      return this.$store.state.showNormal;
    },
    isSessionExist() {
      return this.$store.state.showExist;
    },
    isSessionExpire() {
      return this.$store.state.sessionExpire;
    },
    isNotification() {
      return this.$store.getters.getNotificationId;
    },
  },
  watch: {
    isIssueChanged() {
      this.showIssueView = true
    },
    isLogoutAction($val) {
      this.logoutAction = $val
    },
    async isNotification($val) {
      if ($val) {
        await this.getNotificationData($val)
        this.snackbar = true
      }
    }
  },
  mounted: function () {
    //let socket
    // let $data = JSON.parse(localStorage.getItem("user_details"))
    // socket.emit('inActiveUser',{"userId":$data.id,"message":"InActive User"})
    if (!socket2.socket2) {
      socket2.join(localStorage.getItem("sessionId"))
    } 
  
    let $exist = localStorage.getItem("SET_EXIST");
    if ($exist == 'true') {
      this.$store.commit("SET_EXIST", true);
      localStorage.setItem("SET_EXIST", false);
    }
  },
  created: async function () {
    // await this.getSessionExpire()
    //   await this.getMySession();
    this.configureFirebaseMessaging()

    onMessage(getMessaging(), (payload) => {
      console.log('[firebase-messaging-sw.js] Received fg message ');
      // Customize notification here
      const notificationTitle = 'Background Message Title';
      const notificationOptions = {
        body: 'Background Message body.',
        icon: '/firebase-logo.png'
      };
      console.log(payload)
      console.log(notificationOptions)
      console.log(notificationTitle)
      /*self.registration.showNotification(notificationTitle,
      notificationOptions);*/
      // ...
    });

    if (window.location.pathname.toLocaleLowerCase() == '/verify' || window.location.pathname.toLocaleLowerCase() == '/accountverification') {
      this.showMenu = false
    }
    else {
      this.showMenu = true

    }
    // let $data = JSON.parse(localStorage.getItem("user_details"))
    // socket.emit('inActiveUser',{"userId":$data.id,"message":"InActive User"})
    console.log(socket, 'socket3rfw')
    if (socket) {
      socket.on('userstatus', (content) => {
        console.log(content)
        localStorage.removeItem("TOKEN");
        window.location = "http://" + this.host + "/login";
      })
    }
  },
  methods: {
    moment,
    async getNotificationData($notifyId) {
      let res = await NotificationService.findAll({ id: $notifyId })
      let $obj = {
        data: res[0],
        time: 5000,
        show: true
      }
      this.notificationData = [...this.notificationData, $obj]
      console.log(this.notificationData, "uuuuu")
   
    },
    closeSnackBar(item) {
      item.show = false;
    },
    async getSessionExpire() {
      let user = localStorage.getItem('user_details')
      if (user) {
        let userData = JSON.stringify(user)
        let $sessions = await UserService.findSessions({
          user_id: userData.id, end_date: 'null'
        });
        let $oldDeviceId = localStorage.getItem("device_id")
        if (!$oldDeviceId) {
          localStorage.setItem("device_id", uuid.v1())
          $oldDeviceId = localStorage.getItem("device_id")
        }

        if ($sessions && $sessions.length > 0 && $sessions[0].device_id != $oldDeviceId) {
          localStorage.setItem("USER_SESSIONS", JSON.stringify($sessions[0]))
          this.$store.commit("SET_EXIST", true);
          localStorage.setItem("SET_EXIST", true)
        } else if ($sessions && $sessions.length > 0) {
          localStorage.setItem("USER_SESSIONS", JSON.stringify($sessions[0]))
          this.$store.commit("SET_EXIST", false);
          localStorage.setItem("SET_EXIST", false);
        }
      }

    },
    async updateFCMToken(token, userId) {
      let $data = await TokenService.findAll({ user_id: userId })
      console.log($data, 'token service response')
      if ($data && $data.length > 0) {
        await TokenService.update({ user_id: userId, token_type: token, id: $data[0].id })
      } else {
        // need to insert data
        await TokenService.create({ user_id: userId, token_type: token, active: 1 })
      }
    },
    getNotifcationPermisson() {
      // Let's check if the browser supports notifications
      //alert('here')
      if (!("Notification" in window)) {
        alert("This browser does not support desktop notification");
        return false
      }

      // Let's check whether notification permissions have already been granted
      else if (Notification.permission === "granted") {
        //  var notification = new Notification("Hi there!");
        // alert('granted')
        // alert('Permission gratnd')

        return true
      }
      else if (Notification.permission !== "denied") {
        //alert('Not deinited')
        Notification.requestPermission().then(function (permission) {
          // If the user accepts, let's create a notification
          //alert(permisson)
          if (permission === "granted") {
            //  var notification = new Notification("Hi there!");
            return true
          } else {
            return false
          }
        });
        // At last, if the user has denied notifications, and you
        // want to be respectful there is no need to bother them any more.
      }
    },
    async configureFirebaseMessaging() {
      const token = localStorage.getItem("_FB_MESSAGE_TOKEN_")
      const userDetails = JSON.parse(localStorage.getItem("user_details"));
      console.log('I am inside token')

      if (token) {
        this.updateFCMToken(token, userDetails.id)
        localStorage.setItem("_FB_MESSAGE_TOKEN_", token)
      } else {

        const messaging = getMessaging();

        getToken(messaging, { vapidKey: 'BJVCWfJHHxX7DZtn9gwo906532fcZ-DB9gOrA5sJV2O5Vcy18eRpyhj00J7jFxSKVDuTeUmixcL2-0K8ZEjks0I' })
          .then(token => {
            console.log('Notification token', token)
            this.updateFCMToken(token, userDetails.id)
            localStorage.setItem("_FB_MESSAGE_TOKEN_", token)
          }).catch(error => {
            console.log(error)
          })


      }
      this.getNotifcationPermisson();


    }
  }
}
</script><style scoped>
>>>.v-snackbar__content {
  flex-grow: 1;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.0178571429em;
  line-height: 1.25rem;
  margin-right: auto;
  padding: 0px;
  text-align: initial;

}</style>