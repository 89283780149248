<template>
  <v-card density="compact" flat height="81vh">
    <v-card-title class="ml-4" style="text-transform:capitalize"> {{accountName}} </v-card-title>
  
    <v-card-text>
      <MenuBar 
      @filterTaskData="filterTaskData"
      :zeroBoardIssues="zeroIssuesBoard" :displayType="displayType" 
      :assignedToMe="assignedToMe"
        v-on:assignedtome="assignedToMe=!assignedToMe"
      v-on:listClicked="setListType(true)"
        v-on:boardClicked="setListType(false)" v-on:filter="filterIssueData($event)"
        v-on:filterClicked="filterOpen($event)" 
        v-on:filterClose="clearFilter()" 
        v-on:group="groupData" v-on:more="download($event)" :defaultSort="defaultSort" :resetFilter="resetFilter1"
        v-on:zeroIssuesBoard="zeroIssuesBoard = !zeroIssuesBoard" :defaultGroup="defaultGroup"
        @SearchData="SearchData($event)"
        @sortData="sortData($event)" />
      <!-- <Backlog v-if="displayType == 'list'" /> -->
      <List :defaultGroup="defaultGroup" :stages="stages" :filters="filterData" :sortData="defaultSort" :mainFilter="groupedFilterData"
        :issueData="issues" v-if="displayType == 'list'" @refreshTheData="getIssueList"  />
      <BoardWithoutGroup :assignedToMe="assignedToMe" :defaultGroup="defaultGroup" :showZeroIssuesBoard="zeroIssuesBoard" :showGroup="groupBy" :stages="stages"
      :mainFilter="groupedFilterData"
        :filters="filterData" :sortData="defaultSort" 
        :issueData="issues" 
        v-if="displayType == 'board'"
        :groups="groupValues" :userId="this.userData.id" @stageUpdated="stageUpdated" @getStage="stageForChange" 
        />
    </v-card-text>
  </v-card>
  <div v-if="openDrawer">
    <Filter :model="issues" v-on:closeFilter="filterClose()" v-on:filterClosed="closeFilter1($event)"
      :drawerStatus="openDrawer" />
  </div>
</template>
<script>
import MenuBar from "./MenuBar.vue";
// import Backlog from './BackLog.vue'
import BoardWithoutGroup from "./BoardWithoutGroup.vue";
import List from "./List.vue";
import * as FileSaver from 'file-saver';
import moment from "moment";
// import exportFromJSON from "export-from-json";
import * as xlsx from "xlsx";
import lodash from "lodash";
import Filter from "@/components/others/Filter.vue";
// import StageService from '@/api/service/Stage/StageService'
import IssueService from "@/api/service/Issue/IssueService";
import SubCategoryService from "@/api/service/SubCategory/SubCategoryService";
export default {
  components: {
    MenuBar,
    // Backlog,
    BoardWithoutGroup,
    List,
    Filter,
  },
  data() {
    return {
      parentId:null,
      searchValue: "",
      groupValues: [],
      accountName:'',
      assignedToMe:false,
      zeroIssuesBoard: true,
      defaultSort: { title: "By Latest", value: "created" },
      defaultGroup: {
        title: "Assigned",
        value: "next_action_name",
        column: "next_action",
      },
      // defaultGroup:JSON.parse(localStorage.getItem("_DEFAULTS_")).group,
      stages: [],
      resetFilter1: false,
      backUpIssueData: [],
      displayType: "list",
      groupBy: false,
      issues: [],
      filterData: {},
      groupedFilterData: [],
      openDrawer: false,
      userData: JSON.parse(localStorage.getItem("user_details")),
      columns: [
        {
          field: "user_name",
          header: "User",
          style: { width: "100px", color: "" },
          toolTip: "User",
          sortfield: true,
        },
        {
          field: "name",
          header: "Issue",
          style: { width: "100px", color: "" },
          toolTip: "Name",
          sortfield: true,
        },
        {
          field: "sub_category_name",
          header: "Sub Category",
          style: { width: "100px", color: "" },
          toolTip: "Sub Category",
          sortfield: true,
        },

        {
          field: "end_date",
          header: "End Date",
          style: { width: "100px", color: "" },
          toolTip: "End Date",
          sortfield: true,
          type: "datetime",
        },

        {
          field: "stage",
          header: "Stage",
          style: { width: "100px", color: "" },
          toolTip: "Stage",
          sortfield: true,
        },

        {
          field: "user_name",
          header: "Reported By",
          style: { width: "100px", color: "" },
          toolTip: "Reported By",
          sortfield: true,
        },
        {
          field: "start_date",
          header: "Start Date",
          style: { width: "100px", color: "" },
          toolTip: "Start Date",
          sortfield: true,
          type: "datetime",
        },
      ],
    };
  },
  computed: {
    reloadIssue() {
      return this.$store.getters.getIssueUpdated;
    },
    getChangeInUrl() {
      return this.$route.query
    },
    isDisplayList() {
      return this.$store.state.issueDisplay;
    },
    issueUpdated(){
      return this.$store.getters.getUpdatedIssueStatus;
    },
    issueCreated(){
      return this.$store.getters.getCreatedIssueStatus;
    }
  },
  watch: {
    reloadIssue() {
        if(this.$store.getters.getIssueUpdated!=0) {
          this.getUpdatedData(this.$store.getters.getIssueUpdated)
        }
    },  
    getChangeInUrl() {
      this.getIssueList();
    },
    assignedToMe() {
      //alert('here')
      this.getIssueList()
    },
    issueCreated(){
      if(this.issueCreated){
        this.getIssueById(this.$store.getters.getUpdatedIssueId,'new');
        this.$store.commit("SET_ISSUE_CREATED", false);
      }
      
    },
    issueUpdated(){
      if(this.issueUpdated){
        this.getIssueById(this.$store.getters.getUpdatedIssueId,'update');
        this.$store.commit("SET_OLD_ISSUE_UPDATED", false);
      }
    },
    isDisplayList() {
      if(this.isDisplayList=='list') {
        this.displayType ='list'
      } else {
        this.displayType ='board'
      }
      //this.displayType = this.isDisplayList;
    },
  },
  async created() {
    let defaltGroup =localStorage.getItem("_DEFAULTS_")
    this.defaultGroup =defaltGroup?JSON.parse(defaltGroup).group:this.defaultGroup
    await this.getSelectselectValue()
    let $val = localStorage.getItem("issueDisplay");
   // if ($val == "true") {
      this.displayType = $val //"list";
    //} else {
    //  this.displayType = "board";
   // }
    this.$store.commit("SET_REFRESH_BREADCRUMP", false);
    this.workSpaceId = this.$route.query.workSpaceId;
    let $wsDefaults = localStorage.getItem("_WS_DEFAULTS_");
    let details = JSON.parse($wsDefaults)
    let accountDetails = details?details.account :{}
    this.accountName = accountDetails && accountDetails.name ?accountDetails.name :''
    if ($wsDefaults) {
      $wsDefaults = JSON.parse($wsDefaults);
      if (!$wsDefaults.workspace) {
        this.redirectToWorkspaces();
        return;
      }
    } else {
      this.redirectToWorkspaces();
      return;
    }
    delete $wsDefaults.issue;
    localStorage.setItem("_WS_DEFAULTS_", JSON.stringify($wsDefaults));

    this.$store.commit("SET_REFRESH_BREADCRUMP", true);
    // let $displayType = localStorage.getItem('_ISSUE_DISPLAY_')
    // if ($displayType) {
    //     this.displayType = $displayType
    // }
    await this.getStagesForWorkspaces();
     //this.getIssueList();
    if (this.defaultGroup) {
      console.log(this.defaultGroup, "defaulttttt");
      this.groupValues = this.backUpIssueData.filter(
        (v, i, a) =>
          a.findIndex(
            (v2) => v2[this.defaultGroup.value] === v[this.defaultGroup.value]
          ) === i
      );
      console.log(this.groupValues, "valuessssssss");
    }
  },

  methods: {
    moment,
    filterTaskData(data){
      this.groupedFilterData = data
      this.getIssueList(data
      )
      console.log(this.groupedFilterData , 'hjhghjhg')
    },
     async getUpdatedData($id) {
      var res = await IssueService.getAssignToMeData({"id":$id});
      let $findIndex = lodash.findIndex(this.issues,{"id":$id})
      if($findIndex>=0) {
        this.issues[$findIndex] =res[0]
      } else {
        this.issues.push(res[0])
      }
      this.$store.dispatch('issueUpdated',0)
    },
    async getIssueById($id,$type){
      let $res = await IssueService.findAllIssues({id:$id});
      if($res && $res.length>0){
        this.setNewIssue($res[0],$type)
      }
    },
    getSelectselectValue() {

      let $wsDetails = localStorage.getItem("_WS_DEFAULTS_")
      if ($wsDetails) {
        $wsDetails = JSON.parse($wsDetails)
        this.section = []
        if (!$wsDetails.account) {
          this.$router.push('/showAccountForWorkspaces')
        }
        else if (!$wsDetails.project) {
          this.$router.push('/showProjectForWorkspaces')
        }
      }
      localStorage.setItem("_WS_REDIRECT_",'/issueConsolidated')
    },
    setListType($flag) {
      if ($flag) {
        this.displayType = "list";
        localStorage.setItem("issueDisplay", 'list');
      } else {
        this.displayType = "board";
        localStorage.setItem("issueDisplay", 'board');
      }
    },
    async download() {
      let fileName = "Issues";
      this.exportExcel(fileName)
    },
    
    exportExcel(name) {
            let data = this.formattedData();
            const worksheet = xlsx.utils.json_to_sheet(data);
        // const max_width = this.dataSource.reduce((w, r) => Math.max(w, r.name.length), 10);
        if(data && data.length>0){
            worksheet["!cols"] =[]
            Object.keys(data[0]).forEach(data=>{
            let width  =  { width:20 }
            console.log(data,'called')
            worksheet['!cols'].push(width)
            })
        }
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            this.saveAsExcelFile(excelBuffer, name);
        },
        
        saveAsExcelFile(buffer, fileName) {
            let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let EXCEL_EXTENSION = '.xlsx';
            const data = new Blob([buffer], {
            type: EXCEL_TYPE
            });
            FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
        },
    formattedData() {
      let selectedColumns = this.columns;
      console.log(selectedColumns);
      let data = JSON.parse(JSON.stringify(this.issues));
      let formateData = [];
      for (let i = 0; i < data.length; i++) {
        let $obj = {};
        for (let j = 0; j < selectedColumns.length; j++) {
          if (selectedColumns[j]["header"].length > 0) {
            console.log(selectedColumns[j]["header"]);
            if (
              selectedColumns[j]["type"] == "date" ||
              selectedColumns[j]["type"] == "datetime" ||
              selectedColumns[j].field == "created" ||
              selectedColumns[j].field == "modified"
            ) {
              $obj[selectedColumns[j]["header"]] = this.formatDate(
                lodash.get(data[i], selectedColumns[j].field),
                selectedColumns[j].field
              );
            } else if (
              selectedColumns[j].field == "active" ||
              selectedColumns[j].header.toLowerCase() == "status"
            ) {
              $obj[selectedColumns[j]["header"]] =
                data[i].active == 1 ? true : false;
            } else if (selectedColumns[j]["header"].toLowerCase() != "action") {
              $obj[selectedColumns[j]["header"]] = lodash.get(
                data[i],
                selectedColumns[j].field
              );
            }
          }
        }
        formateData.push($obj);
      }
      return formateData;
    },
    formatDate(dateString, type) {
      if (!dateString) {
        return "";
      } else if (type == "date") {
        const date = new Date(dateString);
        return date; // moment(date).utcOffset('+05:30')
      } else {
        const date = new Date(dateString);
        //return moment(date).toLocal()
        return date.toLocaleString();
      }
    },
    async stageUpdated($data) {
      if($data.parentIssueId){
        this.parentId=JSON.parse($data.parentIssueId)
      }
      if ($data.issueId) {
        let $issue = this.issues.filter((issue) => {
          console.log(issue.id == $data.issueId);
          return issue.id == $data.issueId;
        });
        console.log($issue,'alert2222')
        let $backUpIssue = this.backUpIssueData.filter((issue) => {
          console.log(issue.id == $data.issueId);
          return issue.id == $data.issueId;
        });
        let $oldStage = this.stages.filter((stage) => {
          return stage.stage == $data.oldStage;
        });
        let $oldGroup = this.issues.filter((issue) => {
          return issue.id == $data.oldGroupValue;
        });
        let $group = this.issues.filter((issue) => {
          return issue.id == $data.groupValue;
        });
        let $stage = this.stages.filter((stage) => {
          return stage.stage == $data.stage;
        });

        let value = await this.getDragPermission($backUpIssue[0][this.defaultGroup.column], $group[0][this.defaultGroup.column])
        console.log(value,'tharik2222')
        if (value) {
          if ($issue.length > 0) {
            let $issueSaveData = {};
            $issue[0].stage = $data.stage;
            $backUpIssue[0].stage = $data.stage;
            if (
              $group.length > 0 &&
              $oldGroup.length > 0 &&
              $group[0].id != $oldGroup[0].id
            ) {
              if(this.defaultGroup.column=='sub_category_id'){
                   let res= await SubCategoryService.findOne($group[0][this.defaultGroup.column]);
                    this.categoryId= res[0].category_id
                    this.issueTypeId=$issue[0].issue_type_id
                    this.subCategoryId=$group[0][this.defaultGroup.column]
                    this.taskId=$issue[0].task_id
            }
            if(this.defaultGroup.column=='issue_type_id'){
              this.issueTypeId=$group[0][this.defaultGroup.column]
              this.categoryId=$issue[0].category_id
              this.subCategoryId=$issue[0].sub_category_id
              this.taskId=$issue[0].task_id
              // alert($group[0][this.defaultGroup.column])
            }
            if(this.defaultGroup.column=='task_id'){
              this.taskId=$group[0][this.defaultGroup.column]
              this.categoryId=$issue[0].category_id
              this.subCategoryId=$issue[0].sub_category_id
              this.issueTypeId=$issue[0].issue_type_id
              // alert($group[0][this.defaultGroup.column])
            }
              $issueSaveData = {
                id: $issue[0].id,
                stage: $data.stage,
                [this.defaultGroup.column]: $group[0][this.defaultGroup.column],
                category_id:this.categoryId,
                sub_category_id:this.subCategoryId,
                project_id:$issue[0].project_id,
                account_id:$issue[0].account_id,
                task_id:this.taskId,
                issue_type_id: this.issueTypeId


              };
              console.log($issueSaveData,'alert888888888')
              $backUpIssue[0][this.defaultGroup.value] =
                $group[0][this.defaultGroup.value];
              $backUpIssue[0][this.defaultGroup.column] =
                $group[0][this.defaultGroup.column];
              $issue[0][this.defaultGroup.value] =
                $group[0][this.defaultGroup.value];
              $issue[0][this.defaultGroup.column] =
                $group[0][this.defaultGroup.column];
            } else {
              $issueSaveData = {
                id: $issue[0].id,
                stage: $data.stage,
              };
            }
            if(this.parentId==null){
              console.log($issueSaveData,'check333333333')
              await IssueService.updateChildIssue($issueSaveData)
              //  $oldStage.no_of_issues = $oldStage.no_of_issues - 1;
              //  $stage.no_of_issues = $stage.no_of_issues + 1;
              //   this.getStagesForWorkspaces();
              //   this.groupData(this.defaultGroup);
            }
            await IssueService.update($issueSaveData);
            $oldStage.no_of_issues = $oldStage.no_of_issues - 1;
            $stage.no_of_issues = $stage.no_of_issues + 1
          }
          this.getStagesForWorkspaces();
          this.groupData(this.defaultGroup);
        }
      }
    },

    getDragPermission(data1, data2) {
      if(this.parentId){
        if(this.defaultGroup.column == 'submitted_by' || this.defaultGroup.column == 'category_id' || this.defaultGroup.column == 'created' || this.defaultGroup.column == 'issue_type_id' || this.defaultGroup.column == 'task_id' || this.defaultGroup.column == 'sub_category_id')
        {
          
          return data1 == data2 ? true : false       
        }
        else {
        return true
      }
      }
      
      if (this.defaultGroup.column == 'submitted_by' || this.defaultGroup.column == 'category_id' || this.defaultGroup.column == 'created') {
        return data1 == data2 ? true : false 
      }
      else {
        return true
      }
    },

    async stageForChange() {
      this.getStagesForWorkspaces();
    },
    redirectToWorkspaces() {
      this.$store.dispatch("setSnackbar", {
        text: "Please Select Workspace/Account/Project first",
      });
      this.$router.push("/showWorkspaces");
    },
    SearchData($event) {
      this.searchValue = $event;
      if (this.searchValue.length > 0) {
        let searchStr = new RegExp(this.searchValue, "gi");
        this.issues = this.backUpIssueData.filter((ele) => {
          console.log(this.issues,'aletrt9999')
          return (
            ele.name.search(searchStr) > -1 ||
            ele.reference_num.search(searchStr) > -1
          );
        });
      } else {
        // this.issues = JSON.parse(JSON.stringify(this.backUpIssueData.slice(0, 10)))
        this.issues = this.backUpIssueData;
      }
    },
    async sortData(value) {
      let extractedValues = value.map(item => item.value);
      console.log(extractedValues,"gggggggggggggg2")
      let $defaults = localStorage.getItem("_DEFAULTS_");
      if ($defaults) {
        $defaults = JSON.parse($defaults);
      } else {
        $defaults = [];
      }
      $defaults.sort = value;
      this.defaultSort = value;
      localStorage.setItem("_DEFAULTS_", JSON.stringify($defaults));
      this.issues =lodash.sortBy(this.backUpIssueData,extractedValues)
      // this.issues = await this.backUpIssueData.sort((a, b) => {
      //   let data1;
      //   let data2;
      //   if(value.value=='created' || value.value=='mail_date' || value.value=='due_date') {
      //     data1 = a[value.value] ? a[value.value] : ""
      //     data2 = b[value.value] ? b[value.value] : ""
      //   } else {
      //     data1 = a[value.value] ? a[value.value].toLowerCase() : ""
      //     data2 = b[value.value] ? b[value.value].toLowerCase() : ""
      //   }
      //     if (data1 < data2) {
      //       return -1;
      //     }
      //     if (data1 > data2) {
      //       return 1;
      //     }
      //     return 0;
      // })
      // let $groupValues = this.backUpIssueData.filter(
      //   (v, i, a) =>
      //     a.findIndex(
      //       (v2) => v2[this.defaultGroup.value] === v[this.defaultGroup.value]
      //     ) === i
      // );
      if(this.displayType == 'board') {
        this.issues =lodash.sortBy(this.backUpIssueData,extractedValues)

        // this.groupValues = await this.groupValues.sort((a, b) => {
        //   let data1 = a[value.value] ? a[value.value].toLowerCase() : ""
        //   let data2 = b[value.value] ? b[value.value].toLowerCase() : ""
        //   if (data1 < data2) {
        //     return -1;
        //   }
        //   if (data1 > data2) {
        //     return 1;
        //   }
        //   return 0;
        // })
      }
    //  this.issues = this.backUpIssueData;

    },
    filterOpen() {
      this.openDrawer = true;
    },
    filterIssueData($event) {
      // console.log($event,'eventssssssss')
      if ($event && $event.custom) {
        this.openDrawer = true;
        this.getIssueList({});
      } else {
        this.getIssueList($event, true);
      }
    },
    clearFilter() {
      this.resetFilter1 = false;
      this.filterData = {};
    },
    filterClose() {
      this.openDrawer = false;
    },
    closeFilter1(data) {
      this.openDrawer = false;
      console.log(data,'this.issuesthis.issues')
      this.getIssueList(data,true);
      this.resetFilter1 = true;
    },
    groupData(value) {
      let $defaults = localStorage.getItem("_DEFAULTS_");
      if ($defaults) {
        $defaults = JSON.parse($defaults);
      } else {
        $defaults = {};
      }
      $defaults.group = value;
      this.defaultGroup = value;
      localStorage.setItem("_DEFAULTS_", JSON.stringify($defaults));
      this.groupBy = true;
      this.groupValues = this.backUpIssueData.filter(
        (v, i, a) =>
          a.findIndex((v2) => v2[value.value] === v[value.value]) === i
      );
      console.log(this.groupValues, "showgroupvalues");
    },
    async getStagesForWorkspaces() {
      let $defaultFilters = this.getDefaultFilters();
      IssueService.getAllStages($defaultFilters)
        .then(($response) => {
          this.stages = lodash.orderBy($response, ["sequence"], ["asc"]);
        })
        .catch((error) => {
          this.stages = [];
          console.log(error);
        });
      await this.getIssueList();
    },
    changeDisplayType($type) {
      this.displayType = $type;
    },
    getDefaultFilters() {
      let $wsDetails = localStorage.getItem("_WS_DEFAULTS_");
      $wsDetails = JSON.parse($wsDetails);
      let $defaultFilters = {};
      if ($wsDetails.workspace) {
        $defaultFilters.work_space_id = $wsDetails.workspace.id;
      }
      if ($wsDetails.project) {
        $defaultFilters.project_id = $wsDetails.project.id;
      }
      if ($wsDetails.account && $wsDetails.account.id!=0) {
        $defaultFilters.account_id = $wsDetails.account.id;
      }
      if ($wsDetails.category) {
        $defaultFilters.category_id = $wsDetails.category.id;
      }
      return $defaultFilters;
    },
    async getIssueList(data, $flag) {
      console.log(data,'this.issuesss')
      this.issues = [];
      try {
        let $defaultFilters = this.getDefaultFilters();
        $defaultFilters = data
          ? data
          : $defaultFilters;
        let res = [];
        $defaultFilters ={...$defaultFilters}
        //alert(this.$route.query.pastdue)
        if(this.$route.query.pastdue) {
          $defaultFilters.past_due=">0"
          //alert($defaultFilters.past_due)
        }
        if(this.$route.query.priority) {
          $defaultFilters.priority="critical" 
          //alert($defaultFilters.past_due)
        }
        if(this.assignedToMe) {
         // alert(this.assignedToMe)
          $defaultFilters.next_action = this.userData.id
        }
     //   alert($defaultFilters.priority)
        if ($flag) {
          res = await IssueService.getPresetFilters($defaultFilters);
        } else {
          res = await IssueService.findAllIssues($defaultFilters);
        }
      
      if(res && res.length>0){
        /*for(let i=0;i<res.length;i++){
          res[i].created =moment(res[i].created).format('DD-MM-YYYY')
        }*/
      }
        this.issues = [...res];
      //  alert(this.issues && this.issues.length)
        this.backUpIssueData = JSON.parse(JSON.stringify(res));
        this.groupValues = this.backUpIssueData.filter(
          (v, i, a) =>
            a.findIndex(
              (v2) => v2[this.defaultGroup.value] === v[this.defaultGroup.value]
            ) === i
        );
        console.log(this.groupValues, "showgroupvalues");
      } catch (e) {
        console.log("error while getting isssue");
        this.data = [];
      }
    },
    setNewIssue($item,$type){
      if($type=='new'){
        this.backUpIssueData.push($item);
      }else{
        let $index  = this.backUpIssueData.findIndex(k=>k.id==$item.id);
        if($index>-1){
          this.backUpIssueData.splice($index,1,$item);
        }
      }
      
      this.issues = [...this.backUpIssueData];
        this.backUpIssueData = JSON.parse(JSON.stringify(this.issues));
        this.groupValues = this.backUpIssueData.filter(
          (v, i, a) =>
            a.findIndex(
              (v2) => v2[this.defaultGroup.value] === v[this.defaultGroup.value]
            ) === i
        );
    }
  },
};
</script>