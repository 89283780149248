<template>
    <v-card-item style="position: fixed; background-color: #fff;z-index: 2;">
        <v-card-title style="font-size:18px;font-weight:800" class="pt-1">
            <v-card-subtitle class="pb-2">
                <v-icon class="pb-1" @click="goback">mdi-reply-circle
                    <v-tooltip activator="parent" location="start">Go back to parent task</v-tooltip>
                </v-icon>
                <span style="text-transform: capitalize;" v-if="issueData && issueData.account_name">{{ issueData.account_name }} /</span>
                {{ issueData.reference_num }}
                <!-- <small>Id# {{ issueData.id }}</small> -->
                <v-chip size="x-small" class="bg-yellow" v-if="issueData && issueData.qa_required">
                    QA Required
                </v-chip>
                &nbsp;
                <v-btn desity="compact" size="x-small" @click="recurringDialog = true" v-if="recurringTasks.length > 0"
                    label variant="outlined" class="pink-bg">
                    Recurring
                </v-btn>

                <v-icon @click="$emit('closedrawer')" size="medium" class="mdi mdi-close-circle float-right">
                </v-icon>
            </v-card-subtitle>
            <v-row no-gutters dense>
                <v-col cols="8">
                    <v-row no-gutters dense>
                        <v-col cols="12" dense style="line-height:18px;display: flex;align-items: baseline;">
                            <div style=" max-width: 80%;text-wrap: wrap;line-height: 1.5;" class="line-clamp">
                                {{ issueData.name }}
                            </div>
                            <v-icon v-if="exceptStage && showEdit" @click="$emit('edit')" size="18px"
                                class="pl-2 mdi mdi-pencil ">
                            </v-icon>
                            <ShowMailDetails v-if="issueData.mail_details" activator="#menu-activator"
                                :issueData="issueData" />

                        </v-col>
                        <v-col cols="12" dense>
                            <v-label class="text-label" v-if="issueData && issueData.created">
                                <v-icon size="18px" class="pl-2 mdi mdi-folder-plus-outline icon-label ">
                                </v-icon>
                                &nbsp;
                                {{
                                    issueData && issueData.created && moment((issueData.created)).format(
                                        "MM/DD/YYYY hh:mm A") != 'Invalid date'
                                    ? moment((issueData.created)).format(
                                        "MM/DD/YYYY hh:mm A"
                                    )
                                    : ""
                                }}
                            </v-label>
                            <v-label class="text-label" v-if="issueData && issueData.end_date">
                                <v-icon size="18px" class="pl-2 mdi mdi-alarm-plus icon-label ">
                                </v-icon>
                                &nbsp;
                                {{
                                    issueData && issueData.end_date && moment((issueData.end_date)).format(
                                        "MM/DD/YYYY hh:mm A") != 'Invalid date'
                                    ? moment((issueData.end_date)).format(
                                        "MM/DD/YYYY hh:mm A"
                                    )
                                    : ""
                                }}
                            </v-label>
                            <!-- <v-label class="pl-1 text-label" v-if="issueData && issueData.mail_date">
                                <v-icon size="18px" class=" pl-2 mdi mdi-email-outline icon-label">
                                </v-icon>
                                &nbsp;
                                {{
                                    issueData && issueData.mail_date && moment((issueData.mail_date)).format(
                                        "MM/DD/YYYY hh:mm A") != 'Invalid date'
                                    ? moment((issueData.mail_date)).format(
                                        "MM/DD/YYYY hh:mm A"
                                    )
                                    : ""
                                }}
                            </v-label> -->
                            <v-label class="pl-1 text-label title-case">
                                <v-icon size="18px" class=" pl-2 mdi mdi-account-details icon-label ">
                                </v-icon>
                                &nbsp;
                                {{ issueData.submitted_name }}
                            </v-label>
                            <v-label class="pl-1 title-case text-label">
                                <v-icon id="nextaction-menu-activators" size="18px"
                                    class="  pl-2 mdi mdi-account-check-outline icon-label">

                                </v-icon>
                                &nbsp;
                                {{ issueData.next_action_name }}
                            </v-label>
                        </v-col>
                        <v-col cols="12" style="display:contents">
                            <v-chip label size="small" variant="outlined" class="pink-bg mr-1 title-case">
                                {{
                                    issueData && issueData.category_name && issueData.category_name
                                }}
                            </v-chip>

                            <v-chip label size="small" variant="outlined" class=" teal-bg mr-1 title-case">
                                {{
                                    issueData &&
                                    issueData.sub_category_name &&
                                    issueData.sub_category_name
                                }}
                            </v-chip>
                            <v-chip label size="small" variant="outlined" class=" purple-bg title-case"
                                id="issue-type-menu-activator" @click="toggleMenu($event, 'issueType')">
                                <!-- {{ getIssueType }} -->
                                {{
                                    issueData && issueData.issue_type_name && issueData.issue_type_name
                                }}
                                <v-icon v-if="exceptStage && showEdit">mdi-arrow-down-drop-circle</v-icon>
                            </v-chip>
                            <v-chip label variant="outlined" class=" gold-bg mx-1 title-case" size="small"
                                v-if="issueData && issueData.task_name">
                                {{ issueData && issueData.task_name && issueData.task_name }}
                            </v-chip>

                            <v-chip id="priority-menu-activator" variant="outlined" @click="toggleMenu($event, 'priority')"
                                label :style="{
                                    backgroundColor: getBgColors(issueData && issueData.priority),
                                }" size="small">
                                {{
                                    issueData && issueData.priority
                                    ? issueData.priority.charAt(0).toUpperCase() +
                                    issueData.priority.slice(1)
                                    : ""
                                }}
                                <v-icon
                                    v-if="issueData && issueData.stage != 'closed' && showEdit">mdi-arrow-down-drop-circle</v-icon>
                            </v-chip>

                            <v-chip variant="outlined" id="stage-menu-activator" @click="toggleMenu($event, 'stage')" label
                                class="tags purple-bg mx-1 title-case" size="small">
                                {{ getStage(issueData.stage) }}
                                <span v-if="issueData.stage=='followup'">
                                    {{' - '+issueData.no_of_followups}}
                                </span>
                                <v-icon v-if="showEdit">mdi-arrow-down-drop-circle</v-icon>
                            </v-chip>
                        </v-col>
                    </v-row>
                </v-col>

                <v-col cols="4" class="pl-1" style="display: flex; flex-direction: column;justify-content: space-between;">
                    <div class="float-right" style="display: flex;justify-content: end;margin-right: 12px;">
                        <v-btn id="view-menu-activators" rounded size="small" variant="outlined" class="btn-info mr-1">
                            <template v-slot:prepend>
                                <v-icon class="mdi mdi-eye"></v-icon>
                            </template>
                            {{ totalViews.length }}

                        </v-btn>
                        <v-btn id="share-menu-activators" rounded label size="small" variant="outlined"
                            class="btn-info mr-1">
                            <template v-slot:prepend>
                                <v-icon class="mdi mdi-share"></v-icon>
                            </template>
                            {{ totalShares.length }}
                        </v-btn>
                        <v-btn id="follow-menu-activators" rounded label size="small" variant="outlined"
                            class="btn-info mr-1">
                            <template v-slot:prepend>
                                <v-icon class="mdi mdi-flash-outline"></v-icon>
                            </template>
                            {{ totalFollowers.length }}
                        </v-btn>
                        <v-btn @click="toggleFollowing" rounded label size="small" variant="outlined" class="btn-info mr-1">

                            {{ following ? 'Un Follow' : 'Follow' }}
                        </v-btn>
                        <v-btn @click="checkRecurring" v-if="!issueData.parent_issue_id" id="extended-menu-activators" icon label size="24px" variant="flat">
                            <v-icon size="small" class="mdi mdi-dots-vertical" />
                        </v-btn>
                    </div>
                    <div class="playContainer "
                        v-if="exceptStage && showTimer && issueData.stage != 'qapending' && issueData.next_action == this.userData.id">
                        <span class="text-left" style="font-size: 14px; font-weight: 600; opacity: .8;text-align: left;">
                            {{
                                issueData.stage.toLowerCase() == 'inprogress' || issueData.stage.toLowerCase() == 'in progress'
                                ?
                                'Stop when you are done' :
                                'Start recording time'
                            }}</span>

                        <button
                            v-if="exceptStage && showTimer && issueData.stage != 'qapending' && issueData.next_action == this.userData.id"
                            rounded label size="small" variant="outlined" class="float-right btn-info mr-1">
                            <TimerCount :showHomeCard="false" taskType='issue' :keyId="issueData.id" :showPlay="true"
                                :hideCard="false" :startStopWatch="startTimer" :stopStopWatch="stopTimer"
                                :color="'rgb(18, 88, 171)'" />
                        </button>
                    </div>
                </v-col>
            </v-row>


        </v-card-title>

        <PrimeMenu :options="priorities" @optionselected="(data) => { updateData(data, 'priority') }" ref="priorityMenu" />
        <PrimeMenu :options="stages" @optionselected="(data) => { updateData(data, 'stage') }" :menuType="'stage'"
            ref="childStage" />
        <PrimeMenu :options="issueTypes" :menuType="'issueType'"
            @optionselected="(data, value2) => { updateData(data, 'issue_type_id', value2) }" ref="childIssueType" />
        <MenuOptions activator="#view-menu-activators" :id="issueId" :options="totalViews" :showAvatar="true" />
        <MenuOptions activator="#follow-menu-activators" :options="totalFollowers" :type="'follower'" :showAvatar="true" />
        <MenuOptions activator="#shared-list-menu-activators" :options="totalShares" :showAvatar="true" />
        <MenuOptions activator="#extended-menu-activators" :options="menuOptions" :showAvatar="false"
            @menuSelected="menuOptionSelected" />


        <NextAction v-if="exceptStage && showEdit" activator="#nextaction-menu-activators" :options="workSpaceUsers"
            @menuSelected="nextAction" :showAvatar="true" />

        <UserShare activator="#share-menu-activators" :options="allUsers" @menuSelected="shareIssue" :showAvatar="true" />
        <v-dialog v-model="recurringDialog" width="650px" persistent>
            <CreateJob style="overflow: auto;" ref="createjob" :jobId="getJobId" @reload="hasRecurringTask"
                endPoint="recurring" :params="jobParams" @closeDialog="cancel()"></CreateJob>
        </v-dialog>
    </v-card-item>
</template>
<script>
import JobsService from '@/api/service/Jobs/JobsService.js'
import CreateJob from '../../modules/Jobs/Create.vue'
import ShowMailDetails from './ShowMailDetails.vue'
import NextAction from './NextAction.vue'
import UserShare from './NextAction.vue'
import TimerCount from '@/menus/Timer.vue'
import MenuOptions from '../../menus/MenuOptions'
import PrimeMenu from "./PrimeMenu.vue";
import IssueTypeService from "@/api/service/IssueType/IssueTypeService";
import StageService from "@/api/service/Stage/StageService";
import EnumService from "@/api/service/Enum/EnumService";
import UserService from '@/api/service/User/UserService';
import UserService2 from "@/api/service/UserService";
import moment from 'moment'



export default {
    props: ["issueData", "exceptStage", "showEdit", "totalViews", "totalFollowers", "totalShares"],
    components: {
        PrimeMenu,
        CreateJob,
        UserShare,
        NextAction,
        MenuOptions,
        TimerCount,
        ShowMailDetails
    },
    data() {
        return {
            recurringTasks: [],
            jobParams: {
                "issue_id": this.issueData.id
            },
            recurringDialog: false,
            userData: {},
            workSpaceUsers: [],
            allUsers: [],
            menuOptions: [],
            showTimer: true,
            following: false,
            priorities: [],
            stages: [],
            issueTypes: [],
            totalViews2: [],
            backupStage: [],
        }
    },
    watch: {
        issueData() {
            let issueInStore = localStorage.getItem('timer')
            this.hasRecurringTask()
            issueInStore = issueInStore ? JSON.parse(issueInStore) : {}
            let runningIssue = issueInStore && issueInStore.issueId ? issueInStore.issueId : null
            if (runningIssue == this.issueData.id) {
                this.showTimer = true
            } else if (runningIssue == null) {
                this.showTimer = true
            } else {
                this.showTimer = false
            }
            this.stages = this.backupStage.filter((stg) => {
                if(this.issueData && this.issueData.parent_issue_id){
                    // return stg.code !== "qapending" && stg.code !== "qacompleted" && stg.code !== "inprogress" && stg.code!=='completed';
                    return stg.code === "open" || stg.code === "assigned" || stg.code === "pending" || stg.code==='closed' || stg.code==='cancel';

                }
                else{
                    return stg.code !== "qapending" && stg.code !== "qacompleted" && stg.code !== "inprogress"
                }
            })
        }
        
    },
    async created() {
        let $userDetails = localStorage.getItem('user_details')
        this.userData = $userDetails ? JSON.parse($userDetails) : {}
        this.issueTypes = await IssueTypeService.findAll();
        this.stages = await StageService.findAll();
        this.backupStage = JSON.parse(JSON.stringify(this.stages))
        this.stages = this.backupStage.filter((stg) => {
                if(this.issueData && this.issueData.parent_issue_id){
                    // return stg.code !== "qapending" && stg.code !== "qacompleted" && stg.code !== "inprogress" && stg.code!=='completed';
                    return stg.code === "open" || stg.code === "assigned" || stg.code === "pending" || stg.code==='closed' || stg.code==='cancel';

                }
                else{
                    return stg.code !== "qapending" && stg.code !== "qacompleted" && stg.code !== "inprogress"
                }
        })

        this.isFollowing()
        this.getUsers()
        this.getAllUsers()
        this.hasRecurringTask()
        this.priorities = await EnumService.findAll({ domain: "PRIORITY" });
        //this.getTotalViews()
    },
    computed: {
        getJobId() {
            if (this.recurringTasks && this.recurringTasks.length > 0) {
                return this.recurringTasks[0].id
            }
            return null
        }
    },
    methods: {
        moment,
        cancel() {
            this.recurringDialog = false
        },

        checkRecurring(){
            if(this.recurringTasks.length > 0){
                this.menuOptions = [{ "code": "remove", "name": "Remove recurring task" }]
            }else{
                this.menuOptions = [
                    {"code": "recurring", "name": "Setup recurring task"},
                    {"code": "followup", "name": "Add Followup",},
                    {"code": "itSupportfollowup", "name": "Add IT Support Followup",}
                ]
            }
            let $user = localStorage.getItem("user_details")
            $user = JSON.parse($user)
            if($user.roles.role_type<=5) {
                this.menuOptions.push({"code": "qa", "name": "Toggle QA Required",})
            }
        },
        async hasRecurringTask() {
            let $recurring = await JobsService.hasRecurringTask({ "issueId": this.issueData.id })
            this.recurringTasks = $recurring

        },
        menuOptionSelected($selected) {
            if($selected.code=='recurring') {
                this.recurringDialog = true
            } else {
                this.$emit('extended-menu-selected',$selected)
            }
            console.log($selected)
        },
        getUserName($item) {
            return $item.first_name && $item.last_name
                ? `${$item.first_name} ${$item.last_name}`
                : $item.first_name;
        },

        goback() {
            if(this.issueData.parent_issue_id){
                let id = this.issueData.parent_issue_id
                this.$store.dispatch('setIssueInView', id)
            }else{
                this.$emit('closedrawer')
            }
        },
        toggleFollowing() {
            //alert(JSON.stringify(this.userData,null,4))
            this.following = !this.following
            this.$emit('togglefollowing', this.userData.id, this.following, this.userData.username)
        },
        isFollowing() {
            let $following = this.totalFollowers.filter(obj => {
                return obj.id == this.userData.id
            })
            if ($following.length > 0) {
                this.following = true
            } else {
                this.following = false
            }

        },
        async getPriority() {
            let res = await EnumService.findAll({ domain: "PRIORITY" });
            this.priorities = res;
        },
        async getTotalViews() {
            let $watchers = this.issueData.watchers ? JSON.parse(this.issueData.watchers) : []

            if ($watchers.length > 0) {
                this.totalViews2 = await UserService.findAll({ id: $watchers });
            } else {
                this.totalViews2 = []
            }
        },
        localeDate($date) {
            return new Date($date).toLocaleDateString("en-US", {
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
            });
        },

        updateData($item, $column, $value2) {
            let $data = {
                id: this.issueData.id
            };
            $data[$column] = $item
            this.$emit('updateData', $data, $column, $value2)
        },
        toggleMenu(event, type) {

            if (type == 'priority' && this.showEdit && this.issueData.stage != 'closed') {
                this.$refs.priorityMenu.toggle(event);
            } else if (type == 'stage' && this.showEdit) {
                this.$refs.childStage.toggle(event);
            } else if (type == 'issueType' && this.showEdit && this.issueData.stage != 'closed') {
                this.$refs.childIssueType.toggle(event);
            }
        },
        getStage($stage) {
            console.log(this.stages, $stage, "getting stage data")
            let opt = this.backupStage.filter((k) => k.code == $stage);
            return opt && opt[0] && opt[0].name ? opt[0].name : '';
        },
        async getAllUsers() {
            try {
                let res = await UserService.findAll()
                this.allUsers = res.length ? res : [];
            }
            catch (e) {
                this.allUsers = []
            }
        },
        async getUsers() {
            try {
                let wsDefaults = JSON.parse(localStorage.getItem("_WS_DEFAULTS_"))
                let workSpaceId = localStorage.getItem("workSpaceId")
                let res = await UserService2.getNextAction({ work_space_id: workSpaceId, account_id: wsDefaults.account.id, project_id: wsDefaults.project.id })
                this.workSpaceUsers = res.length ? res : [];
            }
            catch (e) {
                this.workSpaceUsers = []
            }
        },
        shareIssue($item) {
            let $data = {
                'shared_by': {
                    "id": this.userData.id,
                    "name": this.getUserName(this.userData)
                },
                'shared_with': {
                    "id": $item.id,
                    "name": this.getUserName($item)
                },
            }
            this.$emit('shared', $data)
        },
        nextAction($item) {
            let $data = {
                id: this.issueData.id,
                next_action: $item.id
            }
            let $data2 = {
                "next_action_name": this.getUserName($item)
            }
            this.$emit('updateData', $data, 'next_action_id', $data2)
        },
        getBgColors(name) {
            if (name == "low") return "#D1FFBD";
            else if (name == "high") return "#FFCCCB";
            else if (name == "medium") return "#F9C644";
            else return "#F0ECFF ";
        }
    }
}
</script>
<style scoped>
.playContainer {
    left: -5px;
    padding: 10px;
    margin-right: 9px;
    position: relative;
    width: 98%;
    background-color: #f5fbfe;
    height: 58%;
    border: 1px solid rgb(224, 243, 253);
    border-radius: 4px;
    align-items: center;
}

.line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.btn-info {
    border-color: #adadad;
}

.icon-label {
    color: #000;
    font-weight: 800;
}

.text-label {
    color: #272727;
    font-weight: 400;
    text-transform: capitalize
}

.pink-bg {
    background-color: #f8d6e6 !important;
    opacity: 1 !important;
}

.purple-bg {
    background-color: #e2d6f8 !important;
}

.gold-bg {
    background-color: #f8f2d6;
}

.teal-bg {
    background-color: #d6f8f6;
}

.grey-bg {
    background-color: #f4f4f423;
}

.outlined {
    border: 1px solid #dfdfdf;
}

.bold-medium {
    font-weight: 600;
}
</style>