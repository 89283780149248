<template>
   <v-card flat>
        <v-form @submit.prevent="create" ref="form">
            <v-card-title>
                Delete Dashboard
                <v-btn flat icon="mdi mdi-close" class="mt-1 float-right" density="compact" size="small"
                    @click="$emit('closeDialog')" />
            </v-card-title>

            <v-card-text>
                Are you sure you want to delete this <b>{{ item && item.name?item.name:'' }}</b> ?
            </v-card-text>

            <v-card-actions style="display: flex;justify-content: end;">
                <v-btn @click="$emit('delete',item)"  style="background-color:#1258AB;color:#fff;width:80px;" flat type="submit" 
                    color="#1258AB" >Delete</v-btn>
            </v-card-actions>
        </v-form>
    </v-card>
</template>

<script>
export default {
    components: {
    },
    props:['item'],
    created(){
        console.log(this.item , 'ifYouNeed')
    }
}
</script>

<style>

</style>