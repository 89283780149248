const apiConfig = {
    "getWayUrl": "https://79a4z7n2g5.execute-api.ap-south-1.amazonaws.com/dev",
    // "baseUrl":" https://synergy.axodesk.com:8081",
     //"baseUrl":"https://synergy-uat.axodesk.com:8081",
    "baseUrl":"https://synergy-cf-live.axodesk.com:8081",
    "appName": "Identti Front End",
    "apiKey": "apiKey",
    "secrect": "secrect",
    "appId": "b0dc92bf-3710-4e11-a87d-4d72585cd281"
}
export default apiConfig;
