<template>
  <PrimeDataTable
    :title="'Contact'"
    :showFilter="true"
    :searchOption="true"
    :selectAll="true"
    :showDownload="true"
    :showUpload="false"
    :showAdd="true"
    :columnData="columns"
    :data="data"
    @addDiolog="addDiolog()"
    @rowAction="rowActions($event)"
    activity="contact"
  />

  <v-dialog persistent v-model="dialog" width="850px">
    <CreateContactVue
      @closeDialog="close()"
      @reload="getContact()"
      :id="editDataId"
      :viewType="viewType"
    />
  </v-dialog>  
</template>
<script>
import PrimeDataTable from '@/components/PrimeDataTable.vue';
import CreateContactVue from './CreateContact.vue';
import ContactService from '@/api/service/Contact/ContactService';

export default{
    components:{
        PrimeDataTable,
        CreateContactVue
    },
    data(){
     return{
       dialog: false,
       columns: [],
       data: [],
       editDataId: null,
       viewType: false,
     }
    },
    created(){
    this.getContacts();
    this.loadcolumns();
    },
    methods:{
     async getContacts(){
        let res = await ContactService.findAll();
        this.data = res;
     },
     loadcolumns(){
        this.columns = [
        {
          field: "code",
          header: "Code",
          style: { width: "100px", color: "" },
          toolTip: "Code",
          sortfield: true,
        },
        {
          field: "first_name",
          header: "First Name",
          style: { width: "100px", color: "" },
          toolTip: "First Name",
          sortfield: true,
        },
        {
          field: "last_name",
          header: "Last Name",
          style: { width: "100px", color: "" },
          toolTip: "Last Name",
          sortfield: true,
        },
        {
          field: "mobile_no",
          header: "Mobile No",
          style: { width: "100px", color: "" },
          toolTip: "Mobile No",
          sortfield: true,
        },
        {
          field: "status",
          header: "Status",
          style: { width: "100px", color: "" },
          toolTip: "Status",
          sortfield: true,
        },
        {
          field: "action",
          header: "Action",
          style: { width: "100px", color: "" },
          toolTip: "Action",
          sortfield: true,
          rowActions: [
            { action: "view" },
            { action: "edit" },
            // { action: "delete" },
          ],
        },
      ];
     },
    close() {
      this.dialog = false;
      this.getContacts();
    },
    addDiolog(){
      this.viewType = false;
      this.editDataId = null;
      this.dialog = true;
    },
    rowActions($event) {
      if ($event.type == "view") {
        this.dialog = true;
        this.viewType = $event.type;
        this.editDataId = $event.data.id;
      }
      if ($event.type == "edit") {
        this.dialog = true;
        this.editDataId = $event.data.id;
        this.viewType = $event.type;
      }

      if ($event.type == "select") {
        this.dialog = true;
        this.editDataId = $event.data.id;
        this.viewType = "view";
      }
    },
    }
}
</script>
