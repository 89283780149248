<template>
    <v-layout>
        <v-navigation-drawer location="right" v-model="drawer" temporary>
            <v-card style="width: 50vw;">
                <IndexView v-if="viewId" :itemId="viewId" :processView="indexView" />
            </v-card>
        </v-navigation-drawer>
        <v-main style="width:0px !important">
            <div style="overflow: auto;height: 90vh;">
                <v-card flat class="pa-1" style="width:100%">
                    <v-card-item>
                        <v-card-title class="text-body-2">
                            <DashboardSelection :updateList="selectedDashboard" @dashboard-selected="dashboardSelected"  ref="childRef"  />
                     
                                <v-btn class="mr-2" flat density="compact" size="small" icon="mdi mdi-plus" color="#1258AB"
                                    style="color:#fff;" @click="dialog('addDashboard')" />
                                    
                                    <v-btn 
                                    v-if="selectedDashboard" 
                                    flat density="compact" size="small" icon="mdi mdi-delete-outline" color="#1258AB"
                                    style="color:#fff;" @click="dialog('deleteboard')" />
                                
                            <v-btn @click="dialog('addWidget')" text color="primary" class="float-right" v-if="showNewWid">
                                New Widget
                            </v-btn>


                            <UserShare  v-if="selectedDashboard && selectedDashboard.widgets && selectedDashboard.widgets.length > 0"  :options="allUsers" :showAvatar="true" />

                            <v-btn v-if="selectedDashboard && selectedDashboard.widgets && selectedDashboard.widgets.length > 0"
                                @click="hideZeroIssues()" flat class="float-right shareBtn mr-4">
                                <v-icon>{{ getIcon }}</v-icon>
                                <v-tooltip activator="parent" location="bottom">
                                    <span>{{ hideNullGroup ? 'Show all groups' : 'Hide groups with no Tasks' }}</span>
                                </v-tooltip>
                            </v-btn>

                        </v-card-title>
                    </v-card-item>
                </v-card>
                <v-card flat class="pa-1" style="width:100%">
                    <v-card-text class="boardConatiner">
                        <div class="flexWise" id="flexBox" :style="{ width: flexBoxWidth() }" v-if="selectedDashboard">  

                            <template v-for="(widget, index) in selectedDashboard.widgets" v-bind:key="index">
                                <div v-if="clickedIndexes.includes(index)" style="flex-basis:100%;width:100%;"></div>
                                <div :id="'widget' + index" :class="fixedIndex === index ? 'fixedClass' : ''">
                                    <Board @drop="onDrop($event, index)" @dragenter.prevent @dragover.prevent
                                        :fixedIndex="fixedIndex" @deleteWidget="deleteWidget(index)" draggable="true"
                                        @dragstart="dragStart($event, index)" :settingIndex="index" :config="widget"
                                        @changeRow="handleBoardClick(index)" @fixedBoard="fixeSelectedBoard(index)"
                                        :hideNullGroup="hideNullGroup" @draweView="openView"
                                        @open-setting="changeInSetting" />
                                </div>
                            </template>

                        </div>

                    </v-card-text>
                </v-card>
            </div>
            <v-dialog peristent :max-width="getWidth()" v-model="createDialog">
                <CreateDashboard v-if="dialogOptions['addDashboard']" @closeDialog="closeDialog()"
                    @dashboardCreated="dashboardCreated" />
                <DeleteDashboard v-if="dialogOptions['deleteboard']" @closeDialog="closeDialog()"
                    @delete="deleteTheBoard($event)" :item="selectedDashboard" />
                <WidgetTypeSelection v-if="dialogOptions['addWidget']" :settingData="settingData"
                    :selectedIndex="selectedIndex" widgetType="issue" @closeDialog="closeDialog()"
                    @applychanges="applyChanges" />
            </v-dialog>
        </v-main>
    </v-layout>
</template>
<script>
import Board from './Board.vue'
import UserShare from './ShareMenu.vue'
import WidgetTypeSelection from './WidgetTypeSelection.vue'
import DashboardSelection from './DashboardSelecton.vue'
import CreateDashboard from './CreateDashboard.vue'
import DashboardWidgetService from '@/api/service/DashboardWidget/DashboardWidgetService'
import IndexView from '@/modules/issues/views/Index.vue'

import UserService from '@/api/service/User/UserService';
import DeleteDashboard from './DeleteDashboard.vue'
export default {
    components: {
        Board,
        UserShare,
        WidgetTypeSelection,
        CreateDashboard,
        DashboardSelection,
        DeleteDashboard,
        IndexView
    },
    data() {
        return {
            clickedIndexes: [],
            selectedType: null,
            fixedIndex: null,
            showNewWid: false,
            drawer: false,
            selectedIndex: null,
            viewId: null,
            dialogOptions: {
                addDashboard: false,
                deleteboard: false,
                addWidget: false
            },
            settingData: null,
            selectedDashboard:null,
            createDialog: false,
            showInGrid: false,
            hideNullGroup: false,
            indexView: false,
            allUsers: [],
        }
    },

    created() {
        this.getAllUsers()
    },
    computed: {

        getIcon() {
            return this.hideNullGroup ? 'mdi-eye-outline' : 'mdi-eye-off-outline';
        },
    },
    methods: {

        async getAllUsers() {
            let res = await UserService.findAll()
            this.allUsers = res.length ? res : [];
            console.log(this.allUsers, 'dkjjdsnncmnsc')
        },

        changeInSetting($event) {
            this.selectedIndex = $event
            this.createDialog = true
            this.dialogOptions['addWidget'] = true
            console.log(this.settingData, "testing by bala 11111111")
            this.settingData = this.selectedDashboard.widgets[$event]
            console.log(this.settingData, "testing by bala 11111111")
            this.settingData = { ...this.settingData }

        },
        async dragStart(event, index) {
            event.dataTransfer.dropEffect = "move";
            event.dataTransfer.effectAllowed = "move";
            event.dataTransfer.setData("index", index);
        },
        async deleteTheBoard($event){
            this.createDialog = false
            console.log($event,"hello balaganesh")
            if($event && $event.id)
            var obj ={
                id:$event.id,
                active:0,
                deleted:1
            }            
            this.dialogOptions['deleteboard'] = false
            this.$refs.childRef.selectedOption =null
            let res = await DashboardWidgetService.update(obj)
            console.log(res)
            this.$refs.childRef.getDashboard()            
            this.selectedDashboard = null
            this.showNewWid = false
        },

        async onDrop(event, index) {
            let comingIndex = event.dataTransfer.getData("index")
            let $obj = JSON.parse(JSON.stringify(this.selectedDashboard.widgets[comingIndex]))
            let $obj1 = JSON.parse(JSON.stringify(this.selectedDashboard.widgets[index]))
            this.selectedDashboard.widgets.splice(index, 1, $obj)
            this.selectedDashboard.widgets.splice(comingIndex, 1, $obj1)

        },

        deleteWidget(i){
            if(i>-1){
            console.log(this.selectedDashboard,"selection dashboard")
            this.selectedDashboard.widgets.splice(i,1)
            this.selectedDashboard.widgets =[...this.selectedDashboard.widgets]
            this.selectedDashboard ={...this.selectedDashboard}
            let updateData=JSON.parse(JSON.stringify(this.selectedDashboard))
            updateData.widgets =JSON.stringify(updateData.widgets)
            DashboardWidgetService.update(updateData) 
            }
        },

        fixeSelectedBoard(index) {
            if (index === this.fixedIndex) {
                this.fixedIndex = null
            } else {
                this.fixedIndex = index
            }
            console.log(index, 'dsvjsdbdbsd')
        },
        handleBoardClick(index) {
            // console.log('Board clicked:', index);
            console.log('clickedIndexes before:', this.clickedIndexes);

            const indexPosition = this.clickedIndexes.indexOf(index);
            if (indexPosition === -1) {
                this.clickedIndexes.push(index);
            } else {
                this.clickedIndexes.splice(indexPosition, 1);
            }
            this.flexBoxWidth()
        },

        flexBoxWidth() {
            if (this.clickedIndexes.length > 0) {
                let box = document.getElementById("flexBox");
                let boxWidth = box.getBoundingClientRect().width;
                let totalWidth = 0;
                for (let index of this.clickedIndexes) {
                    let childBox = document.querySelector(`#widget${index}`);

                    if (childBox) {
                        let childWidth = childBox.getBoundingClientRect().width;
                        totalWidth += childWidth;
                    } else {
                        console.error(`widget${index} not found.`);
                    }
                }
                return `${boxWidth - totalWidth + 310}px`;
            } else {
                return 'max-content'
            }
        },

        openView(item) {
            let status = this.$store.getters.getMyspace;
            localStorage.setItem("_WS_DEFAULTS_", JSON.stringify({ "workspace": { "id": item.work_space_id, "name": item.work_space_name } }))
            localStorage.setItem("workSpaceId", item.work_space_id);

            // this.$router.push({ path: "/issueView2", query: { id: item.id } });
            this.$store.dispatch('setIssueInView', item.id)
            let wsDefaults = {
                workspace: { id: item.work_space_id, name: item.work_space_name },
                account: { id: item.account_id, name: item.account_name },
                project: { id: item.project_id, name: item.project_name }
            }
            let wsDefaultsData = JSON.stringify(wsDefaults)
            localStorage.setItem("_WS_DEFAULTS_", wsDefaultsData);
            // console.log(wsDefaultsData,'alert111111111')
            this.$store.commit("SET_REFRESH_BREADCRUMP", true)
            this.$store.commit("CHANGE_MY_SPACE", !status);
        },
        hideZeroIssues() {
            this.hideNullGroup = !this.hideNullGroup
        },
        resetDialogOptions() {
            let $keys = Object.keys(this.dialogOptions)
            for (let $key of $keys) {
                this.dialogOptions[$key] = false
            }
        },
        closeDialog() {
            this.resetDialogOptions()
            this.createDialog = false
        },
        dialog($type) {
            this.resetDialogOptions()
            this.createDialog = true
            this.dialogOptions[$type] = true
            this.settingData = null
        },
        getWidth() {
            if (this.dialogOptions.addDashboard) {
                return '500px';
            } else if (this.dialogOptions.addWidget) {
                return '80vw'
            }else if (this.dialogOptions.deleteboard) {
                return '500px'
            }
        },
        dashboardSelected($item) {
            // console.log($item, "getting emit item")
            // alert($item)
            if ($item && $item.widgets && typeof $item.widgets == 'string') {
                $item.widgets = $item.widgets ? JSON.parse($item.widgets) : []
            }
            else if ($item && !$item.widgets) {
                $item.widgets = []
            }
            //alert(JSON.stringify($item))
            this.selectedDashboard = $item
            this.showNewWid = true
        },
        dashboardCreated($item) {
            if ($item && $item.widgets && typeof $item.widgets == 'string') {
                $item.widgets = $item.widgets ? JSON.parse($item.widgets) : []
            }
            else if ($item && !$item.widgets) {
                $item.widgets = []
            }
            this.selectedDashboard = $item
            this.createDialog = false
            this.dialogOptions['addDashboard'] = false
        },
        async applyChanges($item, index) {
            console.log($item, "iiiiiiiiiiiiiiiiiii")
            if (index == null) {
                this.selectedDashboard.widgets.push($item)
            }
            else {
                this.selectedDashboard.widgets[index] = $item
            }

            let $board = Object.assign({}, this.selectedDashboard)
            $board.widgets = JSON.stringify($board.widgets)
            await DashboardWidgetService.update($board)
            this.refreshWidget()
        },
        refreshWidget() {
            this.closeDialog()
        }
    }
}
</script>
<style scoped>
.shareBtn {
    border: 1px solid;
    border-radius: 20px;
}

.flexWise {
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
    min-width: 100vw;
    /* width: max-content; */
    margin-right: 75px;
}
.deleteButton{
    height: 22px;width: 22px;border-radius: 50%;background-color:#1258AB;display: flex;align-items: center;justify-content: center;
}
.boardConatiner {
    overflow: hidden;
    background-color: #efefef;
    width: 94vw;
    height: 80vh;
}

.boardConatiner:hover {
    overflow: auto;
}

.fixedClass {
    padding-left: 17px;
    z-index: 35;
    position: absolute;
    left: 0px;
    top: 4px;
    background: #efefef;
    height: 78vh;
    overflow: hidden;
    padding-top: 15px;
}

.fixedClass:hover {
    overflow: auto;
}

>>>.v-navigation-drawer {
    width: 50% !important;
    position: absolute !important;
    top: 72px !important;
    height: 90vh !important;
    z-index: 45 !important;
}

.v-navigation-drawer__scrim {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: black;
    opacity: 0.2;
    transition: opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    z-index: 43 !important;
}


>>>::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    position: fixed;
    bottom: 70%;

}

/* Track */
>>>::-webkit-scrollbar-track {
    background: #e8f3f9;
}

/* Handle */
>>>::-webkit-scrollbar-thumb {
    background: rgb(0, 0, 0, 0.12);
    border-radius: 12px !important;
}</style>