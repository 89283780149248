<template>
  <v-dialog v-model="dialog" width="700px">
    <v-card style="border-radius: 8px">
      <v-row class="diolog-header">
            <v-card-title  >Filter</v-card-title>
            <v-btn @click="dialog = false" elevation="0" density="comfortable" icon="mdi-close" class="x-closeIcon"></v-btn></v-row>
      
       <v-container class=" mt-8 mb-8">
      <form @submit.prevent="addcategory">
        <v-col>
          <TextFieldComponent
            density="compact"
            placeholder="Code"
            v-model="formData.code"
          ></TextFieldComponent>
        </v-col>
        <v-col>
          <TextFieldComponent
            density="compact"
            placeholder="Name"
            v-model="formData.name"
          ></TextFieldComponent>
        </v-col>
       


           <v-row justify="end" class="dialogBottom">
            <v-btn class="mr-2 cancel" @click="dialog = false">Cancel</v-btn>
            <!-- <v-btn @Click=submit() class="save">Save</v-btn> -->
          </v-row>
        
      </form>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import TextFieldComponent from "@/components/TextFieldComponent.vue";
// import categoryService from "@/api/service/Category/CategoryService"
export default {
    components: {
    TextFieldComponent,
  },
  data() {
    return {
      dialog: false,
      formData: {
        code: "",
        name: "",
      },
    };
  },

}
</script>

<style>

</style>