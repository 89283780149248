<template>
  <v-row dense class="ml-3">
    <v-col cols="2">
      <text-field-component-vue @keyup="$emit('SearchData', search)" v-model="search"
        placeholder="Search"></text-field-component-vue>
    </v-col>
    <v-col>
      <span>
        <v-btn variant="text" class="pa-2 btn-color" @click="$emit('listClicked')">
          <img src="https://synergy-snaps.s3.ap-south-1.amazonaws.com/public/icons/Backlog_gray.svg"
            style="width: 16px; height: 16px" />
          &nbsp; List
        </v-btn>
      </span>

      <span>
        <v-btn variant="text" class="pa-2 btn-color" @click="$emit('boardClicked')">
          <img src=" https://synergy-snaps.s3.ap-south-1.amazonaws.com/public/icons/Board_gray.svg "
            style="width: 16px; height: 16px" />
          &nbsp; Board
        </v-btn>
      </span>

      <span>
        <v-btn variant="text" id="sort-filter-activators" class="pa-2 btn-color">


          <!-- This is the filter Part -->
          <span>
            <v-icon class="filterIcon">mdi-filter-outline</v-icon>
            <v-menu v-model="menu" activator="parent" transition="slide-x-transition" :close-on-content-click="false"
              location="bottom">
              <v-list width="350px">
                <DashboardFilter :type="'openIssue'" :existFilterData="defaultOptions" @applyChange="filterTheData" />
              </v-list>
            </v-menu>
          </span>


          &nbsp; Preset Filters
          <img src=" https://synergy-snaps.s3.ap-south-1.amazonaws.com/public/icons/chevron_down.svg "
            style="width: 16px; height: 16px" />
        </v-btn>
      </span>
      <span v-if="resetFilter">
        <v-btn variant="text" id="filter-menu-activators" class="pa-2 btn-color" @click="$emit('filterClose')">
          <img src=" https://synergy-snaps.s3.ap-south-1.amazonaws.com/public/icons/filter_outline.svg "
            style="width: 16px; height: 16px" />
          &nbsp; Reset Filters
          <img src=" https://synergy-snaps.s3.ap-south-1.amazonaws.com/public/icons/close.svg "
            style="width: 13px; height: 9px" />
        </v-btn>
      </span>

      <span>
        <v-btn variant="text" id="sort-menu-activators" class="pa-2 btn-color" @click="$emit('sortClicked')">
          <img src=" https://synergy-snaps.s3.ap-south-1.amazonaws.com/public/icons/Sort_up.svg "
            style="width: 16px; height: 16px" />

          &nbsp; {{ selectedSort && selectedSort.title }}
          <img src=" https://synergy-snaps.s3.ap-south-1.amazonaws.com/public/icons/chevron_down.svg "
            style="width: 16px; height: 16px" />
          <v-tooltip activator="parent" location="bottom">Sort</v-tooltip>
        </v-btn>
      </span>

      <span :style="{ 'display': displayType != 'board' ? 'none' : '' }">
        <v-btn v-if=false variant="text" id="group-menu-activators" class="pa-2 btn-color" @click="$emit('sortClicked')">
          <img src=" https://synergy-snaps.s3.ap-south-1.amazonaws.com/public/icons/Timeline.svg "
            style="width: 16px; height: 16px" />

          &nbsp; {{ selectedGroup && selectedGroup.title }}
          <img src=" https://synergy-snaps.s3.ap-south-1.amazonaws.com/public/icons/chevron_down.svg "
            style="width: 16px; height: 16px" />
          <v-tooltip activator="parent" location="bottom">Group By</v-tooltip>
        </v-btn>
      </span>

      <span>
        <v-btn variant="text" id="more-menu-activators" class="pa-2 btn-color">
          More..
          <v-icon icon="mdi mdi-angle-down" size="x-small"></v-icon>
        </v-btn>
      </span>
      <span v-if="displayType == 'board'">
        <v-btn variant="text" id="filter-menu-activators" class="pa-2 btn-color"
          :class="{ boardbutton: !zeroBoardIssues }" @click="$emit('zeroIssuesBoard')">
          &nbsp; Stage with zero Tasks
          <img v-if="zeroBoardIssues" src=" https://synergy-snaps.s3.ap-south-1.amazonaws.com/public/icons/check.svg "
            style="width: 16px; height: 16px" />
        </v-btn>
      </span>
      <span>

        <v-btn variant="text" id="assigned-to-me-menu-activators" class="pa-2 ml-2 btn-color"
          :class="{ boardbutton: assignedToMe }" @click="$emit('assignedtome')">
          &nbsp; Assigned to me
          <img v-if="!assignedToMe" src=" https://synergy-snaps.s3.ap-south-1.amazonaws.com/public/icons/check.svg "
            style="width: 16px; height: 16px" />
        </v-btn>
      </span>
      <span>
        <v-btn @click="$router.push('/addQuickIssue?from=topMenu')" variant="text" id="share-menu-activators"
          class="pa-2 btn-color">
          <v-icon style="height: 18px; width: 18px">mdi-plus</v-icon>
          <v-tooltip activator="parent" location="bottom">Add Task</v-tooltip>
        </v-btn>
      </span>
    </v-col>
  </v-row>
  <MenuOptions v-on:menuSelected="(value) => {
    selectedGroup = value;
    $emit('group', value);

  }
    " :options="groupMenuOptions" activator="#group-menu-activators" />
  <MenuOptions v-on:menuSelected="(value) => {
    selectedMore = value;
    $emit('more', value);
  }
    " :options="moreMenuOptions" activator="#more-menu-activators" />
  <MenuOptions v-on:sortData="(value) => {
    selectedSort = value;
    $emit('sortData', value);
  }
    " :options="sortMenuOptions" :type="'sort'" activator="#sort-menu-activators" @sortData='sortData' />
  <!-- <MenuOptions v-on:menuSelected="(value) => { selectedSort = value; $emit('filter', value) }" :options="defaultFilter"
        activator="#sort-filter-activators" /> -->
  <v-menu :close-on-content-click="menuclose" activator="#sort-filter-activators">
    <v-list style="width: 100% !important">
      <v-list-item @click="closeFilterMenu(item)" v-for="(item, index) in defaultFilter" :key="index" :value="index">
        <template v-if="showAvatar" v-slot:prepend>
          <Avatar size="xsmall" :label="getAvatarName(item)" class="mr-2" :style="` background:${item.theme}`"
            style="color: #880e4f" shape="circle" />
        </template>
        <v-checkbox-btn @change="action(item.isSelected, item)" :label="item.title"
          v-model="item.isSelected"></v-checkbox-btn>
        <!-- <v-list-item-title @click="action(index, item)">
          {{ item.title ? item.title : item.name }}
        </v-list-item-title> -->
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import MenuOptions from "@/menus/MenuOptions.vue";
import moment from "moment";
import TextFieldComponentVue from "../../components/TextFieldComponent.vue";
import DashboardFilter from "../dashboard/Widgets/DashboardFilter.vue"
// import { PrimeIcons } from 'primevue/api';

export default {
  props: ["defaultSort", "defaultGroup", "resetFilter", "zeroBoardIssues", "displayType", "assignedToMe"],
  components: {
    TextFieldComponentVue,
    DashboardFilter,
    MenuOptions,
  },

  created() {

    let defaltGroup = localStorage.getItem("_DEFAULTS_")
    this.selectedGroup = defaltGroup ? JSON.parse(defaltGroup).group : this.selectedGroup
    /*  if(this.defaultGroup.title) {
             this.selectedGroup = this.defaultGroup
         }
         if(this.defaultSort.title) {
             this.selectedSort= this.defaultSort
         } */
  },

  data() {
    return {
      menu: false,
      menuclose: false,
      search: "",
      showFilter: false,
      userData: JSON.parse(localStorage.getItem("user_details")),
      defaultOptions: {},
      selectedGroup: { title: "Assigned", value: "next_action_name" },
      // selectedGroup:JSON.parse(localStorage.getItem("_DEFAULTS_")).group,
      selectedMore: { title: "Bulk Update", value: "created" },
      selectedSort: { title: "By Latest", value: "created" },
      groupMenuOptions: [
        { title: "Assigned", value: "next_action_name", column: "next_action" },
        { title: "Created", value: "created", column: "" },
        { title: "Reporter", value: "submitted_name", column: "submitted_by" },
        { title: "Category", value: "category_name", column: "category_id" },
        {
          title: "Sub Category",
          value: "sub_category_name",
          column: "sub_category_id",
        },
        {
          title: "Task Type",
          value: "issue_type_name",
          column: "issue_type_id",
        },
        { title: "Sub Task", value: "task_name", column: "task_id" },
      ],
      moreMenuOptions: [
        { title: "Bulk Update", value: "Bulk Update" },
        { title: "Export as Excel", value: "export" },
      ],
      sortMenuOptions: [
        { title: "Ref#", value: "reference_num", column: "reference_num" },
        { title: "Account", value: "account_name", column: "account_name" },
        { title: "Assigned", value: "next_action_name", column: "next_action" },
        { title: "Created", value: "created", column: "created" },
        { title: "Mail Date", value: "mail_date", column: "mail_date" },
        { title: "Due date", value: "end_date", column: "due_date" },
        { title: "Assigned", value: "next_action_name", column: "next_action" },
        { title: "Reporter", value: "submitted_name", column: "submitted_by" },
        { title: "Category", value: "category_name", column: "category_id" },
        { title: "Sub Category", value: "sub_category_name", column: "submitted_by" },
        { title: "Task Type", value: "issue_type_name" },
        { title: "Sub task", value: "task_name" },
      ],

      // defaultFilter:
      // [
      //     { title: "Assigned Me", "value": "next_action" },
      //     { title: "Reported by Me", "value": "submitted_by" },
      //     { title: "Past Due", "value": "past_due" },
      //     { title: "Due Today", "value": "due_today" },
      //     { title: "More Custom Filers..", "value": "custom" },
      // ],

      defaultFilter: [
        { title: "Assigned Me", value: "next_action", isSelected: false },
        { title: "Reported by Me", value: "submitted_by", isSelected: false },
        { title: "Past Due", value: "past_due", isSelected: false },
        { title: "Due Today", value: "due_today", isSelected: false },
      ],
    };
  },
  methods: {
    moment,
    sortData(data) {
      console.log(data, 'fdvhjdsjhdsj')
    },

    filterTheData(data) {
      this.$emit('filterTaskData', data);
      this.menu = false
    },
    action($index, $item) {
      if ($item.value != "custom") {
        if (this.defaultOptions && this.defaultOptions.custom) {
          delete this.defaultOptions.custom;
        }
        if ($item.isSelected) {
          if ($item.value == "next_action") {
            this.defaultOptions[$item.value] = this.userData.id;
          } else if ($item.value == "submitted_by") {
            this.defaultOptions[$item.value] = this.userData.id;
          } else if ($item.value == "past_due") {
            this.defaultOptions[$item.value] = `>=${moment().format(
              "YYYY-MM-DD"
            )}`;
          } else if ($item.value == "due_today") {
            this.defaultOptions[$item.value] = moment().format("YYYY-MM-DD");
          }
        } else {
          delete this.defaultOptions[$item.value];
        }
      } else {
        this.defaultOptions[$item.value] = "custom";
      }

      console.log(this.defaultOptions, "this.defaultOptions");
      this.$emit("filter", this.defaultOptions);
      if ($item.value == "custom" && $item.isSelected) {
        this.showFilter = false;
        this.defaultFilter.forEach((e) => {
          e.isSelected = false;
        });
      }
    },
    closeFilterMenu(item) {
      if (item.value == "custom") {
        this.menuclose = true;
      } else {
        this.menuclose = false;
      }
    },
  },
};
</script>
<style>
.btn-color {
  color: #333333;
  font-size: 12px;
}

.boardbutton {
  background: #3c5aaa;
  color: white;
}

.filterIcon:hover {
  /* color: #fff; */
  scale: 1.5;
  transition: all ease-in-out .2s;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.icon-size {}</style>
