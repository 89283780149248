import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import router from './router'
import PrimeVue from 'primevue/config'
import store from './store/index'
import { quillEditor } from 'vue3-quill'
//import firebase from "firebase/app";
import { initializeApp } from 'firebase/app';
//import {getMessaging} from  "firebase/messaging";

loadFonts()

var firebaseConfig = {

  apiKey: "AIzaSyAuzCXAfeLhch_7rkUtgYYzw-TjEelzAqM",
  authDomain: "tibura-auth2.firebaseapp.com",
  projectId: "tibura-auth2",
  storageBucket: "tibura-auth2.appspot.com",
  messagingSenderId: "278444668160",
  appId: "1:278444668160:web:54872c10190d9a488aafe5"
  //measurementId: "G-Y3QY7GXLHV"
}

/*firebase.initializeApp(firebaseConfig);
if(firebase.messaging.isSupported()){
    Vue.prototype.$messaging = firebase.messaging()
}*/
initializeApp(firebaseConfig);




createApp(App).use(router)
  .use(vuetify)
  .use(store)
  .use(PrimeVue)
  .use(quillEditor)
  .mount('#app')

