<template>
               <v-card :class="type=='openIssue' ? 'menuFilter' : 'notmenuFilter'" flat width="100%">
               <v-card-item>
             <v-card-title style="display: flex;justify-content: space-between;">
                <div>
                    Filter
                </div>
                <div v-if="type=='openIssue'" @click="$emit('applyChange',filterData)">
                            <v-btn block color="primary">Apply</v-btn>
                        </div>
                <!-- <button v-if="type=='openIssue'" @click="$emit('close')"> <v-icon>mdi-close</v-icon>
</button> -->
             </v-card-title>
           </v-card-item>
   
           <v-card-text>
               <v-row dense >
                   <v-col cols="12" v-if="type=='openIssue'">
                       <v-label class="pb-1" >Work space</v-label>
                       <AutoComplete hide-details multiple :items="workspaceData" item-title="name" item-value="id" density="compact"
                           placeholder="Select work space" v-model="filterData.work_space_id" 
                           @update:model-value="$emit('filterchanged',filterData)"
                           />
                   </v-col>
                   <v-col cols="12" v-if="type=='openIssue'">
                       <v-label class="pb-1" >Account</v-label>
                       <AutoComplete hide-details multiple :items="accountData" item-title="name" item-value="id" density="compact"
                           placeholder="Select Account" v-model="filterData.account_id" 
                           @update:model-value="$emit('filterchanged',filterData)"
                           />
                   </v-col>
                   <v-col cols="12" v-if="type=='openIssue'">
                       <v-label class="pb-1" >Project</v-label>
                       <AutoComplete hide-details multiple :items="projectData" item-title="name" item-value="id" density="compact"
                           placeholder="Select Project" v-model="filterData.project_id" 
                           @update:model-value="$emit('filterchanged',filterData)"
                           />
                   </v-col>
                   <v-col cols="12" >
                       <v-label class="pb-1" >Stage</v-label>
                       <AutoComplete hide-details multiple :items="stageData" item-title="name" item-value="code" density="compact"
                           placeholder="Select Stage" v-model="filterData.stages" 
                           @update:model-value="$emit('filterchanged',filterData)"
                           />
                   </v-col>
                   <v-col cols="12" v-if="!type ||type!='openIssue'">
                       <v-label class="pb-1" >User </v-label>
                       <AutoComplete hide-details multiple  :items="users" item-value="id" item-title="name" density="compact"
                           placeholder="Select Issue-type" v-model="filterData.user_id" 
                           @update:model-value="$emit('filterchanged',filterData)"/>
                   </v-col>
                 <!--   <v-col cols="6">
                       <v-label class="pb-1" >Due On</v-label>
                       <TextFieldComponent  density="compact" placeholder="Due on" type="datetime-local" showtime
                           hoursFormat="24" v-model="filterData.end_date" />
                   </v-col>
   
                   <v-col cols="6" class="pl-4">
                       <v-label class="pb-1" >Created</v-label>
                       <TextFieldComponent density="compact" placeholder="Created" type="date" v-model="filterData.created" />
                   </v-col> -->
            
                  
   
                
                   <v-col cols="12">
                       <v-label class="pb-1" >Category</v-label>
                       <AutoComplete hide-details multiple :items="categoryData" item-value="id" item-title="name" density="compact"
                           placeholder="Select Category" v-model="filterData.category_id" 
                           @change="$emit('filterchanged',filterData)"/>
                   </v-col>
   
                   <v-col cols="12" >
                       <v-label class="pb-1" >Sub category</v-label>
                       <AutoComplete hide-details multiple  :items="subCategoryData" item-value="id" item-title="name" density="compact"
                           placeholder="Select Sub-category" v-model="filterData.sub_category_id" 
                           @change="$emit('filterchanged',filterData)"/>
                   </v-col>
                   <v-col cols="12" v-if="!type ||type!='openIssue'">
                       <v-label class="pb-1" >Sub Task</v-label>
                       <AutoComplete hide-details multiple  :items="taskData" item-value="id" item-title="name" density="compact"
                           placeholder="Select Sub Task" v-model="filterData.task_id" 
                           @change="$emit('filterchanged',filterData)"/>
                   </v-col>
   
                   <v-col cols="12" v-if="!type ||type!='openIssue'">
                       <v-label class="pb-1" >Task type</v-label>
                       <AutoComplete hide-details multiple  :items="issyeTypeData" item-value="id" item-title="name" density="compact"
                           placeholder="Select Task-type" v-model="filterData.issue_type_id" 
                           @change="$emit('filterchanged',filterData)"/>
                   </v-col>
                  
               </v-row>
   

   
               
           </v-card-text>
       </v-card>
   </template>
   
   <script>
   //import TextFieldComponent from "@/components/TextFieldComponent.vue";
   import AutoComplete from "@/components/ui/AutoComplete.vue";
   import ProjectService from "@/api/service/Project/ProjectService";
   import WorkSpaceService from "@/api/service/WorkSpace/WorkSpaceService";
   import AccountService from '@/api/service/Account/AccountService';
   import CategoryService from "@/api/service/Category/CategoryService";
   import SubCategoryService from "@/api/service/SubCategory/SubCategoryService";
   import TaskService from "@/api/service/Task/TaskService";
   import IssueTypeService from "@/api/service/IssueType/IssueTypeService";
//    import EnumService from '@/api/service/Enum/EnumService';
   import StageService from "@/api/service/Stage/StageService";
   import UserService from "@/api/service/UserService";
   // import filterService from "@/api/service/Filter/filterService";
   import commonService from "@/common.service";
   export default {
       props:["showDrawer",'existFilterData',"showApply",'type',"process"],
       components: {
          // TextFieldComponent,
           AutoComplete
       },
       data() {
           return {
               drawer:true,
               projectData: [],
               accountData: [],
               categoryData: [],
               subCategoryData: [],
               issyeTypeData: [],
               taskData: [],
               users: [],
               stageData: [],
               workspaceData:[],
               filterData: {
                   project_id: null,
                   account_id: null,
                   category_id: null,
                   sub_category_id: null,
                   task_id: null,
                   issue_type_id: null,
                   created: null,
                   end_date: null,
                   stages: null,
                   next_action: null,
                   workTimingId: null,
   
               }
           }
       },
       watch:{
            existFilterData(){
                if(this.existFilterData){
                  this.filterData =this.existFilterData
                }
            },
            filterData() {
                this.$emit('filterchanged',this.filterData)
            },
           showDrawer() {
               this.drawer=this.showDrawer
           }
       },
       created(){
           this.getStageData()
           this.getWorkspaceData()
           this.getProjects()
           this.getAccountData()
           this.getCategoryData()
           this.getSubCategoryData()
           this.getTaskData()
           this.getIssueTypeData()
           this.getuser()
            if(this.existFilterData){
                this.filterData =this.existFilterData
            }
       },
       methods: {
           async getWorkspaceData() {
               await WorkSpaceService.findAll().then(async ($options) => {
                   this.workspaceData = $options.length > 0 ? await commonService.getSort($options, 'name', 'asc') : [];
               });
           },
           async getProjects() {
               await ProjectService.findAll().then(async ($options) => {
                   this.projectData = $options.length > 0 ? await commonService.getSort($options, 'name', 'asc') : [];
               });
           },
           async getAccountData() {
               await AccountService.findAll().then(async ($options) => {
                   this.accountData = $options.length > 0 ? await commonService.getSort($options, 'name', 'asc') : [];
               });
           },
           async getCategoryData() {
         let res = await CategoryService.findAll();
         this.categoryData = res.length > 0 ? res : [];
       },
       async getSubCategoryData() {
         let res = await SubCategoryService.findAll();
         this.subCategoryData = res.length > 0 ? res : [];
       },
       async getTaskData() {
         let res = await TaskService.findAll({ task_type: "production" });
         this.taskData =
           res.length > 0 ? await commonService.getSort(res, "name", "asc") : [];
       },
       async getIssueTypeData() {
         let res = await IssueTypeService.findAll();
         this.issyeTypeData = res.length > 0 ? res : [];
       },
           async getuser() {
               try {
                   let workSpaceId = localStorage.getItem("workSpaceId")
                   let res = await UserService.findAll()
                   console.log(workSpaceId)
                   //await UserService.getNextAction({ work_space_id: workSpaceId })
                   this.users = res.length ? res : [];
               }
               catch (e) {
                   this.users = []
               }
           },
           async getStageData() {
               let res = await StageService.findAll()
               this.stageData = res
            //    this.stageData.unshift({
            //        data_value: null,
            //        display_value: "All Stages"
            //    });
           },
           close() {
           this.$emit("closeDialog");
         },
   
           async save() {
               this.filterData.end_date = commonService.changeTheDateToUtc(this.filterData.end_date, Intl.DateTimeFormat().resolvedOptions().timeZone)
               // await filterService.update(this.filterData);
               this.$store.dispatch('setSnackbar', { text: 'Filter Added Successfully' })
               this.dialog = false;
               this.close();
               this.$emit("reload",this.filterData);
               this.filterData = {};
           }
       }
   }
   </script>
   
   <style scoped>
   .nav-drawer {
       position:absolute;
       top:70px !important;
       box-shadow: -10px 0px 10px 1px #aaaaaa;
   }
   .save {
       background-color: #1258AB;
       color:#fff;
   }
   .menuFilter{
    height: 71vh;
    overflow:auto
   }
   .notmenuFilter{
    height:"100%"
   }
   </style>