<template>
    
  <SkeletonReport v-if="loading" />
  <div v-else-if="show && !loading">
    <ReportPreview
      :data="srcData"
      @columnEditor="columnEditor"
      :config="headerData"
      :filterData="filterData"
      :backBtn="backBtn"
      :savedFilters="savedFilters"
      :selectedFilter="selectFilter"
      @filter-id="selectedFilterId"
      @goBack="onGoBack"
      @formChanged="formChanged"
    />

    <EditColumns ref="EditColumns" :data="columnData" @editColumns="sendColumns" @allSelected="allSelected" />
  </div>
</template>
<script>
import EditColumns from "./EditColumns.vue";
import SkeletonReport from "./SkeletonReport.vue";
import { ReportPreview } from "frontendcomponent";
import ListModelService from "@/api/service/ListModel/ListModelService";
import ListModelDetailsService from "@/api/service/ListModelDetail/ListModelDetailService";
import commonService from "@/common.service";
import ListModelFilterService from "@/api/service/ListModelFilter/ListModelFilterService"
export default {
  components: {
    ReportPreview,
    SkeletonReport,
    EditColumns,
  },
  data() {
    return {
      backBtn: {
        label: "Back",
        show: true,
      },
      userData:null,
      loading: true,
      filterData: null,
      headerData: [],
      selectFilter:null,
      show: false,
      menu:false,
      srcData: [],
      modelData: {},
      columnData: [],
      showColumn: this.columnData,
      finalColumn: this.columnData,
      modelDetailsData: [],
      savedFilters:null,
      titleStyle: {
        bold: 600,
        underline: true,
        font: "verdana",
        fontSize: "24px",
        color: "0000",
        align: "left",
        background: "",
        format: "",
        case: "upper",
        border: {
          left: true,
          bottom: true,
          right: true,
          top: true,
        },
      },
      subTitleStyle: {
        bold: "600",
        underline: true,
        font: "verdana",
        fontSize: "18px",
        color: "#272727",
        align: "left",
        background: "",
        format: "",
        case: "upper",
        border: {
          left: true,
          bottom: true,
          right: true,
          top: true,
        },
      },
      headerStyle: {
        bold: true,
        underline: true,
        font: "verdana",
        fontSize: "14px",
        color: "",
        align: "left",
        background: "",
        format: "",
        case: "upper",
        border: {
          left: true,
          bottom: true,
          right: true,
          top: true,
        },
      },
      detailsStyle: {
        rowHeight: 101,
        bold: true,
        underline: true,
        font: "verdana",
        fontSize: "14px",
        color: "",
        format: "",
        case: "upper",
        align: "left",
        background: "",
        border: {
          left: true,
          bottom: true,
          right: true,
          top: true,
        },
      },
    };
  },
  mounted() {
    this.finalColumn = this.columnData;
  },

 async created() {
        let user =localStorage.getItem('user_details')
        this.userData =user?JSON.parse(user):{}
        await this.getSavedFilters()
        await this.getModelData();
        this.finalColumn = [...this.columnData];
        this.showColumn = [...this.columnData];
        this.getModelData();
        console.log(this.finalColumn , 'kyunhi')

  },
  methods: {
    async selectedFilterId($event){
      console.log($event)
      if($event){
      try{
        
      let res =await commonService.getSelectedFilter($event)
        console.log(res,"ooooooooooooo")
        this.selectFilter =res
      }
      catch(e){
        console.log(e)
        this.selectFilter =null
      }
    }
    },
    async getSavedFilters(){
      try{
        let res = await ListModelFilterService.findAll({created_by:this.userData.id})
        this.savedFilters =res && res.length?res:[]
        
      }
      catch(e){
        this.savedFilters =[]
        console.log(e)
      }
    }, 
    async sendColumns(data) {
      const indexToRemove = this.showColumn.findIndex(
        (item) => item.value === data.value
      );
      if (indexToRemove > -1) {
        this.showColumn.splice(indexToRemove, 1);
      } else {
        this.showColumn.push(data);
      }

      console.log(this.showColumn, "kkkaaaayyy");
      this.finalColumn = this.showColumn;
      console.log(this.finalColumn, "kkkkkkkkkkkkkkkkkkkkkkkkh");

      await this.getModelData();
    },

    async allSelected(data){
        if(this.finalColumn != data){
            this.finalColumn = data
        }else{
            this.finalColumn = []
        }
        await this.getModelData();
    },

    columnEditor() {
      this.$refs.EditColumns.drawer = !this.$refs.EditColumns.drawer;
    },
    async getFilterData() {
      try {
        let res = await ListModelDetailsService.findAll({
          model_id: this.$route.query.model_id,
          filter_required: 1,
        });
        let columnData = await commonService.setFilterData(res);
        console.log(columnData, "uuuuuu666");

        this.filterData = {
          title: "Report Form",
          width: "700",
          closeIcon: false,
          height2: "600",
          withContainer: false,
          layout: "fixed",
          columns: 12,
          sections: [
            {
              columns: columnData,
            },
          ],
        };
        console.log(this.filterData, "uuuuuuuuu444");
        this.filterData = { ...this.filterData };
        this.show = true;
        this.loading = false;
        //    this.filterData = res && res.length ? res :[]
      } catch (e) {
        this.filterData = {};
        console.log(e);
      }
    },
    async getModelData() {
      try {
        let res = await ListModelService.findAll({
          id: this.$route.query.model_id,
        });
        this.modelData = res && res.length ? res[0] : {};
        if (
          this.modelData &&
          this.modelData.model_type &&
          this.modelData.model_type == "query"
        ) {
          // alert('common service 1')
          let data = await commonService.getReport({
            modelId: this.modelData.id,
          });
          // alert('common service 2')
          this.srcData = data && data.length ? data : [];
        }
      } catch (e) {
        this.modelData = [];
        console.log(e);
      }
      try {
        let res = await ListModelDetailsService.findAll({
          model_id: this.$route.query.model_id,
          column_required: 1,
        });
        this.modelDetailsData = res && res.length ? res : [];
      } catch (e) {
        this.modelDetailsData = [];
        console.log(e);
      }
      this.commonData = await this.getHeaderData(this.modelData);
      this.columnData = await this.getColumnStyleData(this.modelDetailsData);

      let $headerData = {
        detail: {
          style: this.detailsStyle,
          columns: this.finalColumn,
        },
        header: this.commonData,
      };
      this.headerData = $headerData;
      console.log(this.headerData, "ppppppppppppppppppp");
      this.getFilterData();
    },
    async formChanged($event,flag,name) {
      try {
        console.log($event, "filtert Dataaa",flag,name);
        let obj = {
          tableName: this.modelData.model,
          modelId: this.$route.query.model_id,
          filter: $event,
        };
        let data = await commonService.getReport(obj);
        this.srcData = data && data.length ? data : [];
        console.log(this.srcData, "srcDatasrcData");
        console.log(this.srcData, "src Dataaa");
        this.getSavedFilters()
      } catch (e) {
        this.srcData = [];
        console.log(e);
      }
    },
    async getColumnStyleData(value) {
      let $data = [];
      value = await commonService.getSort(value, "table_sequence", "asc");
      for (let $i = 0; $i < value.length; $i++) {
        let $obj = {
          label: value[$i].label,
          value: value[$i].column_name,
          fixed: $i == 0 ? true : false,
          width: "75px",
          sequence: "",
          visible: true,
          expression: "",
          link: "",
          dataType: value[$i].data_type,
        };
        $data.push($obj);
      }
      return $data;
    },
    onGoBack() {
      this.$router.push("/reportList");
    },
    getHeaderData(value) {
      let header = {
        title: [
          {
            text: value.name,
            size: "100%",
            style: this.titleStyle,
          },
        ],
        subTitle: [
          {
            text: "",
            size: "30",
            style: this.subTitleStyle,
          },
        ],
        filters: [
          {
            column: "accountCode",
            values: ["BNO", "TRF", "TER"],
          },
        ],
        downloadable: true,
        sortable: true,
        style: this.headerStyle,
      };
      return header;
    },
  },
};
</script>
<style>
.p-menu .p-menuitem-link {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-decoration: none;
  overflow: hidden;
  position: relative;
  overflow: inherit;
  color: #495057;
  padding: 0.75rem 1.25rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.p-menu .p-menuitem-link:hover {
  background-color: #dee2e6;
}

>>> .v-card--flat {
  box-shadow: none;
  padding-bottom: 65px;
}
</style>