<template>
    <v-layout>
        <v-navigation-drawer v-model="drawer" location="right" temporary :width="550"
            style="background: white; position: fixed; top: 71px; width: fit-content" class="side-menu">
            <v-card flat="">
                <v-card-title style="border-bottom: 1px solid gray">Edit Columns</v-card-title>
                <v-card-text class="mt-3">
                    <div class="contains" :class="customClass(data)">
                        <v-checkbox class="py-0 all" :class="!expand?'collapseAll':'expandAll'" :model-value="true" label="Select All" color="primary"
                            @click="allSelected(data)"></v-checkbox>
                        <!-- <v-checkbox class="py-0 all" :model-value="true" label="Select All" color="primary" :value="data"
                            @click="allSelected(data)"></v-checkbox> -->

                        <div v-for="(item, index) in data" :key="index"   >
                            <v-checkbox :model-value="allOptionsSelected" class="py-0" :label="item.label" color="primary"
                                 @click="checkSelection(item)"></v-checkbox>
                        </div>
                    </div>
                </v-card-text>
            </v-card>
        </v-navigation-drawer>
    </v-layout>
</template>

<script>
export default {
    props: ["data"],
    data() {
        return {
            drawer: false,
            expand: false,
            allOptionsSelected: true,
            selected: true,
        };
    },
    methods: {
        customClass(data){
            if(data && data.length > 10){
                this.expand = true
                return 'morethan'
            }else{
                this.expand = false
                return 'lessThan'
            }
        },
        checkSelection($item) {
            this.$emit("editColumns", $item);
        },
        allSelected($item) {
            this.allOptionsSelected = !this.allOptionsSelected
            this.$emit("allSelected", $item);
        },
    },
};
</script>

<style scoped>
>>>.v-checkbox .v-selection-control {
    /* min-height: var(--v-input-control-height); */
    height: 25px;
}

.contains {
    display: grid;
    /* gap: 10px; */
}
.lessThan{
    grid-template-columns: repeat(2, 1fr);
}
.morTthan{
    grid-template-columns: repeat(3, 1fr);
}

.all {
    grid-row: 1;
}
.collapseAll{
    grid-column: 1 / 3;
}
.expandAll{
    grid-column: 1 / 4;
}

>>>.v-input__details {
    align-items: flex-end;
    display: flex;
    font-size: 0.75rem;
    font-weight: 400;
    grid-area: messages;
    letter-spacing: 0.0333333333em;
    line-height: normal;
    min-height: 22px;
    padding-top: 6px;
    overflow: hidden;
    justify-content: space-between;
    display: contents;
}
>>>::-webkit-scrollbar {
  width: 10px;
  height: 6px;
}

>>>::-webkit-scrollbar-track {
  background: #e8f3f9;
}

>>>::-webkit-scrollbar-thumb {
  background: rgb(0, 0, 0, 0.12);
  border-radius: 8px;
}


/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #e8f3f9;
}
</style>