<template>
    <div style="display: grid;overflow: auto;">
        <div class="widgetsTitleBox pt-3"> 
            <span>
            <v-icon class="pb-1 mr-2" @click="$emit('open-setting',settingIndex)">mdi-filter-outline</v-icon>
            <span class="widgetsTitle">{{ widgetOptions.title }}</span>
        </span> 
        <div class="pt-1"  style="display: flex;gap: 5px;">

            <v-icon class="pb-2 mr-2" @click="$emit('deleteWidget',settingIndex)" >mdi-trash-can-outline</v-icon>
            <v-icon class="pb-1 mr-2" @click="$emit('fixedBoard')" >mdi-pin-outline</v-icon>
                <v-img @click="$emit('changeRow')" style="width: 20px;height: 16px;"
                    :src="require('../../../assets/icons/changeRow.png')" /></div>
        </div>

        <div style="display: flex;">
            <v-col v-if="widgetOptions.groupWithin" class="parentCard">
                <div class="childCard" v-for="key0 in data && data.group && data.group.key0" :key="key0">
                    <div class="sub-header">
                        <div class="avatarTitle">
                            <CustomAvatar v-if="false && key0.next_action != 'null'" :userId="key0.next_action"
                                :size="'medium'" />
                            <div class="heading text-capitalize">
                                <v-row no-gutters dense>
                                    <v-col cols="12">
                                        <span class=""
                                            style="text-transform:uppercase;line-height:18px;font-weight:800;font-size:12px;color: #3d3d3d !important;">
                                            {{ key0.value }}
                                            ({{ key0.total }})
                                        </span>
                                    </v-col>
                                    <v-col cols="12" class="text-caption">
                                        &nbsp;
                                        <!-- <span class="pa-1 font-weight-bold" style="color:#066269">User</span>
                                            <span class="pa-1 font-weight-bold" style="color:#066269">| In meeting</span>
                                            <span class="pa-1 font-weight-bold" style="color:#066269">| {{ key0.count?key0.count:0 }} issue(s)</span> -->
                                    </v-col>
                                </v-row>
                            </div>
                        </div>
                    </div>
                    <div class="diffStages" v-for="key1 in key0.subGroup" :key="key1">
                        <div class="pa-1"
                            style="line-height:24px;width:100%;font-weight:600;color:#555555;background-color:#f1f1f1">
                            {{ key1 && key1.value? key1.value.charAt(0).toUpperCase() + key1.value.substr(1):'' }}
                            ({{ key1 && key1.total? key1.total:'' }})
                            <span class="float-right">
                                <v-icon :class="key1.collapse ? 'mdi mdi-chevron-up' : 'mdi mdi-chevron-down'" size="small"
                                    @click="key1.collapse = !key1.collapse" />
                            </span>
                        </div>
                        <div v-for="(issue, issueIndex) in getIssueData(key0, key1)" :key="issueIndex"
                            @click="atClick(issue)">
                            <v-row no-gutters class="my-1 detail" v-show="!key1.collapse">
                                <div style="width:82%">
                                    <v-avatar class="float-left mr-2" color="#08929B" rounded="0" size="24px">
                                        <span style="color:#fff;font-size: 9px;">{{ issue &&
                                            issue.account_code ? issue.account_code.toUpperCase().substr(0, 3) : ''
                                        }}</span>
                                    </v-avatar>
                                    <span class=" ml-2 issueName ">{{ issue.name }}</span>
                                </div>
                                <div style="width:18%" class="text-right" :class="isDateOverDue(issue.end_date)">
                                    <span align="end" class="text-caption" style="font-weight:600">{{
                                        issue.end_date ?
                                        moment(issue.end_date).format('MMM DD') : '' }}</span>
                                    <span align="end" class="text-caption" style="font-weight:600">
                                        {{ issue.end_date
                                            ? moment(issue.end_date).format(' HH:mm ') : '' }}</span>
                                </div>
                                <v-col v-if=false cols="6">
                                    <div style="display: flex;gap: 8px;margin-top: 5px;">
                                        <div v-if="issue.categories.code" class="chip">{{ issue.categories.code }}</div>
                                        <div v-if="issue.estimate" class="chip">{{ issue.estimate }} Hrs
                                        </div>
                                    </div>
                                </v-col>
                                <v-col style="width:100%">
                                    <hr style="border:1px solid #efefef !important" />
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                </div>
            </v-col>

            <v-col v-else class="parentCard" v-for="key0 in data && data.group && data.group.key0" :key="key0">
                <div class="sub-header">
                    <div class="avatarTitle">
                        <CustomAvatar v-if="false && key0.next_action != 'null'" :userId="key0.next_action"
                            :size="'medium'" />
                        <div class="heading text-capitalize">
                            <v-row no-gutters dense>
                                <v-col cols="12">
                                    <span class=""
                                        style="text-transform:uppercase;line-height:18px;font-weight:800;font-size:12px;color: #3d3d3d !important;">
                                        {{ key0.value }}
                                        ({{ key0.total }})
                                    </span>
                                </v-col>
                                <v-col cols="12" class="text-caption">
                                    &nbsp;
                                    <!-- <span class="pa-1 font-weight-bold" style="color:#066269">User</span>
                                            <span class="pa-1 font-weight-bold" style="color:#066269">| In meeting</span>
                                            <span class="pa-1 font-weight-bold" style="color:#066269">| {{ key0.count?key0.count:0 }} issue(s)</span> -->
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                </div>
                <div class="diffStages" v-for="key1 in key0.subGroup" :key="key1">
                    <div class="pa-1"
                        style="line-height:24px;width:100%;font-weight:600;color:#555555;background-color:#f1f1f1">
                        {{ key1 && key1.value ?key1.value.charAt(0).toUpperCase() + key1.value.substr(1):'' }}
                        ({{ key1 && key1.total? key1.total:'' }})
                        <span class="float-right">
                            <v-icon :class="key1.collapse ? 'mdi mdi-chevron-up' : 'mdi mdi-chevron-down'" size="small"
                                @click="key1.collapse = !key1.collapse" />
                        </span>
                    </div>
                    <div v-for="(issue, issueIndex) in getIssueData(key0, key1)" :key="issueIndex" @click="atClick(issue)">
                        <v-row no-gutters class="my-1 detail" v-show="!key1.collapse">
                            <div style="width:82%">
                                <v-avatar class="float-left mr-2" color="#08929B" rounded="0" size="24px">
                                    <span style="color:#fff;font-size: 9px;">{{ issue &&
                                        issue.account_code ? issue.account_code.toUpperCase().substr(0, 3) : ''
                                    }}</span>
                                </v-avatar>
                                <span class=" ml-2 issueName ">{{ issue.name }}</span>
                            </div>
                            <div style="width:18%" class="text-right" :class="isDateOverDue(issue.end_date)">
                                <span align="end" class="text-caption " style="font-weight:600">{{
                                    issue.end_date ?
                                    moment(issue.end_date).format('MMM DD') : '' }}</span>
                                <span align="end" class="text-caption" style="font-weight:600">
                                    {{ issue.end_date
                                        ? moment(issue.end_date).format(' HH:mm ') : '' }}</span>
                            </div>
                            <v-col v-if=false cols="6">
                                <div style="display: flex;gap: 8px;margin-top: 5px;">
                                    <div v-if="issue.categories.code" class="chip">{{ issue.categories.code }}</div>
                                    <div v-if="issue.estimate" class="chip">{{ issue.estimate }} Hrs
                                    </div>
                                </div>
                            </v-col>
                            <v-col style="width:100%">
                                <hr style="border:1px solid #efefef !important" />
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </v-col>
        </div>
        <v-dialog v-model="showFilter" width="450px">
            <DashboardFilter @filterchanged="filterChanged" />
        </v-dialog>
    </div>
</template>
<script>
import DashboardFilter from './DashboardFilter.vue'
//import CustomBoardService from '@/api/service/CustomDashBoard/CustomDashBoardService'
import DashboardWidgetService from '@/api/service/DashboardWidget/DashboardWidgetService';
//import EmptyContainer from './EmptyContainer.vue'
import moment from 'moment'
export default {
    components: {
        DashboardFilter
        // EmptyContainer
    },
    props: ["config", 'hideNullGroup','fixedIndex','settingIndex'],
    data() {
        return {
            data: [],
            widgetOptions: {},
            showFilter: false,
            originalDisplayData: []
        }
    },
    async created() {
        if (this.config) {
            this.widgetOptions = this.config
            this.getWidgetData()
        }
    },
    watch: {
        config: {
            deep: true,
            handler: function (newValue, oldValue) {
                
            console.log(this.config,"pppppppppppppppppppppppppppppppppppppppppppp")
                console.log(newValue, oldValue)
                this.widgetOptions = newValue
                this.getWidgetData()
            }
        },
        hideNullGroup() {
            if (this.hideNullGroup) {
                this.hideZeroIssues()
            } else {
                this.getWidgetData()
            }
        }
    },
    methods: {
        isDateOverDue(date) {
            let currentDate = new Date();
            let dueDate = new Date(date);
            return currentDate > dueDate ? 'text-red' : 'text-orange';
        },
        atClick(item) {
            this.$emit('draweView', item)
        },
        moment,
        getWidth() {
            if (this.data && this.data.group && this.data.group.key0.length * 300) {
                return this.data && this.data.group && this.data.group.key0.length * 330 + 'px'
            } else {
                return '100%'
            }
        },
        async getWidgetData() {
            let $config = { "type": "issue", "grouping": ["user", "stage"], "filters": {} }
            // let $defaultFilters = this.getDefaultFilters()
            let $defaultFilters = {}
            //let $config = this.widgetOptions
            $config.type = this.widgetOptions.type
            $config.grouping = this.widgetOptions.grouping
            $config.filters = this.widgetOptions.filters
            if(this.widgetOptions && this.widgetOptions.defaultFilters){
                    if (this.widgetOptions.defaultFilters.work_space_id && this.widgetOptions.defaultFilters.work_space_id >-1) {
                        $defaultFilters.work_space_id = this.widgetOptions.defaultFilters.work_space_id
                    }
                    if (this.widgetOptions.defaultFilters.project_id && this.widgetOptions.defaultFilters.project_id>-1) {
                        $defaultFilters.project_id = this.widgetOptions.defaultFilters.project_id
                    }
                    if (this.widgetOptions.defaultFilters.account_id && this.widgetOptions.defaultFilters.account_id >-1) {
                        $defaultFilters.account_id = this.widgetOptions.defaultFilters.account_id
                    }
            }
            $config.defaultFilters = $defaultFilters //this.getDefautFilters();
            this.data = await DashboardWidgetService.getWidgetData($config)
            this.originalDisplayData = this.data && this.data.group? JSON.parse(JSON.stringify(this.data.group)):null
            console.log(this.originalDisplayData, 'kkkkkkkkkkkssssssssssssss')
        },
        getDefaultFilters() {
            let $wsDefaults = localStorage.getItem('_WS_DEFAULTS_')
            if ($wsDefaults) {
                $wsDefaults = JSON.parse($wsDefaults)
                let $defaultFilters = {}
                // /{"workspace":{"id":1,"name":"Production Red"},"project":{"id":2,"name":"Production"},"account":{"id":0,"name":"All Accounts"}}

                if ($wsDefaults.work_space_id) {
                    $defaultFilters.work_space_id = $wsDefaults.workspace.id
                }
                if ($wsDefaults.project) {
                    $defaultFilters.project_id = $wsDefaults.project.id
                }
                if ($wsDefaults.account && $wsDefaults.account.id != 0) {
                    $defaultFilters.account_id = $wsDefaults.account.id
                }
                return $defaultFilters
            } else {
                return {}
            }
        },
        getIssueData(key0, key1) {
            let $data = []
            for (let i = 0; i < this.data.data.length; i++) {
                // console.log(key0['keyType'], "000000")
                if (this.data.data[i][key0['keyType']] == key0.keyId && this.data.data[i][key1['keyType']] == key1.keyId) {
                    $data.push(this.data.data[i])
                }
            }
            return $data
        },

        filterChanged(data) {
            this.widgetOptions.filters = data
            this.getWidgetData()
        },

        hideZeroIssues() {
            let $original = JSON.parse(JSON.stringify(this.originalDisplayData))
            if ($original && $original.key0.length > 1) {
                $original = $original.key0;
                for (let i = 0; i < $original.length; i++) {
                    $original[i].subGroup = $original[i].subGroup && $original[i].subGroup.length > 0 ? $original[i].subGroup.filter(k => k.total > 0) : [];
                }
            }
            this.data.group.key0 = $original;
        }

    }
}
</script>

<style scoped>
.mainContainer {
    display: -webkit-inline-box;
    gap: 5px;
    padding: 0 5px;
    border-radius: 4px;
    background: #efefef;
    height: 100%;
    background: orange;
    overflow-x: auto;
}

parentcard3 {
    position: relative;
    top: 5px;
    background: #fff;
    border-radius: 8px;
    width: 300px;
    min-height: 150px;
    max-height: 85vh;
    overflow: hidden;
    border: 1px solid #adadad;
    padding: 0px 20px 20px 20px;
    margin: 5px;
}

.parentCard {
    position: relative;
    top: 5px;
    background: #fff;
    border-radius: 8px;
    min-width: 300px;
    max-width: 300px;
    min-height: 650px;
    max-height: 85vh;
    overflow: hidden;
    border: 1px solid #adadad;
    padding: 0px 20px 20px 20px;
    margin: 5px;
}

.parentCard:hover {
    overflow-y: auto;
}

/* .childCard {
    border-bottom: 1px solid #ccc;
    margin-bottom: 10px; 
  } */
.widgetsTitleBox {
    display: flex;
    /* gap: 5px; */
    width: 300px;
    justify-content: space-between;
    padding-left: 12px;
}

.widgetsTitle {
    font-size: 1.25rem;
    font-weight: 500;
    letter-spacing: 0.0125em;
    white-space: nowrap;
}

.sub-header {
    display: flex;
    justify-content: space-between;
    position: sticky;
    top: 0px;
    z-index: 2;
    background-color: #fff;
    padding: 0;
    height: 60px;
    align-items: end;
}


.avatarTitle {
    display: flex;
    gap: 5px;
}

.detail {
    min-height: 50px;
    padding-top: 5px;
    margin: auto auto;
}

.heading {
    line-height: .8;
}

.chip {
    padding: 3px 5px;
    border-radius: 4px;
    height: fit-content;
    width: fit-content;
    display: flex;
    align-items: center;
    font-size: 10px;
    color: #929292;
    font-weight: 600;
    background-color: #ededed;
}

.issueName {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    color: #464646;
    font-size: 13px;
    font-weight: 400;
    -webkit-box-orient: vertical;
}

.inprogress {
    background-color: #E8FFFC;
}

>>>.v-expansion-panel-title {
    align-items: center;
    text-align: start;
    border-radius: inherit;
    display: flex;
    font-size: 0.9375rem;
    line-height: 1;
    min-height: 48px;
    outline: none;
    padding: 2px;
    position: relative;
    transition: 0.3s min-height cubic-bezier(0.4, 0, 0.2, 1);
    width: 100%;
    justify-content: space-between;
    border-bottom: 1px solid;
    border-color: rgb(219, 219, 219) !important;

}

>>>.v-expansion-panel-title--active>.v-expansion-panel-title__overlay,
.v-expansion-panel-title[aria-haspopup=menu][aria-expanded=true]>.v-expansion-panel-title__overlay {
    opacity: 0
}

>>>.v-expansion-panel-title {
    align-items: center;
    text-align: start;
    border-radius: inherit;
    display: flex;
    font-size: 0.9375rem;
    line-height: 1;
    min-height: 35px;
    outline: none;
    padding: 2px;
    position: relative;
    transition: 0.3s min-height cubic-bezier(0.4, 0, 0.2, 1);
    width: 100%;
    justify-content: space-between;
    border-bottom: 1px solid;
}

>>>.v-expansion-panel-title:hover>.v-expansion-panel-title__overlay {
    opacity: 0;
}

>>>.diffStages .v-avatar.v-avatar--density-default {
    height: 28px;
    width: 32px;
    border-radius: 5px !important;
}

>>>.v-expansion-panel-text__wrapper {
    padding: 0px 2px;
    flex: 1 1 auto;
    max-width: 100%;
}

>>>::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

>>>::-webkit-scrollbar:hover {
    width: 10px;
    height: 10px;
}

>>>::-webkit-scrollbar-track {
    background: #e8f3f9;
}

>>>::-webkit-scrollbar-thumb {
    background: rgb(0, 0, 0, 0.12);
    border-radius: 8px;
}
</style>
  