<template>
    <div style="padding:5px" v-if="accessPrivilege.indexOf('list')<0 && !loading">
        NO Access
    </div>
    <div style="padding:5px" v-if="accessPrivilege.indexOf('list')>=0">
        <PrimeDataTable v-model:filters="filters" :value="dataSource" paginator :loading="dataLoading" scrollDirection="both" rowHover :rows="10"
            :dataKey="id" :class="`p-datatable-${size.class}`" :globalFilterFields="fileterKey" scrollable scrollHeight="400px" 
            :rowsPerPageOptions="rowsPerPageOptions" @row-click="rowActions($event.data, 'select')"
            style="cursor: pointer;height:90vh;" :multiSortMeta="sortFields">
            <template #header>
                <v-row no-gutters dense class="pa-0 ma-0" style="background-color:white">
                    <v-col cols="7" class="pt-2">
                        <div class="text-h6 font-weight-black">{{ title }}</div>
                    </v-col>
                    <v-col cols="5" class="pt-1">
                        <v-row no-gutters dense>
                            <v-col cols="5">
                                <div class="flex justify-content-end" v-if="searchOption">
                                    <span class="p-input-icon-left" style="width: 95%;">
                                        <v-text-field class="search-input p-inputtext-sm" style="border-radius:14px"
                                            label="" density="compact" variant="outlined" v-model="filters['global'].value"
                                            placeholder="Search">
                                            <template v-slot:prepend-inner>
                                                <i class="pa-1 pi pi-search" />
                                            </template>
                                        </v-text-field>
                                    </span>
                                </div>
                            </v-col>
                            <v-col cols="7" class="text-right pa-2">
                                <span class="prime-icon-outlet" v-if="showAdd && accessPrivilege.indexOf('create')>=0" @click="addDiolog()">
                                    <i class="pi pi-plus" />
                                    <v-tooltip activator="parent" location="bottom">Add</v-tooltip>
                                </span>
                                <!-- <span class="prime-icon-outlet" v-if="showFilter" @click="showFilter()">
                                    <i class="pi pi-filter" />
                                    <v-tooltip activator="parent" location="bottom">Filter</v-tooltip>
                                </span> -->

                                <v-menu v-if="accessPrivilege.indexOf('download')>=0">

                                    <template v-slot:activator="{ props }">
                                        <v-button v-bind="props">
                                            <span class="prime-icon-outlet" v-if="showDownload">
                                                <i class="pi pi-download" />
                                                <v-tooltip activator="parent" location="bottom">Download</v-tooltip>

                                            </span>
                                        </v-button>
                                    </template>

                                    <v-list>
                                        <v-list-item class="menu-cursor" v-for="item in downloadMenu" :key="item"
                                            :value="index">
                                            <v-list-item-title @click="download(item)">{{ item }}</v-list-item-title>
                                        </v-list-item>
                                    </v-list></v-menu>

                                <!-- <span class="prime-icon-outlet" v-if="showUpload" @click="upload()">
                                    <i class="pi pi-upload" />
                                </span> -->
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </template>
            <template #empty>
                <v-container v-if="!dataLoading" class="mt-5" justify-center
                    style="text-align:center;position:relative;height:100%;margin:auto auto">
                    <img :src="require('@/assets/no-record.png')" width="160" height="160" />
                    <p class="text-center">
                        No Results found
                        <br>
                        <span class="text-body-1">
                            Please check if you have permissions or try adding new records!!
                        </span>
                    </p>
                    <br />
                    <v-btn color="#2D56B2" type="submit" size="large" @click="addDiolog()" :disabled="loading"
                        :loading="loading" class="text-none mb-4 text-white">Add</v-btn>
                </v-container>
            </template>
            <template #loading>
                <v-container class="mt-5" justify-center
                    style="text-align:center;position:relative;height:100%;margin:auto auto">
                    <DataTableLoader displayText="Loading data" />
                </v-container>
            </template>


            <template v-for="(col, index) of layoutColumns" :key="col.field">

                <PrimeColumn :headerClass="col.headerClass" :style="index==0?col.style:''">
                    <template #header >
                        <div> 
                            {{ col.header }} <v-tooltip activator="parent" location="bottom">{{ col.header }}</v-tooltip>
                        </div>
                        <v-checkbox class="checkBox" color="primary" v-if="index == 0" v-model="multipleCheck"
                            @change="multiCheck()"></v-checkbox>
                        <span v-if="index != 0 && col.header.toLowerCase() != 'action' && col.header.toLowerCase() != ''">
                            <i class="pi pi-sort-alt" v-if="col.sort == 'defalt' || !col.sort"
                                style="font-size: 0.7rem;margin-left: 9px;" @click="sorthTheData(col, index)"></i>
                            <i class="pi pi-sort-alpha-down" v-if="col.sort == 'asc'"
                                style="font-size: 0.7rem;margin-left: 9px;" @click="sorthTheData(col, index)"></i>
                            <i class="pi pi-sort-alpha-down-alt" v-if="col.sort == 'desc'"
                                style="font-size: 0.7rem;margin-left: 9px;" @click="sorthTheData(col, index)"></i>
                        </span>
                    </template>
                    <!-- <template #header v-if="index != 0">
                        <i class="pi pi-sort-alt" v-if="col.sort == 'defalt' || !col.sort" style="font-size: 0.8rem"
                            @click="sorthTheData(col, index)"></i>
                        <i class="pi pi-sort-alpha-down" v-if="col.sort == 'asc'" style="font-size: 0.8rem"
                            @click="sorthTheData(col, index)"></i>
                        <i class="pi pi-sort-alpha-down-alt" v-if="col.sort == 'desc'" style="font-size: 0.8rem"
                            @click="sorthTheData(col, index)"></i>
                    </template> -->
                    <template #body="{ data }">
                        <div v-if="index == 0">
                            <v-checkbox class="checkBox" v-model="data.checkbox" color="primary"
                            style="z-index:999"
                                @change="singleCheck()"></v-checkbox>
                        </div>
                        <div
                            v-if="col.header.toLowerCase() != '' && col.header.toLowerCase() != 'active' && col.field.toLowerCase() != 'active' && col.header.toLowerCase() != 'created' && col.header.toLowerCase() != 'modified' && !col.type && !col.avatar">
                            
                            <v-chip :color="getColors(getObjectData(data, col.field))" label v-if="title == 'Time Sheets' && col.field == 'task_name'">
                            {{ col.field.includes('.') ? getObjectData(data, col.field) : data[col.field] }}
                        </v-chip>
                        <div v-else>
                            {{ col.field.includes('.') ? getObjectData(data, col.field) : data[col.field] }}
                        </div>
                        </div>
                        <div v-if="col.avatar">
                            <Avatar :label="getAvatarName(data[col.f_name], data[col.l_name])" class="mr-2"
                                :style="`background-color:${data[col.theme]};font-size: 75%;width:25px;height:25px`"
                                shape="circle" size="small" />
                            {{ col.field.includes('.') ? getObjectData(data, col.field) : data[col.field] }}
                        </div>
                        <div v-if="col.header.toLowerCase() == 'created' || col.header.toLowerCase() == 'modified' || col.type == 'datetime' || col.type == 'date'"
                            class="date">
                            {{ formatDate(data[col.field], col && col.type ? col.type : '') }}
                        </div>

                        <div v-if="col && col.type == 'boolean'">
                            {{ getBooleanData(data[col.field]) }}
                        </div>
                        <div v-if="col.header.toLowerCase() == 'status'" :class="data.active == 1 ? 'active' : 'in-active'">
                            <span class="helper-legend"
                                :style="data.active == 1 ? { 'background-color': 'rgb(0, 178, 61)', 'border-radius': '2px' } : { 'background-color': 'red', 'border-radius': '2px' }">
                            </span>
                            {{ data['active'] == 1 ? 'Active' : 'InActive' }}
                        </div>
                        <div v-if="col.header.toLowerCase() == 'action' || col.header.toLowerCase() == ''">
                            <span v-for="item of col.rowActions" :key="item"
                                @click.stop="rowActions(data, (item.event ? item.event : item.action))">
                             
                                <i class="pi"
                                v-if="accessPrivilege.indexOf(item.action)>=0 || accessPrivilege.indexOf('update')>=0"
                                 :class="item.action == 'view' ? { 'pi-eye': data } :
                                    item.action == 'edit' ? { 'pi-file-edit': data } :
                                        item.action == 'delete' ? { 'pi-trash': data } : item.action"
                                    style="cursor: pointer;padding-right:7%;"></i>
                            </span>
                        </div>
                    </template>
                </PrimeColumn>
            </template>

<template v-if="title === 'KraReview'">
    <PrimeColumn :header="Review" :style="index == 0 ? col.style : ''">
        <template #header>
            <div>
                Review
                <v-tooltip activator="parent" location="bottom">Review</v-tooltip>
            </div>
        </template>
        <template #body="{ data }">
            <div>
                <button class="reviewButton" @click="reviewClick(data)">Review</button>
            </div>
        </template>
    </PrimeColumn>
</template>

            <template #footer> {{ dataSource ? dataSource.length : 0 }} Record(s) found </template>

        </PrimeDataTable>
    </div>
</template>
<script>
import DataTableLoader from './DataTableLoader.vue';
import PrimeDataTable from 'primevue/datatable';
// import InputText from 'primevue/inputtext';
import * as FileSaver from 'file-saver';

import ListModelService from '@/api/service/ListModel/ListModelService';
import ListModelDetailService from '@/api/service/ListModelDetail/ListModelDetailService';
import ListModelLayoutService from '@/api/service/ListModelLayout/ListModelLayoutService';
import ModelService from '@/service/ModelService';
import PrimeColumn from 'primevue/column';
import { ref } from 'vue';
import * as xlsx from "xlsx";
import { FilterMatchMode } from 'primevue/api';
import "primevue/resources/themes/lara-light-indigo/theme.css";
import "primevue/resources/primevue.min.css";
import 'primeicons/primeicons.css';
import Avatar from 'primevue/avatar';
// import ModelService  from '../service/ModelService'
import exportFromJSON from "export-from-json";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import lodash from 'lodash';
import RolePermissionService from '@/api/service/RolePermission/RolePermissionService'
// import moment from 'moment-timezone'


export default {
    name: "listComponent",
    props: ['model', 'data', 'showDataOnPreview',
        'showFilter', 'searchOption', 'selectAll', 'showDownload', 'showUpload', 'showAdd', 'columnData',
        'exportOptions', 'pagination', 'id', 'addButton', 'headers',
        'checkBoxOnRow', 'addAction', 'rowAction', 'title', 'fileName'
        ,'activity'],
    components: {
        PrimeColumn,
        PrimeDataTable,
        DataTableLoader,
        Avatar
        // InputText,
        // CheckBox
        // PrimeColumnGroup,
        // PrimeRow
    },
    data() {
        return {
            loading:true,
            accessPrivilege:[],
            multipleCheck: false,
            sortFields: [],
            downloadMenu: ['Excel', 'Pdf', 'Json'],
            dataLoading: false,
            listModelDetails: [],
            layoutColumns: [],
            listModelLayoutDetails: [],
            selectedData: ref(),
            rowsPerPageOptions: [5, 10, 20, 50, 100],
            metaKey: ref(true),
            size: ref({ label: 'Small', value: 'small', class: 'sm' }),
            dataSource: [],
            selectedTableData: ref(),
            fileterKey: [],
            filters: ref({
                global: { value: null, matchMode: FilterMatchMode.CONTAINS }
            })
        }
    },
    computed: {
        watchData() {
            return this.data
        }
    },
    watch: {
        model() {
            this.getDataByModel(this.model)
        },
        watchData() {
            this.dataSource = this.data
        }
    },
    async created() {
        if(this.activity) {
            this.accessPrivilege = await RolePermissionService.isAllowed(this.activity)
        }
        this.loading=false
        if (!this.model) {
            this.layoutColumns = this.columnData
            this.dataSource = this.data
            this.layoutColumns.unshift({
                "header": "",
                "headerClass": "action-header",
                "headerStyle": "width: 4%",
            })
        } else {
            this.getDataByModel()
        }
        if (this.layoutColumns && this.layoutColumns.length > 0) {
            this.fileterKey = this.layoutColumns.map(obj => obj.field)
            this.sortFields = this.layoutColumns.map(obj => {
                return { field: obj.field, order: '1' }
            })
            console.log(this.data, "0000000005555%%%%")
        }
    },
    methods: {
        getColors(name){
          if(name == 'Login' && 'LogOut') return '#C08C1E'
          else if(name == 'Meeting') return 'grey'
          else if(name == 'Development') return '#EEEEEE'
          else if(name == 'Break') return 'blue'
          else return 'primary'
        },
        getAvatarName(fName, lName) {
            let name1 = fName ? fName[0] : ""
            let name2 = lName ? lName[0] : ""
            return name1 + name2
        },
        singleCheck() {
            this.multipleCheck = this.dataSource.every((key) => {
                console.log(key)
                return key.checkbox
            })
        },
        getObjectData(data, feild) {
            let opt = lodash.get(data, feild)
            return opt ? opt : ''
        },
        getBooleanData($value) {
            console.log("hello kashif")
            return $value == 1 ? 'Yes' : 'No'
        },
        multiCheck() {
            for (let key of this.dataSource) {
                key.checkbox = this.multipleCheck
            }

        },
        formatDate(dateString, type) {

            if (!dateString) {
                return ''
            }
            else if (type == 'date') {
                const date = new Date(dateString);
                return date.toLocaleDateString()// moment(date).utcOffset('+05:30')
            }
            else {
                const date = new Date(dateString);
                //return moment(date).toLocal()
                return date.toLocaleString();
            }
        },
        async getDataByModel() {
            this.dataLoading = true
            if (this.model) {
                this.dataSource = []
                this.layoutColumns = []
                let $listModel = await ListModelService.findAll({ "model": this.model })
                this.listModelDetails = await ListModelDetailService.findAll({ "model_id": $listModel[0].id })
                this.listModelLayoutDetails = await ListModelLayoutService.findAll(this.model)
                this.listModelLayoutDetails = this.listModelLayoutDetails && this.listModelLayoutDetails.length > 0 ? this.listModelLayoutDetails[0] : {}
                this.listModelLayoutDetails.columns = this.listModelLayoutDetails.columns ? JSON.parse(this.listModelLayoutDetails.columns) : []
                await this.prepareColumnHeader()
                console.log(this.dataSource)
            }
            this.dataLoading = false
        },
        async prepareColumnHeader($modelDetails) {
            console.log($modelDetails)
            this.layoutColumns = []
            this.listModelDetails.forEach(detail => {
                if (this.listModelLayoutDetails.columns.indexOf(detail.column_name) >= 0) {
                    this.layoutColumns.push({
                        "field": detail.column_name,
                        "headerClass": "header",
                        "headerStyle": `width: ${detail.width}px`,
                        "header": detail.label.toUpperCase(),
                        "dataType": detail.data_type,
                        "formatters": detail.formatters,
                    })
                }
                console.log(this.layoutColumns, "load colums")
            })
            this.layoutColumns.unshift({
                "header": "",
                "headerClass": "action-header",
                "headerStyle": "width: 10%",
            })
            this.layoutColumns.push({
                "header": "",
                "headerClass": "checkbox-header",
                "headerStyle": "width: 10%",
                "rowActions": [
                    { "action": "view" },
                    { "action": "edit" },
                    { "action": "delete" },
                ]
            })
            if (this.layoutColumns && this.layoutColumns.length > 0) {
                this.fileterKey = this.layoutColumns.map(obj => obj.field)
            }
            this.dataSource = await ModelService.getAll(this.model)
        },
        selectCheck($event) {
            this.dataSource[$event].isChecked = true
        },
        addDiolog() {
            this.$emit('addDiolog', true)
        },
        addFilter() {
            this.$emit('filter', true)
        },
        async download($event) {
            let fileName = this.fileName ? this.fileName : `${this.title}`;
            let data = await this.formattedData()
            if ($event.toLowerCase() == 'excel') {
                this.exportExcel(fileName)
                // const exportType = exportFromJSON.types.csv;

                // if (data) exportFromJSON({ data, fileName, exportType });
            }
            else if ($event.toLowerCase() == 'json') {
                const exportType = exportFromJSON.types.json;
                if (data) exportFromJSON({ data, fileName, exportType });

            }
            else {
                this.exportAsPDF(data, fileName)
            }
        },
        exportExcel(name) {
            let data = this.formattedData();
            const worksheet = xlsx.utils.json_to_sheet(data);
        // const max_width = this.dataSource.reduce((w, r) => Math.max(w, r.name.length), 10);
        if(data && data.length>0){
            worksheet["!cols"] =[]
            Object.keys(data[0]).forEach(data=>{
            let width  =  { width:20 }
            console.log(data,'called')
            worksheet['!cols'].push(width)
            })
        }
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            this.saveAsExcelFile(excelBuffer, name);
        },
        
        saveAsExcelFile(buffer, fileName) {
            let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let EXCEL_EXTENSION = '.xlsx';
            const data = new Blob([buffer], {
            type: EXCEL_TYPE
            });
            FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
        },
        formattedData() {
            let selectedColumns = this.layoutColumns
            let data = JSON.parse(JSON.stringify(this.dataSource))
            let formateData = []
            for (let i = 0; i < data.length; i++) {
                let $obj = {}
                for (let j = 0; j < selectedColumns.length; j++) {
                    if (selectedColumns[j]['header'].length > 0) {
                        console.log(selectedColumns[j]['header'])
                        if ((selectedColumns[j]['type'] == 'date' || selectedColumns[j]['type'] == 'datetime' || selectedColumns[j].field == 'created' || selectedColumns[j].field == 'modified')) {
                            $obj[selectedColumns[j]['header']] = this.formatDate(lodash.get(data[i], selectedColumns[j].field), selectedColumns[j].field)
                        }
                        else if (selectedColumns[j].field == 'active' || selectedColumns[j].header.toLowerCase() == 'status') {
                            $obj[selectedColumns[j]['header']] = data[i].active == 1 ? true : false
                        }
                        else if (selectedColumns[j]['header'].toLowerCase() != 'action') {
                            $obj[selectedColumns[j]['header']] = lodash.get(data[i], selectedColumns[j].field)
                        }
                    }

                }
                formateData.push($obj)
            }
            return formateData
        },
        exportAsPDF(data, fileName) {
            const headers = this.setPDFHeader(data);
            const fontSize = this.calculateFontSize(headers[0].length);
            const body = this.setPDFBody(data);
            const doc = new jsPDF();
            autoTable(doc, { html: "#dt2" });
            doc["autoTable"]({
                styles: {
                    cellPadding: 0.5,
                    fontSize: fontSize,
                },
                headStyles: {
                    fillColor: "#3f51b5",
                    textColor: "#fff",
                    halign: "center",
                },
                bodyStyles: {
                    halign: "center",
                },
                margin: {
                    left: 5,
                    right: 5,
                },
                tableLineWidth: 1,
                head: headers,
                body: body,
                // didDrawPage: function (data) {

                //   // Header
                //   doc.setFontSize(20);
                //   doc.setTextColor(40);
                //   doc.text("Report", data.settings.margin.left, 22);

                //   // Footer
                //   var str = "Page " + doc.internal.pages;

                //   doc.setFontSize(10);

                //   // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                //   var pageSize = doc.internal.pageSize;
                //   var pageHeight = pageSize.height
                //     ? pageSize.height
                //     : pageSize.getHeight();
                //   doc.text(str, data.settings.margin.left, pageHeight - 10);
                // }
            });
            doc.save(fileName);
        },
        setPDFHeader(data) {
            return [
                Object.keys(data[data.length - 1]).map(
                    (item) =>
                        `${item.charAt(0).toUpperCase()}${item.substr(1, item.length)}`
                ),
            ];
        },
        calculateFontSize(count) {
            return count ? 50 / count : count;
        },
        setPDFBody(data) {
            return data.map((item) => {
                const keys = Object.keys(item);
                const values = [];
                keys.forEach((key) => {
                    if (typeof item[key] === "object" && item[key] !== null) {
                        values.push(
                            item[key].name
                                ? item[key].name
                                : item[key].code
                                    ? item[key].code
                                    : item[key].name
                        );
                    } else {
                        values.push(item[key]);
                    }
                });
                return values;
            });
        },
        downloadExcel() {
        },
        downloadPDF(data) {
            console.log(data, "000999")
            const doc = new jsPDF();
            let header = this.getTableHeader()
            console.log(header, doc)
            doc.autoPrint({
                head: [['Name', 'Age']],
                body: this.data.map(({ name, code }) => [name, code])
            });
            doc.save('data.pdf');
        },
        getTableHeader() {
            return Object.keys(this.dataSource[0])

        },
        reviewClick(data){
            this.$emit('reviewClicked', data)
        },
        rowActions($event, type) {
            let data = {
                data: $event,
                type: type,
                index: $event.index
            }
            console.log(data)
            this.$emit('rowAction', data)
        },
        async sorthTheData($value, index) {
            this.layoutColumns[index].sort = !$value.sort ? 'asc' : $value.sort == 'asc' ? 'desc' : $value.sort == 'desc' ? 'defalt' : ''
            if (this.layoutColumns[index].sort == 'defalt') {
                this.dataSource = await lodash.orderBy(this.dataSource, ['created'], ['desc']);
            }
            else {
                this.dataSource = await lodash.orderBy(this.dataSource, [$value.field], [this.layoutColumns[index].sort]);
            }
            this.dataSource = [...this.dataSource]
        }

    }

}
</script>
<style>
.p-datatable-thead{
    z-index: 1000 !important;
}
.p-avatar-text {
    text-transform: uppercase;
}

.search-input {
    width: 100%;
}

.prime-icon-outlet {
    width: 30px;
    height: 30px;
    padding: 10px;
    color: #7b7b7b;
    font-weight: 800;
    border-radius: 4px;
    border: 0px solid #d1cece;
    cursor: pointer;
    margin-left: 3%;
}

.prime-icon-outlet:hover {
    background-color: #F3F9FF;
    color: #000000;
    font-weight: 800;
}

.p-datatable .p-sortable-column .p-sortable-column-icon {
    height: 11px;
    color: #343a40;
    margin-left: 0.5rem;
}

.p-datatable {
    font-size: 12px;
}

.p-datatable .p-datatable-tbody>tr:hover {
    background-color: #16A9F623 !important;
}

.p-datatable .p-paginator-bottom {
    float: right;
    border-width: 0 0 1px 0;
    border-radius: 0;
}

.p-datatable .p-datatable-thead>tr>th {
    background-color: #16A9F623;
    font-weight: 800;
    border: 0px;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    border-radius: 7px;
    background: #EEF2FF;
    border-color: #EEF2FF;
    color: #4338CA;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight:hover {
    border-radius: 7px;
    background: #EEF2FF;
    border-color: #EEF2FF;
    color: #4338CA;
}

.p-datatable .p-datatable-tbody {
    min-height: 90vh;
}

.p-datatable .p-datatable-footer {
    background: #16A9F603;
    color: #343a40;
    border: 1px solid #dee2e6;
    border-width: 0 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 700;
}

.date {
    font-weight: bold;
    color: #e4ae83;
}

.p-datatable .p-datatable-header {
    background: #ffffff;
    color: #272727;
    border: 0px solid #eee;
    border-width: 0px 0 0px 0;
    padding: 0px 0px 0px 0px;
    margin: 0px 0px 0px 0px;
    font-weight: 700;
}

.header {}

.header-action {
    align-items: right !important;
}

.in-active {
    font-weight: bold;
    color: #62be62;
    ;
}

.helper-legend {
    border: 1px solid rgba(0, 0, 0, .17);
    width: 10px;
    vertical-align: middle;
    height: 10px;
    display: inline-block;
    position: relative;
    left: 0px;
    box-sizing: content-box;
    -webkit-box-sizing: content-box;
}

.active {
    font-weight: bold;
    color: rgb(0, 178, 61);
}

.align-right {
    text-align: right;
}

.align-center {
    text-align: center;
}

.p-datatable th[class*="action-header"] .p-column-header-content {
    text-align: center;
    justify-content: center;
}

.menu-cursor:hover {
    cursor: pointer;
    background-color: #16A9F623;
}

.p-component-overlay {
    background-color: white !important;
}
</style>

<style scoped>
>>>.v-field__outline {
    --v-field-border-width: 0px !important;
    border: 10px !important;
    --v-field-border-opacity: 0.38;
    align-items: stretch;
    contain: layout;
    display: flex;
    height: 100%;
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    width: 100%;
    border: 1px solid #ADADAD !important;
    border-radius: 4px !important;
}

>>>.v-field--variant-outlined.v-field--focused .v-field__outline {
    --v-field-border-width: 0px !important;
    border: 2px solid #3C5AAA !important;
}

>>>.v-messages__message {
    line-height: 12px;
    word-break: break-word;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -webkit-hyphens: auto;
    hyphens: auto;
    transition-duration: 150ms;
    text-align: left;
}

>>>.action {
    display: flex;
    align-items: start;
    column-gap: 5px;
}

.checkBox {
    height: 0px;
    position: relative;
    bottom: 26px;
}

.reviewButton{
color: #FFF;
font-size: 11px;
font-weight: 700;
border-radius: 5px;
background: #1258AB;
padding: 3px 5px;
}
</style>
