<template>
    <v-card flat>
        <v-form @submit.prevent="create" ref="form">
            <v-card-title>
                Create Dashboard
                <v-btn flat icon="mdi mdi-close" class="mt-1 float-right" density="compact" size="small"
                    @click="$emit('closeDialog')" />
            </v-card-title>

            <v-card-text>
                <v-label class="py-2">
                    Name
                </v-label>
                <TextFieldComponent v-model="dashboard.name" placeholder="Name for the dashboard" :rules="nameRule" />
                <v-label class="py-2">
                    Model
                </v-label>
                <v-row>
                    <CardSelection :menuOptions="modelOptions" @cardSelected="cardSelected" />
                </v-row>
                <v-row v-if="showModelError" class="pa-4">
                    <v-error>Model is required</v-error>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <Button style="background-color:#1258AB;color:#fff" flat type="submit" name="Create" block
                    color="#1258AB" />
            </v-card-actions>
        </v-form>
    </v-card>
</template>
<script>
import DashboardWidgetService from '@/api/service/DashboardWidget/DashboardWidgetService'
import { CardSelection } from 'frontendcomponent';
import { Button } from 'frontendcomponent'
import { TextFieldComponent } from 'frontendcomponent';
export default {
    components: {
        TextFieldComponent,
        CardSelection,
        Button
    },
    data() {
        return {
            showModelError:false,
            nameRule: [(value) => !!value || "Name is Required."],
            modelOptions: {
                "columns": 2,
                "height": "190px",
                "showSearchBox": false,
                "outlineOnHover": true,
                "outlineOnSelection": true,
                "actions": [],
                "multiSelection": false,
                "selectedValue": "code",
                "scrollContainerHeight": "25vh",
                "options": [
                    {
                        "title": "Task",
                        "code": "issue",
                        "subTitle": "By Task Model",
                        "description": "Model would allow you to create dashboard with data driven by Tasks"
                    },
                    {
                        "title": "Time",
                        "code": "time",
                        "subTitle": "By Hours model",
                        "description": "Model would allow you to create dashboard with data driven by Hours"
                    }
                ]
            },
            dashboard: {
                name: "",
                model_type: ""
            }
        }
    },
    methods: {
        cardSelected($item) {
            this.dashboard.model_type = $item
        },
        async create() {
            let validation = await this.$refs.form.validate()
            if (!validation.valid || !this.dashboard.model_type) {
                console.log("Not valid")
                if(!this.dashboard.model_type){
                    this.showModelError =true
                }
                else{
                    this.showModelError=false
                }
            }
            else {
                this.dashboard.active = 1
                this.dashboard.deleted = 0
                let $widget = await DashboardWidgetService.create(this.dashboard)
                this.$emit('dashboardCreated', $widget.data.data)

            }
        }
    }
}
</script>