<template>
    <v-card elevation="0" style="border-radius:10px">
        <v-form @submit.prevent="save" ref="form">
            <v-card-item>
                <v-card-title>
                    Follow Up
                    <v-avatar size="x-small" color="primary">
                        {{no_of_followups}}
                    </v-avatar>
                    <v-btn style="float: right;" @click="close()" elevation="0" density="comfortable"
                        icon="mdi-close"></v-btn>
                </v-card-title>
            </v-card-item>


            <v-card-text>
                <v-row no-gutters>
                    <v-col cols="12" class="px-0 py-0" style="display: grid">
                        <v-radio-group :disabled="showReadOnly" v-model="formData.followup_type" inline>
                            <v-radio color="primary" label="Internal" :value="1"></v-radio>
                            <v-radio color="error" label="External" :value="0"></v-radio>
                        </v-radio-group>
                    </v-col>

                    <v-col cols="4">
                        <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">FollowUp Date
                            <span v-if="!showReadOnly"
                                style="color: #cf0707; padding-left: 2px; font-weight: 600">*</span></v-label>
                        <TextFieldComponent density="compact" placeholder="FollowUp date" :rules="dateRule"
                           v-model="formData.followup_date" type="date"/>
                    </v-col>

                    <v-col cols="12">
                        <v-label class="pb-1"
                            style="font-size: 14px; color: #272727; font-weight: 600">Description</v-label>
                        <TextArea class="pb-0" editorStyle="height: 120px" :data="formData.description"
                            @textData="changeDescription($event)"></TextArea>
                    </v-col>
                </v-row>

            </v-card-text>
            <v-card-action class="mr-3">
                <v-row justify="end" class="dialogBottom">
                    <v-btn style=" --v-btn-height: 35px;" class="mr-2 cancel" @click="close()">Cancel</v-btn>
                    <v-btn style=" --v-btn-height: 35px;" :loading="saveClicked" class="save" type="submit">Save</v-btn>
                </v-row>
            </v-card-action>
        </v-form>
    </v-card>
</template>
      
<script>
import TextArea from "@/components/ui/TextArea.vue";
import IssueService from "@/api/service/Issue/IssueService";
import ForumService from "@/api/service/Forum/ForumService";
import TextFieldComponent from "@/components/TextFieldComponent.vue";
export default {
    components: {
        TextArea,
        TextFieldComponent
    },
    props: ['issueId','no_of_followups'],
    data() {
        return {
            saveClicked:false,
            formData: {
                followup_date: null,
                followup_type: 1,
                description: null
            },
            dateRule: [(value) => !!value || "Follow up is Required"],
        }
    },
    methods: {
        close() {
            this.$emit("closeDialog");
        },
        changeClarification($event) {
            this.formData.clarification = $event;
        },
        changeDescription($event) {
            this.formData.description = $event;
        },

        async save() {
            let validation = await this.$refs.form.validate()
            if (!validation.valid) {
                console.log(validation.valid)
            }
            else {
                if (this.issueId) {
                    this.saveClicked =true
                    let followup;
                    if (this.formData.followup_type == 1) {
                        followup = 'internal'
                    } else {
                        followup = 'external'
                    }
                    let $obj = {
                        id: this.issueId,
                        followup_type: followup,
                        followup_date: this.formData.followup_date
                    }
                    await IssueService.update($obj);
                    let $description = this.formData && this.formData.description ? this.formData.description : ''

                    let data = {
                        key_type: "issue",
                        key_id: this.issueId,
                        action: 'followup',
                        description: $description
                    };
                    await ForumService.create(data)
                    this.$emit('followup-updated')
                    this.close();
                    this.formData = {};
                }
            }
        },
    }

}
</script>

<style></style>