<template>
    <v-card flat>
        <v-card-item>
            <v-card-title>
                {{
                    viewType == "edit" ? "Edit" : viewType == "view" ? "View" : "Add"
                }}
                {{ jobs.end_point == 'reports' ? 'Schedule Report' : jobs.end_point == 'recurring' ? 'Setup Recurring' :
                    'Schedule Job' }}
                <v-btn @click="close()" elevation="0" density="comfortable" icon="mdi-close" class="x-closeIcon"></v-btn>
            </v-card-title>
        </v-card-item>
        <v-card-text>
            <v-form @submit.prevent="save" ref="form" v-model="value">
                <v-row dense>
                    <v-col v-if="jobs.id" cols="12" class="px-0 py-0" style="display: grid">
                        <v-radio-group :disabled="showReadOnly" v-model="jobs.active" inline>
                            <v-radio color="primary" label="Active" :value="1"></v-radio>
                            <v-radio color="error" label="Inactive" :value="0"></v-radio>
                        </v-radio-group>
                    </v-col>
                    <v-col cols="12" class="py-0" v-if="!endPoint">
                        <v-label>
                            End Point
                        </v-label>
                        <AutoComplete :disabled="showReadOnly" v-model="jobs.end_point" :items="endPoints" item-title="name"
                            item-value="id" placeholder="Select endpoint" @update:modelValue="endPointChanged" />
                    </v-col>
                    <v-col cols="12" class="py-0">
                        <v-label>
                            Interval Type
                        </v-label>
                        <v-radio-group :disabled="showReadOnly" v-model="jobs.interval_type" inline>
                            <v-radio v-for="intervalType in intervalTypes" color="primary" v-bind:key="intervalType"
                                :label="intervalType.name" :value="intervalType.id" :v-bind:key="intervalType"></v-radio>
                        </v-radio-group>
                    </v-col>
                    <v-col cols="12">
                        <v-label>
                            Interval Frequency
                        </v-label>
                        <TextFieldComponent :disabled="showReadOnly" hide-details v-model="jobs.interval_frequency" />
                    </v-col>
                    <v-col cols="12">
                        <v-label>
                            Include Days
                        </v-label>
                        <v-row no-gutters dense>
                            <v-col class="pa-0 ma-0" cols="3" v-for="day in days" v-bind:key="day">
                                <v-switch :disabled="showReadOnly" color="primary" inline v-model="include_days[day.id]"
                                    density="compact" :label="day.name.substr(0, 3)" />
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="3">
                        <v-label>
                            Next Date

                        </v-label>
                        <TextFieldComponent :disabled="showReadOnly" type="date" :showtime="false" v-model="nextDate" />
                    </v-col>

                    <v-col cols="3">
                        <v-label>
                            Next Time
                        </v-label>
                        <AutoComplete :disabled="showReadOnly" :items="timelines" item-title="name" item-value="id"
                            v-model="nextTime" placeholder="Select Time" @update:modelValue="nextTimeLineChanged" />
                    </v-col>

                    <v-col cols="3">
                        <v-label>
                            Expires on
                        </v-label>
                        <TextFieldComponent :disabled="showReadOnly" type="date" :showtime="false" v-model="expireDate" />

                    </v-col>
                    <v-col cols="3">
                        <v-label>

                        </v-label>
                        <AutoComplete :disabled="showReadOnly" :items="timelines" item-title="name" item-value="id"
                            v-model="expireTime" placeholder="Select time" @update:modelValue="expireTimeLineChanged" />
                    </v-col>
                    <v-col cols="12" v-if="!showReadOnly">
                        <v-btn @click="submit" variant="flat" class="save" block>Submit </v-btn>
                    </v-col>
                </v-row>

            </v-form>
        </v-card-text>
    </v-card>
</template>
<script>
import moment from 'moment'
import { TextFieldComponent } from 'frontendcomponent'
import AutoComplete from '@/components/ui/AutoComplete.vue';
import JobsService from "@/api/service/Jobs/JobsService";


export default {
    components: {
        AutoComplete,
        TextFieldComponent
    },
    props: ["endPoint", "params", 'id', "jobId", 'viewType'],
    data() {
        return {
            showReadOnly: false,
            days: [
                { "id": "sun", "name": "Sunday" },
                { "id": "mon", "name": "Monday" },
                { "id": "tue", "name": "Tuesday" },
                { "id": "wed", "name": "Wednesday" },
                { "id": "thu", "name": "Thursday" },
                { "id": "fri", "name": "Friday" },
                { "id": "sat", "name": "Saturday" },
            ],
            intervalTypes: [
                { "id": "minutes", "name": "Minutes" },
                { "id": "hourly", "name": "Hourly" },
                { "id": "daily", "name": "Daily" },
                { "id": "weekly", "name": "Weekly" },
                { "id": "monthly", "name": "Monthly" }
            ],
            endPoints: [
                { "id": "recurring", "name": "Setup Recurring issue" },
                { "id": "report", "name": "Schedule a report" },
                { "id": "job", "name": "Schedule a job" },
            ],
            include_days: {
                "sun": true, "mon": true, "tue": true, "wed": true, "thu": true, "fri": true, "sat": false
            },
            "nextDate": moment(),
            "nextTime": "",
            "expireDate": "",
            "expireTime": "",
            jobs: {
                "end_point": this.end_point,
                "params": this.params ? this.params : {},
                "interval_type": "day",
                "interval_frequency": 1,
                "include_days": ["mon", "tue", "wed", "thu", "fri", "sat", "sun"],
                "next_date": null,
                "expire_date": null
            }
        }
    },
    created() {
        this.jobs.end_point = this.endPoint
        this.jobs.params = this.params
        this.createTimelines()
        if (this.viewType) {
            this.showReadOnly = this.viewType == "edit" ? false : true;
        }
        if (this.jobId) {
            this.getJob(this.jobId)
        }
    },
    watch: {
        jobId() {
            if (this.jobId) {
                this.getJob(this.jobId)
            }
        }
    },
    mounted() {

    },
    methods: {

        async getCurrentDateToUtc(date) {
            var someDate = new Date(date);
            var utcDate = someDate.toUTCString();
            let formatDate = moment(utcDate).format('YYYY-MM-DD HH:mm:ss')
            // console.log(someDate, "hhhhhhhhhhhhh444", formatDate)
            return formatDate;
            //   }
        },
        moment,
        changeinData() {
            alert(this.this.nextDate)
        },
        close() {
            this.$emit('closeDialog')
        },
        async getJob($id) {
            let $job = await JobsService.findOne($id)
            console.log($job, "pppppppppppppppprrrrr")
            this.include_days = $job && $job.include_days ? JSON.parse($job.include_days) : this.include_days
            $job.params = JSON.parse($job.params)
            this.nextTime = moment($job.next_date).format('HH:mm')
            this.expireTime = moment($job.expire_date).format('HH:mm')
            this.nextDate = $job.next_date ? moment($job.next_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
            this.expireDate = $job.expire_date ? moment($job.expire_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
            this.jobs = $job

            // console.log(this.jobs , 'balabro',this.nextDate,this.nextTime,this.expire_date,this.expireTime)
        },
        postClose($message) {
            this.$store.dispatch('setSnackbar', { text: $message })
            this.close();
            this.$emit("reload", true);
            this.jobs = {};
        },

        formatDate(dateString, type) {

            if (!dateString) {
                return ''
            }
            else if (type == 'date') {
                const date = new Date(dateString);
                return date.toLocaleDateString()// moment(date).utcOffset('+05:30')
            }
            else {
                const date = new Date(dateString);
                //return moment(date).toLocal()
                return date.toLocaleString();
            }
        },
        async submit() {
            let validation = await this.$refs.form.validate()
            if (!validation.valid || this.codeError) {
                console.log(validation.valid)
            }
            let $jobs = Object.assign({}, this.jobs)
            $jobs.next_date = moment(this.nextDate + " " + this.nextTime)
            $jobs.expire_date = moment(this.expireDate + " " + this.expireTime)
            $jobs.include_days = JSON.stringify(this.include_days)
            $jobs.params = JSON.stringify($jobs.params)
            if ($jobs.id) {
                await JobsService.update($jobs)
                this.postClose('Update Successfully')
            } else {
                await JobsService.create($jobs)
                this.postClose('Saved Successfully')
            }
        },
        nextTimeLineChanged($data) {
            this.nextTime = $data
            this.jobs.next_date = moment(this.nextDate + " " + this.nextTime)

        },
        expireTimeLineChanged($data) {
            this.expireTime = $data
            this.jobs.expire_date = moment(this.expireDate + " " + this.expireTime)

        },
        endPointChanged($data) {
            this.jobs.end_point = $data
        },
        createTimelines() {
            let $fromDate = this.nextDate ? moment(this.nextDate) : moment()
            let $start = $fromDate.toObject();
            console.log($start)
            let $timelines = []
            if ($fromDate.get('minutes') <= 30) {
                $timelines.push(moment($fromDate).format('HH:mm'))
                $fromDate.set('minutes', 30)
                $timelines.push(moment($fromDate).format('HH:mm'))
            } else {
                $fromDate.set('hours', $fromDate.get('hours') + 1)
                $fromDate.set('minutes', 0)
                $timelines.push(moment($fromDate).format('HH:mm'))
            }
            do {
                $fromDate = moment($fromDate).add(.5, 'hour')
                $timelines.push(moment($fromDate).format('HH:mm'))
            } while ($fromDate.get('hours') < 23)
            $timelines.push('23:30')
            $timelines.push('00:00')
            this.timelines = $timelines
        }
    }
}
</script>
<style scoped>
>>>.v-btn--icon .v-icon {
    --v-icon-size-multiplier: .7;
}

.save {
    background-color: #3C5AAA;
    color: #fff;
}
</style>