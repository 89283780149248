<template>
  <div v-if="loading" class="skeletonBox">
    <div style="display: flex">
      <v-col class="pa-0" v-for="index in 5" :key="index">
        <Skeleton height="55vh" width="275px"></Skeleton>
      </v-col>
    </div>
  </div>
  <!-- <div v-else> -->
  <div
    class=""
    :class="maxValue ? 'maximize' : 'mainContainer'"
    v-else-if="
      data && data.group && data.group.key0 && data.group.key0.length > 0
    "
  >
    <div
      v-for="key0 in data.group.key0"
      :key="key0.id"
      style="display: contents"
    >
      <div
        class=""
        :class="maxValue ? 'maxParent' : 'parentCard'"
        v-if="isOkToShowBoard(key0.id,key0.role_code)"
      >
      

        <div class="sub-header mb-0">
          <div class="avatarTitle">
          <!--   <CustomAvatar2
              v-if="key0 && key0.keyId"
              :userId="key0.keyId"
              :size="'small'"
            /> -->
          
            <CustomAvatar2 :size="'small'" 
                :fName="key0.first_name" 
                :lName="key0.last_name" 
                :theme="key0.theme" 
                :imgUrl="key0.image_url" 
                />
            <div class="heading text-capitalize ml-1">
              <v-row no-gutters dense>
                <v-col cols="12">
                  <span
                    class=""
                    style="
                      text-transform: uppercase;
                      line-height: 18px;
                      font-weight: 800;
                      font-size: 12px;
                      color: #3d3d3d !important;
                    "
                    >{{ key0 && key0.value ? key0.value : "" }}
                    &nbsp; ({{ getIssueCount(key0) }})
                  </span>
                  
                </v-col>
                <v-col class="text-caption" style="white-space: nowrap">
                  <span class="pa-0 font-weight-bold" style="color: #066269">
                    {{
                       key0.role_code && key0.role_code.toUpperCase()
                    }}
                  </span>
                  <span
                    class="pa-1 font-weight-bold title-case"
                    style="color: #066269"
                    >| 
                    {{ 
                    key0.user_stage=='login' ? 'Online' :
                    key0.user_stage=='logout' ? 'Offline' : key0.user_stage
                    }}</span
                  >
                </v-col>
              </v-row>
            </div>
          </div>

          <!-- <div class="pb-2"> -->
            <!-- <v-sheet rounded class="imageInSheet"> -->
          <!--   <v-img
              :style="{ width: '24px', height: '24px' }"
              :src="getStatusImage(key0.workSpaceData[0].users.status)"
            /> -->
            <!-- </v-sheet> -->
          <!-- </div> -->
        </div>
        <div
          class="diffStages"
          v-for="(key1, key1Index) in data.group.key1"
          :key="key1Index"
        >
          <v-expansion-panels
            variant="accordion"
            class="ma-0 pa-0"
            :id="key1Index"
            v-if="getIssueFilterData(
                      key0,
                      key1
                    ).length>0"
            v-model="key1.isOpen"
          >
            <v-expansion-panel
              elevation="0"
              class="ma-0 pa-0"
              :draggable="false"
              @drop="onDrop($event, key1.keyId, key0.value)"
              @dragenter.prevent
              @dragover.prevent
            >
              <v-expansion-panel-title
                class="name"
                expand-icon="mdi-plus"
                collapse-icon="mdi-minus"
              >
                <!-- {{ key1 && key1.keyId?key1.keyId.toUpperCase():'' }} -->
                <div
                  class="text-subtitle-2"
                  style="
                    color: #363636;
                    font-weight: 800;
                    font-size: 11px !important;
                  "
                >
                  {{ key1 && key1.keyId ? key1.keyId.toUpperCase() : "" }}
                </div>
              </v-expansion-panel-title>

              <v-expand-x-transition>
                <v-expansion-panel-text class="ma-0 pa-0">
                  <div
                  class="issue-detail"
                    style="height: auto"
                    v-for="(issue, issueIndex) in getIssueFilterData(
                      key0,
                      key1
                    )"
                    :key="issueIndex"
                  >
                    <v-row
                      :draggable="true"
                      @dragstart="dragStart($event, issue)"
                      no-gutters
                      class="my-1 detail"
                      @click="atClick(issue)"
                      :class="
                        issue.stage.toLowerCase() == 'in progress'
                          ? 'inprogress'
                          : ''
                      "
                    >
                     
                      <v-col class="pl-1" cols="12" style="display: flex">
                        <span>
                          <v-avatar class="account-avatar" color="#08929B" rounded="0" size="24px">
                          <span style="color: #fff; font-size: 9px">{{
                            issue && issue.account_code
                              ? issue.account_code.toUpperCase().substr(0, 3)
                              : ""
                          }}</span>
                        </v-avatar>
                        </span>
                        <span
                          class="pl-2 text-body-2 issueName"
                          style="font-size: 12px !important"
                          >{{ issue.name }}</span
                        >
                        <v-icon
                          v-if="issue && issue.priority == 'critical'"
                          class="pi pi-exclamation-triangle"
                          style="color: red; font-size: 12px; font-weight: 800"
                        />
                        <v-icon  v-if="issue.week_end_mails=='yes'"  class="mdi mdi-circle-half-full" size="16" color="blue"></v-icon>
                      </v-col>

                    

                      <v-col cols="12">
                        <div style="display: flex; gap: 4px; margin-top: 5px">
                          <div v-if="issue.category_name" class="chip">
                            {{ issue.category_code.toUpperCase() }}
                          </div>
                          <div v-if="issue.estimate" class="chip">
                            {{ issue.estimate }} Mins
                          </div>
                          <div
                          align="end"
                          class="chip"
                          v-if="issue.end_date"
                          :class="isDateOverDue(issue.end_date)"
                          style="font-size:10px;font-weight: 600"
                        >
                          {{
                            issue.end_date &&
                            moment(issue.end_date).format("MMM DD") !=
                              "Invalid date"
                              ? moment(issue.end_date).format("MMM DD")
                              : ""
                          }}  {{
                            issue.end_date &&
                            moment(issue.end_date).format(" hh:mm A") !=
                              "Invalid date"
                              ? moment(issue.end_date).format(" hh:mm A")
                              : ""
                          }}
                        </div>
                        </div>
                      </v-col>
                    </v-row>
                    <hr class="hrline" style="border-style: dashed; border-color: #c6c6c6" />
                  </div>
                </v-expansion-panel-text>
              </v-expand-x-transition>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </div>
    </div>
  </div>
  <!-- </div> -->
</template> 
  
<script>
import moment from "moment";
import IssueService from "@/api/service/Issue/IssueService";
import UserService from "@/api/service/UserService";
import Skeleton from "primevue/skeleton";
import CustomAvatar2 from "@/components/ui/CustomAvatar2.vue";
import CustomDashBoardService from "@/api/service/CustomDashBoard/CustomDashBoardService";
// import NoRecordFound from './NoRecordFound.vue';
import lodash from 'lodash'
export default {
  props: ["maxValue"],
  components: {
    CustomAvatar2,
    Skeleton,
  },
  data() {
    return {
      expendData: [],
      panel: false,
      workTiming: null,
      wsDefaults: null,
      data: null,
      loading: true,
      mainData: [],
      issueData: [],
      userData: [],
      issueDataOld: [],
      filterData: {},
      searchQuery: "",
      sort: null,
      viewType: "Board",
      boardLoaded: false,
    };
  },
  async created() {
    let $workTiming = localStorage.getItem("_WORK_TIMING_");
    this.workTiming = $workTiming ? JSON.parse($workTiming) : null;
    let $wsDefaults = localStorage.getItem("_WS_DEFAULTS_");
    this.wsDefaults = $wsDefaults ? JSON.parse($wsDefaults) : null;
    await this.getIssueData();
    await this.getAllusers();
  },
  watch: {
    getRefresh() {
      if (this.getRefresh) {
        this.getIssueData();
      } else {
        this.getIssueData();
      }
    },
    refreshOnUser() {
      if (this.refreshOnUser) {
        this.getIssueData();
      } else {
        this.getIssueData();
      }
    },
    reloadTheData() {
      this.getIssueData();
    },
  },
  computed: {
    getRefresh() {
      return this.$store.getters.getIssueUpdate;
    },
    refreshOnUser() {
      return this.$store.getters.getAssignee;
    },
    filteredResources() {
      if (this.searchQuery) {
        return this.globalFilter(this.issueData, this.searchQuery);
      } else {
        return this.mainData;
      }
    },

    reloadTheData() {
      return this.$store.getters.getReloadTheIssue;
    },
  },

  methods: {
    isDateOverDue(date) {
      let currentDate = new Date();
      let dueDate = new Date(date);
      return currentDate > dueDate ? "text-red" : "text-orange";
    },

    getStatusImage(status) {
      if (status === "inprogress") {
        return require("@/assets/image/clock.svg");
      } else if (status === "in meeting") {
        return require("@/assets/image/command.svg");
      } else if (status === "in break") {
        return require("@/assets/image/cupcake.svg");
      } else {
        return require("@/assets/image/color.svg");
      }
    },
    getExpandData(key1, key2) {
      for (let i = 0; i < this.expendData.length; i++) {
        for (let j = 0; j < this.expendData[i].exp.length; j++) {
          if (
            this.expendData[i].key1 == key1 &&
            this.expendData[i].exp[j].key2 == key2
          ) {
            return this.expendData[i].exp[j].isOpen;
          }
        }
      }
    },
    getIssueCount(key) {
      let count = 0;
      for (let i = 0; i < this.data.data.length; i++) {
        if (this.data.data[i]["next_action"] == key.keyId) {
          count++;
        }
      }
      return count;
    },
    async dragStart(event, issue) {
      // console.log("xxxxxxxs", issue.id);
      event.dataTransfer.dropEffect = "move";
      event.dataTransfer.effectAllowed = "move";
      event.dataTransfer.setData("issueId", issue.id);
    },

    async onDrop(event, stage, name) {
      let nextAction = this.getUserDetails(name);
      let $stage = stage;
      let issueID = event.dataTransfer.getData("issueId");
      let status = this.$store.getters.getIssueUpdate;
      await IssueService.update({
        id: issueID,
        stage: $stage,
        next_action: nextAction,
      });
      this.getIssueData();
      this.$store.commit("ISSUE_CHANGE", !status);
    },
    isOkToShowBoard($userId,$roleCode) {
      //alert($roleCode)
      
      let $filtered = lodash.filter(this.data.data,{"next_action":$userId})
      if($filtered && $filtered.length==0) {
          return ($roleCode=='PA'|| $roleCode=='PL') ? true : false 
      }
      return true;
    },
    getUserDetails(username) {
      let $data = this.userData.filter(
        (k) => k.name.toLowerCase() == username.toLowerCase()
      );
      return $data && $data.length > 0 ? $data[0].id : "";
    },

    async getAllusers() {
      let res = await UserService.findAll();
      this.userData = res;
    },

    globalFilter(arr, searchKey) {
      this.issueData = arr.filter(function (obj) {
        return Object.keys(obj).some(function (key) {
          if (key == "next_action_name" || key == "name") {
            let keyValue =
              obj[key] && obj[key] != null
                ? typeof obj[key] == "number"
                  ? obj[key].toString()
                  : obj[key]
                : null;
            return (
              keyValue &&
              typeof keyValue != "object" &&
              keyValue.toLowerCase().includes(searchKey.toLowerCase())
            );
          }
        });
      });
      this.aggregateIssueData();
    },

    getIssueFilterData(key0, key1) {
      let $data = [];
      for (let i = 0; i < this.data.data.length; i++) {
        if (
          this.data.data[i][key0["keyType"]] == key0.keyId &&
          this.data.data[i][key1["keyType"]] == key1.keyId
        ) {
          //  this.addTheCount(key0)
          $data.push(this.data.data[i]);
        }
      }
      return $data;
    },
    slectView(button) {
      this.viewType = button;
    },

    moment,
    async getIssueData() {
      try {
        let obj = {
          id: 1,
          name: "Process Dashboard",
          type: "issue",
          defaultFilters: {},
          filters: {"work_timing_id":["!",this.workTiming.id],"user_id":"#notnull#"},
          sort: [
            {
              key: "name",
              direction: "asc",
            },
          ],
          grouping: ["user", "stage"],
          workSpace: {
            keyId:
              this.wsDefaults &&
              this.wsDefaults.workspace &&
              this.wsDefaults.workspace.id
                ? this.wsDefaults.workspace.id
                : null,
            keyType: "same",
          },
        };
        if (this.wsDefaults) {
          obj.defaultFilters.work_space_id =
            this.wsDefaults &&
            this.wsDefaults.workspace &&
            this.wsDefaults.workspace.id
              ? this.wsDefaults.workspace.id
              : "";
        }
        let res = await CustomDashBoardService.getCustomBoardData(obj);
        console.log(res, "ooooooppppppp");
        if (res) {
          for (let velue of res.group.key0) {
            let obj = {};
            obj.key1 = velue.keyId;
            obj.exp = [];
            await res.group.key1.forEach((k, index) => {
              let obj2 = {};
              obj2.key2 = k.keyId;
              obj2.isOpen = index == 0 ? 1 : 0;
              obj.exp.push(obj2);
              k.isOpen = 0;
            });
            this.expendData.push(obj);
          }
        }
        console.log(this.expendData, "ooooo");
        this.data = res;
        console.log(this.data, "hhjrehrj");
        this.loading = false;
      } catch (e) {
        this.data = null;
        console.log(e);
      }
    },

    atClick(item) {
      this.$emit("draweView", item);
      // this.$router.push({ path: "/issueView2", query: { id: selectedId } });
    },
    changeFilter(data) {
      this.filterData = data;

      Object.keys(this.filterData).forEach((key) => {
        if (this.filterData[key] === null) {
          delete this.filterData[key];
        }
      });

      this.getIssueData(this.filterData);
    },

    downloadData() {
      alert("downloadata");
    },

    sortData() {
      if (this.sort === null) {
        this.sort = true;
      } else if (this.sort == true) {
        this.sort = false;
      } else {
        this.sort = null;
      }
      this.getIssueData(this.filterData);
    },
    updateSearchQuery(query) {
      this.searchQuery = query;
      return this.globalFilter(this.issueDataOld, this.searchQuery);
    },
  },
};
</script>

  
<style scoped>
.skeletonBox {
  width: 94vw;
  height: 60vh;
  overflow: hidden;
}

>>> .p-skeleton {
  background-color: #ececec;
  border-radius: 4px;
}

.mainContainer {
  display: -webkit-inline-box;
  gap: 15px;
  border-radius: 4px;
  width: 100%;
  max-height: 60vh;
  overflow-x: auto;
}

.maximize {
  display: -webkit-inline-box;
  gap: 15px;
  border-radius: 4px;
  width: 100%;
  max-height: 80vh;
  overflow-x: auto;
  padding: 15px 15px;
  background: #efefef;
}

.maxParent {
  background: #fff;
  border-radius: 4px;
  width: 275px;
  height: 80vh;
  overflow: hidden;
  padding: 0px 20px 20px 20px;
  overflow-y: auto;
}

.parentCard {
  background: #fff;
  border-radius: 4px;
  width: 275px;
  height: 58vh;
  overflow: hidden;
  padding: 0px 20px 20px 20px;
  overflow-y: auto;
}

.sub-header {
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0px;
  z-index: 2;
  background-color: #fff;
  padding: 0;
  height: 45px;
  align-items: end;
}

.imageInSheet {
  /* position: absolute; */
  height: 40px;
  width: 40px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d7eaf4;
}

.avatarTitle {
  display: flex;
  gap: 2px;
}

.detail {
  min-height: 30px;

  margin: auto auto;
}

.heading {
  line-height: 0.8;
}

.chip {
  white-space: nowrap;
  padding: 3px 5px;
  border-radius: 4px;
  height: fit-content;
  width: fit-content;
  display: flex;
  align-items: center;
  font-size: 10px;
  color: #929292;
  font-weight: 600;
  background-color: #ededed;
  border: 1px solid #F9C644;
  background: #f9f3e4;
  color: #000;
  
}

.issueName {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  line-height:14px;
  color: #272727;
  font-weight: 400;
  -webkit-box-orient: vertical;
}

.inprogress {
  background-color: #e8fffc;
}

>>> .v-expansion-panel-title {
  align-items: center;
  text-align: start;
  border-radius: inherit;
  display: flex;
  font-size: 0.9375rem;
  line-height: 1;
  min-height: 48px;
  outline: none;
  padding: 2px;
  position: relative;
  transition: 0.3s min-height cubic-bezier(0.4, 0, 0.2, 1);
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid;
  border-color: rgb(219, 219, 219) !important;
}

>>> .v-expansion-panel-title--active > .v-expansion-panel-title__overlay,
.v-expansion-panel-title[aria-haspopup="menu"][aria-expanded="true"]
  > .v-expansion-panel-title__overlay {
  opacity: 0;
}

>>> .v-expansion-panel-title {
  align-items: center;
  text-align: start;
  border-radius: inherit;
  display: flex;
  font-size: 0.9375rem;
  line-height: 1;
  min-height: 35px;
  outline: none;
  padding: 2px;
  position: relative;
  transition: 0.3s min-height cubic-bezier(0.4, 0, 0.2, 1);
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid;
}

>>> .v-expansion-panel-title:hover > .v-expansion-panel-title__overlay {
  opacity: 0;
}

>>> .diffStages .v-avatar.v-avatar--density-default {
  height: 28px;
  width: 32px;
  border-radius: 5px !important;
}

>>> .v-expansion-panel-text__wrapper {
  padding: 0px 2px;
  flex: 1 1 auto;
  max-width: 100%;
}

>>> ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

>>> ::-webkit-scrollbar:hover {
  width: 10px;
  height: 10px;
}

>>> ::-webkit-scrollbar-track {
  background: #e8f3f9;
}

>>> ::-webkit-scrollbar-thumb {
  background: rgb(0, 0, 0, 0.12);
  border-radius: 8px;
}

.issue-detail:hover{
  background-color:#f5f1cb;
  border-radius:8px;
}
.issue-detail:hover .hrline {
  display:none;
}

.issue-detail:hover .issueName{
  font-weight:600;
  overflow:visible;
  -webkit-line-clamp: 10;
}
.issue-detail:hover .account-avatar{
  background-color:#2d2d2d !important;
}
</style>
  