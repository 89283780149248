<template>
    <v-card elevation="0" style="border-radius:10px">
        <v-form @submit.prevent="save" ref="form">
            <v-card-item>
                <v-card-title>
                    Hold
                    <v-btn style="float: right;" @click="close()" elevation="0" density="comfortable"
                        icon="mdi-close"></v-btn>
                </v-card-title>
            </v-card-item>


            <v-card-text>
                <v-row no-gutters>
                    <v-col cols="6">
                        <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Hold [E]
                            <span style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
                        <EnumAutoCompleteVue class="dropdown" density="compact" v-model="formData.hold"
                            :selected="formData.hold" :domain="'HOLD'" placeholder="Hold"
                            @changeEnumData="holdChange($event)" :rules="holdRule" />

                    </v-col>

                    <v-col cols="12">
                        <v-label class="pb-1"
                            style="font-size: 14px; color: #272727; font-weight: 600">Description</v-label>
                        <TextArea class="pb-0" editorStyle="height: 120px" :data="formData.description"
                            @textData="changeDescription($event)"></TextArea>
                    </v-col>
                </v-row>

            </v-card-text>
            <v-card-action class="mr-3">
                <v-row justify="end" class="dialogBottom">
                    <v-btn style=" --v-btn-height: 35px;" class="mr-2 cancel" @click="close()">Cancel</v-btn>
                    <v-btn style=" --v-btn-height: 35px;" :loading="saveClicked" class="save" type="submit">Save</v-btn>
                </v-row>
            </v-card-action>
        </v-form>
    </v-card>
</template>
      
<script>
import TextArea from "@/components/ui/TextArea.vue";
import IssueService from "@/api/service/Issue/IssueService";
import ForumService from "@/api/service/Forum/ForumService";
import EnumAutoCompleteVue from '@/components/others/EnumAutoComplete.vue';
export default {
    components: {
        TextArea,
        EnumAutoCompleteVue,
    },
    props: ['issueId'],
    data() {
        return {
            saveClicked:false,
            formData: {
                hold: null,
                description: null
            },
            holdRule: [(value) => !!value || "Hold is Required"],
        }
    },
    methods: {
        close() {
            this.$emit("closeDialog");
        },
        holdChange($event) {
            this.formData.hold = $event;
        },
        changeDescription($event) {
            this.formData.description = $event;
        },

        async save() {
            let validation = await this.$refs.form.validate()
            if (!validation.valid) {
                console.log(validation.valid)
            }
            else {
                if (this.issueId) {
                    this.saveClicked = true
                    await IssueService.update({
                        id: this.issueId,
                        hold_type: this.formData && this.formData.hold ? this.formData.hold : ''
                    });
                    let $description = this.formData && this.formData.description ? this.formData.description : ''
                    console.log($description , 'kya aarha hai')
                    let data = {
                        key_type: "issue",
                        key_id: this.issueId,
                        action: 'hold',
                        description: $description
                    };
                    await ForumService.create(data)
                    this.close();
                    this.formData = {};
                }
            }
        },
    }

}
</script>

<style></style>