import ApiService from '../../service/apiService.js';
const querystring = require('querystring')
var DocumentService = {

    findAll: function ($data) {
        return new Promise(function (resolve, reject) {
            let $options = {};
            $options.url = "documents"
            $options.method = "get"

            if ($data) {
                $options.url = "documents?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
                .then(response => {
                    console.log('test');
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error);
                })
        });

    },
    

    imageProfile: function ($data) {
        return new Promise(function (resolve, reject) {
            let $options = {};
            $options.url = "imageProfile"
            $options.method = "get"

            if ($data) {
                $options.url = "imageProfile?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
                .then(response => {
                    console.log('test');
                    resolve(response.data)
                }).catch(error => {
                    reject(error);
                })
        });

    },

    imageProfileAccount: function ($data) {
        return new Promise(function (resolve, reject) {
            let $options = {};
            $options.url = "imageProfileAccount"
            $options.method = "get"

            if ($data) {
                $options.url = "imageProfileAccount?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
                .then(response => {
                    console.log('test');
                    resolve(response.data)
                }).catch(error => {
                    reject(error);
                })
        });

    },
    getAllDocuments: function ($data) {
        return new Promise(function (resolve, reject) {
            let $options = {};
            $options.url = "getAllDocuments"
            $options.method = "get"

            if ($data) {
                $options.url = "getAllDocuments?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
                .then(response => {
                    console.log('test');
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error);
                })
        });

    },

    findOne: function ($data) {
        return new Promise(function (resolve, reject) {
            let $options = {};
            $options.url = "documents/" + $data
            console.log($options.url)
            $options.method = "get"
            if (!$data) {
                $options.data = $data
            }
            ApiService.request($options)
                .then(response => {
                    // console.log('Gpt the data');
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error);
                })
        });

    },
    update: function ($data) {
        return new Promise(function (resolve, reject) {
            let $options = {};
            $options.data = $data
            $options.url = "documents/" + $data.id;
            $options.method = "post"
            console.log($options)
            ApiService.request($options)
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                });
        })
    },
    save: function ($data) {
        return new Promise(function (resolve, reject) {
            var $options = {};
            $options.data = $data
            $options.url = "documents" + $data.id;
            $options.method = "post"
            console.log($options)
            ApiService.request($options)
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                });
        });
    },

    bulkUpdate: function ($data) {
        return new Promise(function (resolve, reject) {
            var $options = {};
            $options.data = $data
            $options.url = "bulkUpdate";
            $options.method = "post"
            console.log($options)
            ApiService.request($options)
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                });
        });
    },

    create: function ($data) {
        return new Promise(function (resolve, reject) {
            var $options = {};
            $options.data = $data
            $options.url = "documents";
            $options.method = "post"
            console.log($options)
            ApiService.request($options)
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                });
        });
    },
    upload($data) {
        return new Promise(function (resolve, reject) {
            var $options = {};
            $options.url = 'documentUpload'
            $options.method = 'post'
            $options.data = $data
            ApiService.request($options)
                .then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error);
                })
        });
    },
    
  
    download: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.responseType = "blob"
            $options.url = "documentDownload"
            $options.method = "get"

            if ($data) {
                $options.url = "documentDownload?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response)
            }).catch(error => {
                reject(error);
            })
        });

    },
    // download: function ($data) {
    //     return new Promise(function (resolve, reject) {
    //         var $options = {};
    //         $options.responseType = "blob"
    //         $options.url = "documentDownload/?" + querystring.stringify($data)
    //         $options.method = "get"
    //         ApiService.request($options)
    //             .then(response => {
    //                 resolve(response)
    //             }).catch(error => {
    //                 reject(error);
    //             })
    //     });

    // },

    getImage: function ($data) {
        return new Promise(function (resolve, reject) {
            var $options = {};
            // $options.responseType="blob"
            $options.url = "getImageFromDocument/?" + querystring.stringify($data)
            $options.method = "get"
            ApiService.request($options)
                .then(response => {

                    resolve(response.data)
                }).catch(error => {
                    reject(error);
                })
        });
    },
    getDocument: function ($file) {
        // Pass documentId and documentType in request body to get the file from the server
        return new Promise(function (resolve, reject) {
            var $options = {};
            $options.url = 'documents/get'
            $options.method = "post"
            $options.data = $file
            console.log($options, "working111111")
            ApiService.request($options)
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })

        });
    },
};
export default DocumentService;