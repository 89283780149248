<template>
    <v-card elevation="0" style="border-radius:10px">
        <v-form @submit.prevent="save" ref="form">
            <v-card-item>
                <v-card-title>
                    Clarification
                    <v-avatar size="x-small" color="primary">
                        {{ no_of_followups }}
                    </v-avatar>
                    <v-btn style="float: right;" @click="close()" elevation="0" density="comfortable"
                        icon="mdi-close"></v-btn>
                </v-card-title>
            </v-card-item>


            <v-card-text>
                <v-row no-gutters>
                    <v-col cols="12" class="px-0 py-0" style="display: grid">
                        <v-radio-group :disabled="showReadOnly" v-model="formData.followup_type" inline
                            @change="getUsers(formData.followup_type)">
                            <v-radio color="primary" label="Internal" :value="1"></v-radio>
                            <v-radio color="error" label="External" :value="0"></v-radio>
                        </v-radio-group>
                    </v-col>

                    <v-col cols="6" class="pr-1">
                        <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Clarification [E]
                            <span style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
                        <EnumAutoCompleteVue class="dropdown" density="compact" v-model="formData.clarification"
                            :selected="formData.clarification" :domain="'CLARIFICATION'" placeholder="Clarification"
                            @changeEnumData="changeClarification($event)" :rules="clarificationRule" />

                    </v-col>

                      <v-col cols="6" class="pr-1">
                        <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">
                            Pending With
                            <span style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
                        <AutoComplete class="dropdown" :items="users" :item-props="itemProps" item-value="id"
                            density="compact" v-model="formData.user" placeholder="User" :rules="userRule" />
                    </v-col>


                    <v-col cols="6" v-if="!switchMe">
                        <v-switch v-model="switchMe" color="primary">
                            <template v-slot:label>
                                Followup Date Required ?
                            </template>
                        </v-switch>
                    </v-col>

                    <v-col cols="6" class="pl-1" v-if="switchMe">
                        <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">FollowUp Date
                            <span v-if="!showReadOnly"
                                style="color: #cf0707; padding-left: 2px; font-weight: 600">*</span></v-label>
                        <TextFieldComponent density="compact" placeholder="FollowUp date" :rules="dateRule"
                            append-icon="mdi-close" @click:append="switchMe = !switchMe" v-model="formData.followup_date"
                            type="date" />
                    </v-col>

                    <v-col cols="12">
                        <v-label class="pb-1"
                            style="font-size: 14px; color: #272727; font-weight: 600">Description</v-label>
                        <TextArea class="pb-0" editorStyle="height: 120px" :data="formData.description"
                            @textData="changeDescription($event)"></TextArea>
                    </v-col>
                </v-row>

            </v-card-text>
            <v-card-action class="mr-3">
                <v-row justify="end" class="dialogBottom">
                    <v-btn style=" --v-btn-height: 35px;" class="mr-2 cancel" @click="close()">Cancel</v-btn>
                    <v-btn style=" --v-btn-height: 35px;" :loading="saveClicked" class="save" type="submit">Save</v-btn>
                </v-row>
            </v-card-action>
        </v-form>
    </v-card>
</template>
      
<script>
import AutoComplete from "@/components/ui/AutoComplete.vue";
import TextArea from "@/components/ui/TextArea.vue";
import IssueService from "@/api/service/Issue/IssueService";
import ForumService from "@/api/service/Forum/ForumService";
import EnumAutoCompleteVue from '@/components/others/EnumAutoComplete.vue';
import TextFieldComponent from "@/components/TextFieldComponent.vue";
import ContactService from '@/api/service/Contact/ContactService';
import UserService from '@/api/service/UserService';
export default {
    components: {
        TextArea,
        EnumAutoCompleteVue,
        AutoComplete,
        TextFieldComponent
    },
    props: ['issueId', 'followUpNo'],
    data() {
        return {
            saveClicked: false,
            switchMe: false,
            users: [],
            formData: {
                followup_type: null,
                user: null,
                followup_date: null,
                clarification: null,
                description: null
            },
            clarificationRule: [(value) => !!value || "Clarification is Required"],
            dateRule: [(value) => !!value || "Followup date is Required"],
        }
    },

    created() {
        this.getUsers()
    },
    methods: {
        async getUsers(type) {
            if (type == 1) {
                let res = await UserService.findAll()
                this.users = res && res.length > 0 ? res : []
            } else if (type == 0) {
                let res = await ContactService.findAll();
                this.users = res && res.length > 0 ? res : []
            } else {
                this.users = []
            }
        },

        itemProps(item) {
            return {
                title: item.first_name,
                subtitle: item.email,
            }
        },
        close() {
            this.$emit("closeDialog");
        },
        changeClarification($event) {
            this.formData.clarification = $event;
        },
        changeDescription($event) {
            this.formData.description = $event;
        },

        async save() {
            let validation = await this.$refs.form.validate()
            if (!validation.valid) {
                console.log(validation.valid)
            }
            else {
                if (this.issueId) {
                    this.saveClicked = true
                    await IssueService.update({
                        id: this.issueId,
                        followup_date: this.formData.followup_date,
                        followup_type: this.formData.followup_type,
                        followup_user: this.formData.user,
                        clarification_type: this.formData && this.formData.clarification ? this.formData.clarification : ''
                    });
                    let $description = this.formData && this.formData.description ? this.formData.description : ''
                    console.log($description, 'kya aarha hai')
                    let data = {
                        key_type: "issue",
                        key_id: this.issueId,
                        action: 'clarification',
                        description: $description
                    };
                    await ForumService.create(data)
                    this.close();
                    this.formData = {};
                }
            }
        },
    }

}
</script>

<style></style>