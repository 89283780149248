<template>
  <p>logout</p>
</template>
<script>
import UserService from '@/api/service/UserService'
import TimerService from '@/api/service/TimeSheet/TimerService.js'

export default {

  data() {
    return {
      showData: false
    }
  },
  async created() {
    let $timerData = JSON.parse(localStorage.getItem('timer'))
    if ($timerData) {
      let $time = {}
      await TimerService.processTime($time, $timerData.type, false)
    }

    let userDetails = localStorage.getItem("user_details")
    userDetails = JSON.parse(userDetails)

    UserService.update({
        id: userDetails.id,
        stage: 'logout',
      });
      
    let $time = {}
    await TimerService.processTime($time, 'logout', true)
    localStorage.removeItem("TOKEN");
    localStorage.removeItem("user_details");
    localStorage.removeItem("_WS_DEFAULTS_");
    localStorage.removeItem("workSpaceId")
    localStorage.removeItem("_WORK_TIMING_");
    this.$router.push('/login')
  }
}
</script>