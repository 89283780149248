<template>
  <div class="px-0 mx-0" style="width: fit-content">
    <v-avatar v-if="this.imgUrl" :size="aSize && aSize.size ? aSize.size : ''">
      <img style="width: 150%" :src="this.imgUrl" alt="John" />

      <v-tooltip activator="parent" location="start">
        <span class="avatarTip">{{
          this.fName && this.lName
            ? `${this.fName} ${this.lName}`
            : this.fName && !this.lName
            ? `${this.fName}`
            : ""
        }}</span>
      </v-tooltip>
    </v-avatar>


    <v-avatar
      v-else
      :style="{ 'background-color': this.theme ? this.theme : '#08929B' }"
      :class="type == 'account'? 'accountAvatar' :''"
      :size="aSize && aSize.size ? aSize.size : ''"
    >
      <span
      v-if="acCode"
        :style="{ 'font-size': aSize && aSize.font ? aSize.font : '' }"
        style="color: white;text-transform:uppercase"
      >
        {{this.acCode? `${this.acCode}` : ""}}
        </span>
      <span
      v-else
        :style="{ 'font-size': aSize && aSize.font ? aSize.font : '' }"
        style="color: white;text-transform:uppercase"
      >
        {{
          this.fName && this.lName
            ? `${this.fName[0]}${this.lName[0]}`
            : this.fName && !this.lName
            ? `${this.fName[0]}${this.fName[1]}`
            : ""
        }}
        </span>
      <v-tooltip activator="parent" 
      v-if="fName"
       location="start">
        <span class="avatarTip">{{
          this.fName && this.lName
            ? `${this.fName} ${this.lName}`
            : this.fName && !this.lName
            ? `${this.fName}`
            : ""
        }}</span>
      </v-tooltip>
    </v-avatar>
  </div>
</template>
<script>
import commonService from "@/common.service";
export default {
  props: ["size", "fName", "lName", "imgUrl", "theme", "type",'acCode','arounded'],
  data() {
    return {
      userData: null,
      url: null,
      aSize: null,
      aType:''
    };
  },
  watch: {
    type(){
        if(this.type){
            this.aType = this.type
        }
    }
  },
  created() {
    if (this.size) {
      this.getSize();
    }
  },
  methods: {
    getSize() {
      if (this.size == "xSmall") {
        this.aSize = commonService.xSmallAvatar();
      }
      if (this.size == "small") {
        this.aSize = commonService.smallAvatar();
      }
      if (this.size == "medium") {
        this.aSize = commonService.mediumAvatar();
      }
      if (this.size == "large") {
        this.aSize = commonService.largeAvatar();
      }
    },
  },
};
</script>
<style>
.avatarTip {
  text-transform: capitalize;
}
.accountAvatar{
  scale: .75;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border-radius: 8% !important;
}
</style>