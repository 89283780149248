import ApiService from '../../service/apiService.js';
const querystring = require('querystring')
//import CommonService from '../Common/CommonService.js';
var RolePermissionService = {
    copyPermission: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.data = $data
            $options.url = "copyPermissions"
            $options.method = "post"
            console.log($options)
            ApiService.request($options)
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                });
        })
    },
    findAll: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "rolepermissions"
            $options.method = "get"

            if ($data) {
                $options.url = "rolepermissions?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },
   
    findOne: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "rolepermissions/" + $data
            console.log($options.url)
            $options.method = "get"
            if (!$data) {
                $options.data = $data
            }
            ApiService.request($options)
                .then(response => {
                    // console.log('Gpt the data');
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error);
                })
        });

    },
    isAllowed:  function(activityCode,action) {
        return new Promise(function(resolve, reject) {
        let $options={}
        $options.method = "get"
        let $data = {activityCode}
        if(action) {
            $data =[...action]
        }
        $options.url = "isAllowed?" + querystring.stringify($data)
        ApiService.request($options)
        .then(response => {
            // console.log('Gpt the data');
            resolve(response.data.data)
        }).catch(error => {
            reject(error);
        })
        });
    },
    update: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.data = $data
            $options.url = "rolepermissions/" + $data.id;
            $options.method = "post"
            console.log($options)
            ApiService.request($options)
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                });
        })
    },
    save: function($data) {
        return new Promise(function(resolve, reject) {
            var $options = {};
            $options.data = $data
            $options.url = "rolepermissions" + $data.id;
            $options.method = "post"
            console.log($options)
            ApiService.request($options)
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                });
        });
    },

    create: function($data) {
        return new Promise(function(resolve, reject) {
            var $options = {};
            $options.data = $data
            $options.url = "rolepermissions";
            $options.method = "post"
            console.log($options)
            ApiService.request($options)
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                });
        });
    }
};
export default RolePermissionService;