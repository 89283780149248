<template>
    <v-card flat style="background-color: #e0f3fd53; border: 1px solid #e0f3fd" :class="halfScreen ? 'my-0' : 'my-3'">
        <v-card-title class="hoverEdit">
            <v-row class="toggle-button pl-0" @click="toggleCollapse">
                <v-card-title class="pl-2" style="font-size: 14px">
                    QA {{ type == "review" ? "Pending" : "Completed" }}</v-card-title>
            </v-row>
        </v-card-title>
        <!-- <v-divider /> -->
        <v-card-text class="my-0 pa-0" v-if="QAdata && QAdata.length > 0">
            <v-row no-gutters class="my-0 py-0" dense v-for="(value, index) in QAdata" :key="index">
                <v-col cols="2" class=" pl-0">
                    <CustomAvatar class=" ml-2" :userId="value.user_id" :size="'medium'" />
                </v-col>

                <v-col cols="7" class="pt-1  pr-0" v-if="type != 'review'">
                    <span class="name-condition" style="position: relative; bottom: 8px">
                        <span class="cardTitle">
                            {{ value.users.user_first_name }} {{ value.users.user_last_name }}
                            <br />
                            <span class="orange--text">
                                <small>{{
                                    moment(value.created).format("MM/DD/YYYY HH:mm")
                                }}</small>
                            </span>
                        </span>
                    </span>
                    <!-- <br /> -->
                </v-col>

                <v-col cols="7" class="" v-if="type == 'review'">
                    <span class="name-condition pt-1" style="position: relative; bottom: 8px">
                        <span class="cardTitle">
                            {{ value.users.user_first_name }} {{ value.users.user_last_name }}
                            <br />
                            <small>{{
                                moment(value.created).format("MM/DD/YYYY HH:mm")
                            }}</small>
                        </span>
                        <span v-if="value.pass == 'fail'" class="onText1">
                            {{ value.severity }}|{{ value.impact }}
                            <v-btn v-if="value.user_id == userLS.id && showEdit && exceptStage" @click="editQATask(value)"
                                variant="text" class="btn-color">
                                <img src="https://synergy-snaps.s3.ap-south-1.amazonaws.com/public/icons/edit.svg"
                                    style="width: 16px; height: 20px" />
                            </v-btn>
                        </span>
                    </span>
                </v-col>

                <v-col class="mt-2 chippp" cols="2" v-if="type != 'review'">
                    <v-chip class="chipp" :class="getChipClass(value.pass)" label v-on="on">
                        {{
                            value.pass === "1"
                            ? "Pass"
                            : value.pass === "0"
                                ? "Fail"
                                : "Review"
                        }}
                    </v-chip>
                </v-col>
                <v-col class="text-right chippp " cols="3" v-if="type == 'review' && allow">
                    <v-btn v-if="value.user_id == userLS.id" @click="editQATask(value)" variant="text" class="btn-color">
                        <img src="https://synergy-snaps.s3.ap-south-1.amazonaws.com/public/icons/edit.svg"
                            style="width: 16px;" />
                    </v-btn>
                </v-col>
                <v-col class="text-right chippp " cols="1" v-if="type != 'review'">
                    <v-btn @click="viewQATask(value)" variant="text" class="btn-color">
                        <img src="https://synergy-snaps.s3.ap-south-1.amazonaws.com/public/icons/Eye.svg"
                            style="width: 16px;" />
                    </v-btn>
                </v-col>

                <!-- <v-divider class="divider" v-if="QAdata.length > 1"></v-divider> -->
            </v-row>
        </v-card-text>

        <v-card-text v-else class="pa-0">
            <v-container class="text-center pt-0" justify-center>
                <v-icon style="font-size:100px;" class="">
                    <img :src="require('@/assets/no-data-found.svg')" width="56" height="56" />
                </v-icon>
                <br />
                <span class="text-body2 mt-2">
                    No QA task found for selected task
                </span>
            </v-container>
        </v-card-text>
    </v-card>
    <v-dialog v-model="showQaDialog" :width="700" persistent>
        <QaTask ref="QaTask" @closeDialog="closeQATask()" @reload="getQAdata()" :timeSheetDefaults="getTimeSheetDefaults()"
            :viewType="viewType" :id="QAId"></QaTask>
    </v-dialog>
</template>
  
<script>
import CustomAvatar from "@/components/ui/CustomAvatar.vue";
import moment from "moment-timezone";
import QaTask from "../issues/views/QaTask.vue";
import QaService from "@/api/service/Qa/QaService";
export default {
    props: ["issueId", "refresh", "issueData", "type", "halfScreen", "showEdit", "exceptStage", "allow"],
    components: {
        CustomAvatar,
        QaTask,
    },
    data() {
        return {
            userLS: [],
            QAdata: [],
            QAId: '',
            isCollapsed: false,
            showQaDialog: false,
            editData: {},
            viewType: false,
        };
    },
    updated() {
        this.getQAdata();
    },

    created() {
        this.getUserIdFromLS();
    },
    watch: {
        getQataskStatus() {
            if (this.getQataskStatus) {
                this.getQAdata();
            } else {
                this.getQAdata();
            }
        },

        getReload() {
            if (this.getReload) {
                this.getQAdata();
            }
        },

        refresh() {
            this.getQAdata();
            this.$emit("setRefreshFalse");
        },
    },
    computed: {
        getReload() {
            return this.$store.getters.getAssignee;
        }
    },

    getQataskStatus() {
        return this.$store.getters.getQaUpdate;
    },

    methods: {
        moment,
        qaClicked() {
            this.$emit("qaClicked");
        },
        getTimeSheetDefaults() {
            if (this.issueData.id) {
                return {
                    issueId: this.issueData.id,
                    refNum: this.issueData.ref_num,
                    name: this.issueData.name,
                    startDate: this.issueData.start_date,
                    accountId: this.issueData.account_id,
                    accountName:
                        this.issueData.accounts && this.issueData.accounts.name
                            ? this.issueData.accounts.name
                            : "",
                    projectId: this.issueData.project_id,
                    projectName:
                        this.issueData.projects && this.issueData.projects.name
                            ? this.issueData.projects.name
                            : "",
                    taskName:
                        this.issueData.tasks && this.issueData.tasks.name
                            ? this.issueData.tasks.name
                            : "",
                    taskId: this.issueData.task_id,
                    user_id: this.editData.user_id,
                    id: this.editData.id,
                    pass: this.editData.pass,
                    severity: this.editData.severity,
                    cause: this.editData.cause,
                    impact: this.editData.impact,
                    description: this.editData.description,
                    qa_user_id: this.editData.qa_user_id,
                };
            }
        },
        editQATask($value) {
            this.showQaDialog = true;
            this.editData = $value;
            this.viewType = false
        },
        viewQATask($value) {
            this.showQaDialog = true;
            this.editData = $value;
            this.QAId = this.editData.id
            this.viewType = true;
        },
        closeQATask() {
            this.showQaDialog = false;
            this.editData = {};
        },
        toggleCollapse() {
            this.isCollapsed = !this.isCollapsed;
        },
        async getUserIdFromLS() {
            this.userLS = JSON.parse(localStorage.getItem("user_details"));
        },

        async getQAdata() {
            if (this.issueId) {
                // alert("jiii")
                if (this.type == "review") {
                    let res = await QaService.getQaForIssue({ issueId: this.issueId });
                    this.QAdata = res;
                    console.log(this.QAdata, 'jdfbjsv')
                } else {
                    let res = await QaService.getQaForIssue({
                        issueId: this.issueId,
                        pass: [0, 1],
                    });
                    this.QAdata = res;
                }
            }
        },

        getAvatarName(value) {
            if (this.type != "review") {
                let fName =
                    value && value.qa_user.qa_first_name
                        ? value.qa_user.qa_first_name.charAt(0)
                        : "";
                let lName =
                    value && value.qa_user.qa_last_name
                        ? value.qa_user.qa_last_name.charAt(0)
                        : "";
                let name = fName + lName;
                return name;
            } else {
                let fName =
                    value && value.users.user_first_name
                        ? value.users.user_first_name.charAt(0)
                        : "";
                let lName =
                    value && value.users.user_last_name
                        ? value.users.user_last_name.charAt(0)
                        : "";
                let name = fName + lName;
                return name;
            }
        },

        getChipClass(pass) {
            if (pass === "1") {
                return "green-chip";
            } else if (pass === "0") {
                return "red-chip";
            } else if (pass === null) {
                return "yellowgreen-chip";
            } else {
                return "";
            }
        },
    },
};
</script>
  
<style scoped>
.toggle-button {
    cursor: pointer;
}

.toggle-button span {
    margin-right: 8px;
}

.toggle-button v-icon {
    transition: transform 0.3s;
}

.toggle-button:hover v-icon {
    transform: rotate(180deg);
}

.divider {
    border-top: 1px dotted #000000;
    opacity: 0.5;
}

.grey-bg {
    background-color: #f4f4f423;
}

/* .outlined {
    border: 1px solid #dfdfdf;
  } */

>>>.v-chip.v-chip--size-default {
    --v-chip-size: 0.875rem;
    --v-chip-height: 19px;
    font-size: 10px;
    padding: 0 9px;
}

.cardTitle {
    color: #000;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.5;
}

.bold-medium {
    font-weight: 600;
}

.onText {
    color: #636363;
    font-weight: 500;
    text-align: start;
    opacity: 1 !important;
}

.onText1 {
    font-size: 10px;
    padding: 0 !important;
    margin: 0 !important;
}

.green-chip {
    background-color: #9af5d5;
    color: #000000;
}

.ifNoQa {
    text-align: center;
}

.red-chip {
    background-color: #fcc4c4;
    color: #000000;
}

.yellowgreen-chip {
    background-color: #9ae0f5;
    color: #000000;
}

.name-condition {
    display: grid;
}

.hoverEdit {
    font-size: 14px;
}

.chippp {
    display: flex;
    justify-content: center;
}

.chipp {
    widows: 40px;
}
</style>