<template>
    <div v-if="showInput">
        <TextFieldComponent :close-on-content-click="false" id="search-menu-activators" class="mt-6 mr-2" :placeholder="'Subject,Refrence No'" ref="searchIssue"
            v-model="searchQuery"  append-inner-icon="mdi-magnify" @focus="fieldInFocus = true"
            @blur="searchQuery = ''; " @input="getSearch"  :loader="searchLoader" />
    </div>
    <v-menu :close-on-content-click="false" v-if="fieldInFocus" class="custom" style="top:-52px"
        activator="#search-menu-activators">
        <v-list class="notification custom-height">
            <v-card density="compact" flat min-width="100%" min-height="650" class="pa-0">
                <v-card-text density="compact" class="pa-0 ma-0 green">

                    <v-row no-gutters dense class="pa-2">
                        <!-- <v-col cols="12">
                            <TextFieldComponent autofocus ref="searchIssue" v-model="searchQuery"
                                placeholder="Search Issues" @input="getSearch()" class="pa-1" />
                        </v-col> -->
                        <v-col cols="12" class="pa-2 font-weight-black" color="grey">
                            {{ searchQuery && searchQuery.length > 0 ? "Searched Result" : "Recently created Tasks" }}
                        </v-col>
                        <v-col cols="12" class="py-1">
                            <v-list density="compact">
                                <v-list-item density="compact" v-for="item in issues" :key="item" class="list-item-bc"
                                    @click="openIssue(item)">
                                    <template v-slot:prepend>
                                        <!-- <CustomAvatar v-bind="props" class="mr-4" :userId="item.next_action"
                                            :size="'small'" /> -->  
                                            <CustomAvatar2 :size="'medium'" :fName="item.first_Name" :lName="item.last_name" :theme="item.theme" :imgUrl="item.n_image_url ? n_image_url: null"/>
                                    </template>
                                    <v-list-item-title> &nbsp;
                                        {{ item && item.reference_num ? item.reference_num : "" }} - {{ item && item.name
                                            ? item.name : '' }}
                                    </v-list-item-title>
                                    <template v-slot:append>
                                        <!-- <span   class="font"> {{ item && item.stage ? getStage(item.stage) : '' }}</span> -->
                                        <v-chip v-if="item.stage" class="onText3" label v-on="on"><b>{{ item.stage ?getStage(item.stage) : "" }}</b></v-chip>                                       
                                    </template>
                                </v-list-item>
                            </v-list>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-list>
    </v-menu>
</template>
  
<script>
import TextFieldComponent from '../TextFieldComponent.vue';
import IssueService from "@/api/service/Issue/IssueService";
import EnumService from "@/api/service/Enum/EnumService";
import CustomAvatar2 from './CustomAvatar2.vue';
//   import SynergyLogo from ""

export default {
    components: {
        TextFieldComponent,
        CustomAvatar2
        // SynergyLogo
    },

    data() {
        return {
            page:0,
            searchLoader:false,
            fieldInFocus: true,
            showInput: true,
            searchQuery: null,
            dialog: false,
            issues: [],
            elseIssue:[],
            backUpIssue: [],
            searchData: [],
            issueData: [],
            switchMe: false,
            tab: null,
            items: [{ title: "User Profile" }, { title: "Logout" }],
            text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
        }
    },
    updated() {
        this.focusInput();
    },

    watch: {
       
    },
    created() {
        this.getStageData()
        this.getIssueList('created')
    },

    methods: {
        isFocus() {
            console.log('Search Issue', this.$refs.searchIssue)
            return false;
            //return this.$refs.searchIssue.className.match('focus')
        },
        focusInput() {
            this.showInput = true
            // console.log('Ref', this.$refs.searchIssue.$el)
            // this.$refs.searchIssue.$el["input"].focus();
        },
        getSearch() {
            
            if (this.searchQuery.length > 0) {
                
                //console.log(this.searchQuery,"searchQuery")
               let $issues = this.searchFilter()
                if($issues.length==0) {
                    this.searchLoader=true
                   // alert('searching')
                // alert('no issues found')
                 this.getMoreIssues()
                 this.searchLoader=false

                } else {
                    this.issues = $issues
                }
                
            }
            else {

                this.issues = this.elseIssue
            }
        },
        searchFilter() {
            
            let searchStr = new RegExp(this.searchQuery, 'gi');
            return this.backUpIssue.filter(ele => {
                console.log(ele.name,"searchQuery111",ele.reference_num)
                if(ele && ele.name && ele.name.length>0 && ele.name.search(searchStr) > -1){
                    return ele
                }
                else if(ele && ele.reference_num && ele.reference_num.length>0 && ele.reference_num.search(searchStr) > -1){ 
                    return ele
                }

            })
        },
        async getMoreIssues() {
            let res = await IssueService.getOneUserIssue({"search":this.searchQuery,"page":this.page})
            this.issues = res //[...res]
            
           // alert('kore issues')
        },
        async getIssueList() {

            this.data = []
            try {
                // let accountId = localStorage.getItem("_ACCOUNT_ID_")
                // let projectId = localStorage.getItem("_PROJECT_ID_")
                let res = await IssueService.getOneUserIssue({"search":this.searchQuery,"page":this.page})
                this.elseIssue = res.slice(0,10)
                this.backUpIssue = [...res]
                this.issueData = JSON.parse(JSON.stringify(this.backUpIssue))
                this.issues = this.elseIssue
                console.log("11111111",this.issues)
                this.page = this.page+50;
                // console.log(response, 'jhhdddbdhdhcb')
            }
            catch (e) {
                console.log(e)
                this.backUpIssue = []
                this.issueData = []
                this.issues = []
            }
        },

        async getStageData() {
            try {
                let res = await EnumService.findAll({ domain: 'STAGE' })
                this.stageData = res.length > 0 ? res : []
            } catch (e) {
                this.stageData = []
            }
        },
        getStage(value) {
            let opt = this.stageData.filter(k => k.data_value == value)
            return opt && opt.length > 0 ? opt[0].display_value : ""
        },
        getAvatarName($value) {
            let fName = $value && $value.first_Name ? $value.first_Name[0] : ""
            let lName = $value && $value.last_name ? $value.last_name[0] : ""
            let name = fName + lName
            return name && name.length > 0 ? name : $value.user_name[0]

        },
        openIssue(data) {
            let obj ={
                            "workspace": {
                                "id": data.work_space_id,
                                "name": data.work_space_name
                            },
                            "project": {
                                "id": data.project_id,
                                "name": data.project_name
                            },
                            "account": {
                                "id": data.account_id,
                                "name": data.account_name
                            }
                        }
            localStorage.setItem('_WS_DEFAULTS_',JSON.stringify(obj))
            
            this.$store.dispatch('setIssueInView', data.id)
            // this.$router.push({ path: '/issueView2', query: { id: data.id, type: 'view' } });
            this.$nextTick(() => {
                this.fieldInFocus = false
            //    this.getIssueList()
                // this.$router.go(this.$router.currentRoute)
                // window.location.reload()
                //this.$router.go(0)

            })



        }

    },

    //     methods: {
    //         closeDialog() {
    //       this.$emit('close-dialog');
    //     },
    //     },
};
</script>
  
<style scoped>
.switch {
    padding-left: 44px;
    position: relative;
    bottom: 15px;
}

.font {
  font-size: 12px;
  color: #636363;
  font-weight: 500;
  text-align: start;
}

>>>.notification {
    height: 87vh !important;
    width: 684px;
    border-radius: 5px;
    box-shadow: 0px 0px 4px 0px;
    margin-top: 7px;
}

>>>.notification .v-overlay__content {
    width: 526px;
    height: 90vh;
    position: fixed;
    top: -35px;
    right: 2px;
    border-radius: 5px;
    box-shadow: 0px 0px 4px 0px;
}

>>>.switch {
    margin-top: 2px;
}

.bottomCard {
    height: 50px;
    width: 94%;
    border: 1px solid grey;
    position: absolute;
    top: 75vh;
    left: 15px;
    display: flex;
    align-items: center;
}

.message {
    display: grid;
    justify-content: center;
    padding: 50px 0px 44px;
}

>>>.v-tabs--density-default[data-v-97a9b204] {
    --v-tabs-height: 30px;
}

.onHover:hover {
    color: #3949ab;
}

.textC {
    color: white;
}

.onHover:focus {
    color: #3949ab;
}

.cardHover {
    border-radius: 6px;
}

.cardHover:hover {
    background: #16a9f623;
}

.button {
    color: #3949ab;
}

.button:hover {
    color: #3949ab;
}

.avat {
    width: 80px;
    height: 80px;
    position: fixed;
    top: 12px;
    left: 12px;
    border: 2px solid;
}

>>>.v-field__field .v-field__input {
    cursor: text;
    background-color: #ECECEC !important;
    font-size: 14px;
    color: #777777;
    border-radius: 8px !important;
    width: 100px;
    /*   transition: width 0.2s ease-in-out; */
}


>>>.v-field__field .v-field__input:focus-within {
    cursor: text;
    background-color: #fff !important;
    font-size: 14px;
    color: #000;
    border-radius: 8px !important;
    width: 650px;
    /*    transition-duration: 0.4s;
	-moz-transition-duration: 0.4s;
	-webkit-transition-duration: 0.4s;
	-o-transition-duration: 0.4s;*/
}
</style>
  