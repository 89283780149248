<template>
    <v-card flat class="pa-3" min-height="90vh">
        <v-card-title>
            Projects
        </v-card-title>
        <v-card-text>
            <v-row no-gutters dense class="rowheader pa-2">
                <v-col cols="5" class="text-left">
                    Name
                </v-col>
                <v-col cols="3" class="text-center">
                    Open Issues
                </v-col>
                <v-col cols="2" class="text-left">
                    Last Issue
                </v-col>
            </v-row>
            <div class="ifNoAccount" v-if="projects.length === 0">
            <h3 >No project available in selected WorkSpace</h3>
        </div>
            <v-row v-else dense v-for="project in projects" :key="project">
                <v-col cols="5" class="text-left ">
                    <span class="link" @click="getLink(project)">
                        <b>{{ project.code }}</b> - {{ project.name }}
                    </span>
                </v-col>
                <v-col cols="3" class="text-center">
                    <v-chip class="open-issues">
                        {{ project.no_of_issues }}
                    </v-chip>
                </v-col>
                <v-col cols="2" class="text-left date">
                    {{ project.last_updated ? moment(project.last_updated).format('MM/DD/YY') : '' }}

                    {{ project.last_updated ? moment(project.last_updated).fromNow() : '' }}
                </v-col>
                <v-divider />
            </v-row>
        </v-card-text>
    </v-card>
</template>
<script>
import WorkSpaceService from '@/api/service/WorkSpace/WorkSpaceService'
import moment from 'moment'
export default {
    components: {
    },
    data() {
       
        return {
            workSpaceId: 1,
            accountId: 1,
            workspace: {},
            projects: [],
            columns: [
                {
                    field: "code",
                    header: "Code",
                    style: { width: "100px", color: "" },
                    toolTip: "id",
                    sortfield: true,
                },
                {
                    field: "name",
                    header: "Name",
                    style: { width: "100px", color: "" },
                    toolTip: "name",
                    sortfield: true,
                },
                {
                    field: "Open",
                    header: "Open Issues",
                    style: { width: "100px", color: "" },
                    toolTip: "Open",
                    sortfield: true,
                },
                {
                    field: "updated",
                    header: "Last Updated",
                    style: { width: "100px", color: "" },
                    toolTip: "Last Updated",
                    sortfield: true,
                }
            ]
        }
    },
    watch: {
         getRefresh() {
            if (this.getRefresh) {
                // await this.getStart()
                this.getProjects()
            }
            else {

                // await this.getStart()
                this.getProjects()
            }
        }
    },
    computed: {
        getRefresh() {
            return this.$store.getters.getMyspace;
        }
    },
    async created() {
      
        this.getProjects()
    },
    mounted() {

    },
    methods: {
        moment,
       
        getLink($project) {
            let $wsDefaults = localStorage.getItem("_WS_DEFAULTS_")
            $wsDefaults = JSON.parse($wsDefaults)
            $wsDefaults.project = { "id": $project.id, "name": $project.name }
            localStorage.setItem("_WS_DEFAULTS_", JSON.stringify($wsDefaults))
            // localStorage.setItem("_WS_DEFAULTS_",JSON.stringify($wsDefaults))
            this.$store.commit("SET_REFRESH_BREADCRUMP", true)
            let url = localStorage.getItem('_WS_REDIRECT_')
            this.$router.push(url ? url : '/showAccountForWorkspaces')
        },
        getProjects() {
            
            this.$store.commit("SET_REFRESH_BREADCRUMP", false)
            let $wsDefaults = localStorage.getItem("_WS_DEFAULTS_")
            $wsDefaults = JSON.parse($wsDefaults)
            this.workSpaceId = $wsDefaults.workspace.id
           // this.accountId = $wsDefaults.account.id
           delete $wsDefaults.account
            delete $wsDefaults.project
            delete $wsDefaults.category
            delete $wsDefaults.issue
            localStorage.setItem("_WS_DEFAULTS_", JSON.stringify($wsDefaults))

            this.$store.commit("SET_REFRESH_BREADCRUMP", true)
            WorkSpaceService.getProjects({ workSpaceId: this.workSpaceId })
                .then($projects => {
                    this.workspace = $projects.workspace
                    this.projects = $projects.projects
                }).catch(error => {
                    console.log(error)
                })
        }
    }
}
</script>
<style>
.rowheader {
    background-color: #D7EAF4;
    font-weight: 600;
}

.link {
    color: #000;
    text-decoration: none;
}

.link:hover {
    color: #000;
    text-decoration: underline;
    cursor: pointer;
}

.open-issues {
    background-color: #E2D6F8;
    color: #272727;
    font-weight: 10px;
    font-weight: 400;
}

.date {
    color: orange;
}</style>