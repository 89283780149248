import ApiService from '../../service/apiService.js';
const querystring = require('querystring')
var InsightService = {

    insights: function ($data) {
        return new Promise(function (resolve, reject) {
            let $options = {};
            $options.url = "insights"
            $options.method = "get"

            if ($data) {
                $options.url = "insights?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
                .then(response => {
                    console.log('test');
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error);
                })
        });
    },
        getInsightByCategory: function ($data) {
            return new Promise(function (resolve, reject) {
                let $options = {};
                $options.url = "insightsByCategory"
                $options.method = "get"
    
                if ($data) {
                    $options.url = "insightsByCategory?" + querystring.stringify($data)
                    console.log($options.url)
                }
                ApiService.request($options)
                    .then(response => {
                        console.log('test');
                        resolve(response.data.data)
                    }).catch(error => {
                        reject(error);
                    })
            });
    }
};
export default InsightService;