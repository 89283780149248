<template>
  <v-card elevation="0" v-if="showHomeTimer" class="px-4 py-2"
    style="height: fit-content;border: 2px solid #ADADAD;border-style: groove;">
    <v-card-title v-if="showHomeTimer" class="cardTitle pa-0"> Timer </v-card-title>
    <div class="mainDiv">
      <div style="display: flex">
        <div style="float: left; width: 12%; display: flex; align-items: center">
          <CustomAvatar2 class="ma-0 mb-2" :size="'medium'" :fName="issueData.first_Name" :lName="issueData.last_name"
            :imgUrl="issueData.n_image_profile_id ? issueData.n_image_profile_id : null" :theme="issueData.theme">
          </CustomAvatar2>
        </div>

        <div class="childOfChild">
          <div>
            <div class="issueReff">{{ issueData.reference_num }}</div>
            <div class="issueReffCont">{{ issueData.name }}</div>
          </div>

          <div>

            <v-icon class="show-cursor" size="medium" @click="atClick(issueData)">mdi-page-next-outline</v-icon>
          </div>
        </div>
      </div>

      <div class="d-flex justify-center">
        <!-- <div class="childOfChild1">
          <v-card-text class="pa-0" v-html="issueData && issueData.description
            ? issueData.description
            : ''
            " />
          <v-icon v-if="issueData.description && issueData.description.length > 150" class="show-cursor" size="25"
            style="position: sticky; top: 1px;" @click="openDialog">mdi-unfold-more-horizontal</v-icon>
        </div> -->

        <div class="childOfChild2 d-flex" @click="timerClicked('home')">
          <span class="timerInCard">{{ getTimerCC }}</span>
          <!-- <span> -->
            <v-img style="width: 24px; height: 24px" :src="require('@/assets/image/pause_circle_filled.svg')"></v-img>
          <!-- </span> -->
        </div>
      </div>

      <div style="display: flex; justify-content: space-between">
        <div>
          <span class="estimateHead">Estimated</span>
          <br />
          <span class="estimateCont">
            {{
            getHourMinutes(issueData.estimate) 
            }}
          </span>
        </div>

        <div>
          <span class="estimateHead">Submitted On</span>
          <br />
          <span class="estimateCont">{{
            moment(issueData.submitted_date).format("DD MMMM YYYY")
          }}</span>
        </div>

     <!--    <div>
          <span class="estimateHead">Total Hours </span>
          <br />
          <span class="estimateCont">
            {{
            getHourMinutes(issueData.estimate) 
            }}
            
          </span>
        </div> -->

        <div>
          <span class="estimateHead">Issue Type</span>
          <br />
          <span class="estimateCont title-case">{{
            issueData.issue_type_name
          }}</span>
        </div>
      </div>
    </div>
  </v-card>
  <v-card class="dialogCard" v-if="showCard && !showHomeTimer">
    <v-card-item style="text-align: center">
      <span class="titleMain">Your {{ title.charAt(0).toUpperCase() + title.slice(1) }} has
        started</span>

        <span v-if="title.toLowerCase() == 'meeting'" @click="showRemarks = !showRemarks" style="position: absolute;top: 0;right: 10px;" justify="center">
        <i class="pi pi-pencil" style="font-size: .7rem;font-weight: 600">
          <v-tooltip
        activator="parent"
        location="end"
      >click on me to add notes</v-tooltip>
        </i>
      </span>
    </v-card-item>
    <v-card-text class="mt-4" style="display: flex; justify-content: center">
      <div class="insideCard">
        <div class="my-4">
          <v-img v-if="title.toLowerCase() == 'meeting'" style="display: flex; width: 36px; height: 36px; margin: auto"
            :src="require('@/assets/command.svg')">
          </v-img>
          <v-img v-if="title.toLowerCase() == 'break'" style="width: 24px; height: 24px; margin: auto"
            :src="require('@/assets/image/cupcake.svg')">
          </v-img>
        </div>
        <div class="mb-4" style="display: flex; justify-content: center">
          <span class="timerC">
            {{ getTimerCC }}
          </span>
        </div>
      </div>
    </v-card-text>

    <v-card-actions class="mt-3" style="display: flex; justify-content: center">
      <v-btn>
        <v-img @click="closeDialog()" style="display: flex; width: 36px; height: 36px; margin: auto"
          :src="require('@/assets/image/pause_circle_filled.svg')">
        </v-img>
      </v-btn>
     
    </v-card-actions>

    <div align="center">
        <div class="px-3">
          <v-textarea v-if="showRemarks" rows="1" label="Remarks" variant="outlined"
          v-model="meetingRemarks"
          @change="updateRemarks"
          ></v-textarea>
        </div>
      </div>
    <div align="center">
      <div class="mainNote" justify="center" @click="minimizeTimer()">
        Click here to contine your work
      </div>
      <div class="instruction my-3 mx-15 mb-7">
        The timer will continue to clock until you stop this. You can find it in
        the top left corner of the toolbar
      </div>
    </div>
  </v-card>
  <v-chip v-if="!showCard && !showHomeTimer" label @click="timerClicked" :class="timer && timer.seconds > 0 ? type : ''"
    variant="text" :style="{ color: color ? color : '#272727' }">
    <span>
      <v-icon v-if="isOkToShowLink" @click.stop="openIssue" size="small"  class="mdi mdi-eye" />
      {{ getTimerCC }}
    </span>
    <v-icon v-if="((timer && timer.seconds == 0) || !timer) && showPlay">
      mdi-play
    </v-icon>
    <v-icon v-if="timer && timer.seconds > 0"> mdi-pause </v-icon>
  </v-chip>

  <v-dialog v-model="dialogOpen" class="dialogOpen" width="55%" max-width="55%">
    <v-card class="descriptionCard">
      <v-row>
        <v-col cols="4">
          <v-card-title>Description</v-card-title>
        </v-col>
        <v-col class="descBtn">
          <v-btn @click="closeDialog1()" elevation="0" density="comfortable" icon="mdi-close" class="x-closeIcon"></v-btn>
        </v-col>
      </v-row>
      <v-card-text v-html="issueData && issueData.description
        ? issueData.description
        : ''
        " />
    </v-card>
  </v-dialog>
</template>
<script>
import { useStopwatch } from "vue-timer-hook";
import moment from "moment";
import lodash from "lodash";
import UserService from "@/api/service/UserService";
import IssueService from "@/api/service/Issue/IssueService";
import TimeSheetService from "@/api/service/TimeSheet/TimeSheetService";
import TimerService from "@/api/service/TimeSheet/TimerService";
import CustomAvatar2 from "@/components/ui/CustomAvatar2.vue";
export default {
  props: [
    "color",
    "title",
    "showHomeCard",
    "timerInProgress",
    "taskType",
    "showPlay",
    "keyId",
    "hideCard",
  ],
  components: {
    CustomAvatar2,
  },
  data() {
    return {
      showLink:false,
      timer: null,
      type: "issue",
      showTimer: true,
      showRemarks: false,
      showCard: false,
      showHomeTimer: false,
      issueData: {},
      dialogOpen: false,
      timerData:{},
      meetingRemarks:''
    };
  },
  computed: {
    isOkToShowLink() {
      if(this.$store.getters.getIssueViewDrawer) {
        return false
      } else if(this.$route.path=="/")  {
        return false
      }
      else if(!this.timerData.issueId) {
       return false
      } else {
        return true
      }
    },
    getTimerCC() {
      if (!this.timer || this.timer.seconds == 0) {
        return "";
      } else {
        return this.timer
          ?
          this.timer.hours +
          ":" +
          this.timer.minutes +
          ":" +
          this.timer.seconds
          : "";
      }
    },
    getStageStatus() {
      return this.$store.getters.getStageInProgress;
    },

    reloadTheData() {
      return this.$store.getters.getReloadTheIssue;
    },
  },
  watch: {
    getStageStatus() {
      if (this.getStageStatus) {
        this.timerClicked()
      }
    },
    getQataskStatus() {
      if (this.getQataskStatus) {
        this.getQAdata();
      } else {
        this.getQAdata();
      }
    },

    timerInProgress() {
      this.getTimer(this.timerInProgress);
    },
    showHomeCard() {
      this.showHomeTimer = this.showHomeCard;
      if (this.showHomeCard) {
        this.getIssueData();
      }
    },
    hideCard() {
      this.showCard = this.hideCard;
    },
    startStopWatch() {
      if (this.startStopWatch) {
        this.startTimer();
      }
    },
    showTimer() {
      if (this.showTimer) {
        this.getTimer(true);
      }
    },
    hideTimer() {
      if (this.hideTimer) {
        this.getTimer(false);
      }
    },
    stopStopWatch() {
      if (this.stopStopWatch) {
        this.stopTimer();
      }
    },
  },
  created() {
    this.showCard = this.hideCard;
    let timerData = localStorage.getItem("timer");
      this.timerData = timerData ? JSON.parse(timerData) : {}
    this.getTimer(false);
    if (this.showHomeCard) {
      this.showHomeTimer = this.showHomeCard;
      this.getIssueData();
    }
    if (this.$route.query.id) {
      let timerData = localStorage.getItem("timer");
      

      this.timerData = JSON.parse(timerData)
      console.log(timerData, "timerData Dataaaaa")
      if (!timerData) {
        this.showIssueTimeView = true
      }
      else if (timerData && timerData.type && timerData.type == 'issue') {
        this.showIssueTimeView = this.$route.query.id == timerData.issueId ? true : false
      }
      else {
        this.showIssueTimeView = true
      }
    }

    //this.getTimer(true);
  },
  methods: {
    moment,
    async updateRemarks() {
      await TimeSheetService.updateMeetingRemarks({description:this.meetingRemarks})
    },
    openIssue() {
      let timerData = JSON.parse(localStorage.getItem("timer"));
      if(timerData.issueId) {
        this.$store.dispatch("setIssueInView", timerData.issueId);
      }
    },
    getHourMinutes($minutes) {
      if($minutes>59) {
        return `${Math.floor($minutes/60)} Hr  ${$minutes%60} mins`
      } else {
        return `${$minutes} mins`
      }
    },
    openDialog() {
      this.dialogOpen = true;
    },
    closeDialog1() {
      this.dialogOpen = false;
    },
    atClick(issueData) {
      let selectedId = issueData.id;
      // this.$router.push({ path: "/issueView2", query: { id: selectedId } });
      this.$store.dispatch('setIssueInView', selectedId)
    },
    async getIssueData() {
      let timerData = JSON.parse(localStorage.getItem("timer"));
      if (timerData && timerData.type == "issue" && timerData.issueId) {
        let res = await IssueService.getIssueData({
          issue_id: timerData.issueId,
        });
        this.issueData =
          res.length > 0 && res[0].ds_issue_list ? res[0].ds_issue_list : {};
        console.log(this.issueData, "checking for details");
      } else {
        this.showHomeTimer = false;
      }
    },
    async timerClicked(type) {
      let data = await IssueService.findOne(this.$route.query.id);
      if (data && data.length > 0 && !data[0].start_date) {
        let obj = {
          id: data[0].id,
          start_date: new Date(),
          stage: 'inprogress'
        };
        await IssueService.update(obj);
        this.getIssueData();
      }
      let status = this.$store.getters.getRefreshTimeSheet;
      if (type && type == "home") {
        this.$emit("workInProgress", true);
        this.$store.commit("SET_REFRESH_TIME_SHEET", !status);
      }
      if ((this.timer && this.timer.seconds == 0) || !this.timer) {
        this.createTimeSheet();
        this.$store.commit("SET_REFRESH_TIME_SHEET", !status);
      } else {
        this.$store.commit("SET_REFRESH_TIME_SHEET", !status);
        let timerData = JSON.parse(localStorage.getItem("timer"));
        if (timerData && timerData.type == "issue") {
          this.showHomeTimer = false;
          this.$store.commit("SET_REFRESH_TIME_SHEET", !status);
          this.updateTimeSheet();
          this.stopTimer()
        } else {
          let timerData = JSON.parse(localStorage.getItem("timer"));
          this.$store.commit("SET_HIDE_TIMER", false);
          this.$emit("closeDialog1", timerData);
          this.stopTimer();
        }
      }
    },
    closeDialog() {
      let timerData = JSON.parse(localStorage.getItem("timer"));
      timerData.description = this.meetingRemarks
      this.$emit("closeDialog1", timerData);
      this.stopTimer();
    },
    minimizeTimer() {
      this.$emit("closeTimerDialog");
    },
    async createTimeSheet() {
      this.startTimer();
      let userData = JSON.parse(localStorage.getItem("user_details"));
      let data = {};
      let res = await IssueService.findOne(this.keyId);
      data.company_id = userData.company_id;
      data.customer_id = res[0] && res[0].account_id ? res[0].account_id : '';
      data.description = res[0].name;
      data.issue_id = res[0].id;
      data.project_id = res[0].project_id;
      data.task_id = res[0].task_id;
      data.resource_id = userData.id;
      data.stage = "pending";
      await TimerService.processTime(data, "issue", true);
      let status2 = this.$store.getters.getReloadTheIssue;
      this.$store.commit("RELOAD_THE_ISSUE", !status2);

      //await TimeSheetService.create(data);
    },
    async updateTimeSheet() {
      let data = {};
      await TimerService.processTime(data, "issue", false);
      this.stopTimer();
    },
    async updateTimeSheet3() {
      let $timer = JSON.parse(localStorage.getItem("timer"));
      let res = [];
      if ($timer.issueId) {
        res = await TimeSheetService.findAll({
          issue_id: $timer.issueId,
          stage: "pending",
        });
      } else {
        res = await TimeSheetService.findAll({
          task_id: $timer.taskId,
          stage: "pending",
        });
      }
      res = lodash.orderBy(res, ["id"], ["desc"]);
      // alert('Updating time shet')
      let timesheetData = res[0];
      let data = {};
      data.id = timesheetData.id;
      data.end_date = moment(new Date())
        .utcOffset("+05:30")
        .format("YYYY/MM/DD HH:mm:ss");
      let end_date = moment(new Date()).tz("utc");
      let start_date = moment(timesheetData.start_date, true).tz(
        "Asia/Kolkata"
      );
      var hours = end_date.diff(start_date, "seconds") / 60;
      data.stage = "completed";
      data.actual_hrs = hours ? parseFloat(hours.toFixed(2)) : 0;
      // alert(data.stage)
      await TimeSheetService.update(data);
      this.stopTimer();
    },
    getTimer(value) {
      this.type = this.taskType ? this.taskType : "";
      let $timer = JSON.parse(localStorage.getItem("timer"));
      if ($timer) {
        let startTime = JSON.parse($timer.start);
        let endTime = moment(new Date());
        var hours = endTime.diff(startTime, "seconds");
        console.log(hours, "starttimehours", endTime, "start", startTime);
        this.timer = useStopwatch(hours, true);
        this.type = $timer.type;
      } else if (value) {
        this.createTimeSheet();
      } else {
        /* else {
                 let timerStart = JSON.parse(localStorage.getItem("breakormeetingTimer"))
                 if (timerStart) {
                     let endTime = moment(new Date());
                     var hours1 = endTime.diff(timerStart, 'seconds')
                     console.log(hours1, 'starttimehours', endTime, 'start', timerStart)
                     this.timer = useStopwatch(hours1, true);
                 }
             }*/
        // alert('check')
        this.timer = useStopwatch(0, true);
        this.timer.pause();
        // localStorage.removeItem('stopWatchTimer')
        // localStorage.removeItem('stopWatchIssueId')
        // this.$store.commit('SET_IN_PROGRESS', false)
      }
    },
    checkTimer() {
      let timerData = JSON.parse(localStorage.getItem("breakormeeting"));
      if (timerData) {
        this.$emit("openTimer");
      }
    },
    startTimer() {
      this.timer = useStopwatch(true);
      this.timer.start();
      let timerData = JSON.parse(localStorage.getItem("timer"));
      if (!timerData) {
        let $start = moment(new Date());
        let $timer = {
          type: this.taskType,
          issueId: this.keyId,
          start: JSON.stringify($start),
        };
        localStorage.setItem("timer", JSON.stringify($timer));
      } else {
        if (timerData && timerData.type == "issue") {
          this.showHomeTimer = true;
        } else {
          this.showHomeTimer = false;
        }
      }
      this.$store.commit("SET_IN_PROGRESS", true);
    },
    async stopTimer() {
      // alert(this.issueData.id)
      let status = this.$store.getters.getPending;
      let timerData = localStorage.getItem("timer");
      timerData = timerData ? JSON.parse(timerData) : {}
      // alert(status)
      if (timerData && timerData.type == 'issue' && timerData.issueId) {
        // alert(status)
        await IssueService.update({
          id: timerData.issueId,
          stage: 'pending',
        });
        let status2 = this.$store.getters.getReloadTheIssue;
        this.$store.commit("RELOAD_THE_ISSUE", !status2);
        this.$store.commit("SET_REFRESH_TIME_SHEET", true);

      }
      this.timer = useStopwatch(0, true);
      this.timer.pause();
      localStorage.removeItem("timer");
      //localStorage.removeItem('stopWatchTimer')
      //localStorage.removeItem('stopWatchIssueId')
      this.$store.commit("SET_IN_PROGRESS", false);
      this.$store.commit("SET_TO_PENDING", !status);

      let userDetails = localStorage.getItem("user_details")
      userDetails = JSON.parse(userDetails)

      UserService.update({
        id: userDetails.id,
        stage: 'login',
      });
      let tempTimer =localStorage.getItem("timer")
      this.timerData =tempTimer?JSON.parse(localStorage.getItem("timer")):{}
      
    },
  },
};
</script>
<style scoped>
.mainDiv {
  display: flex;
  flex-flow: column;
  /* justify-content: space-between; */

  gap: 12px;
  height: 100%;
}

.cardTitle {
  color: #272727;
  font-size: 18px;
  font-weight: 700;
}

>>>p {
  font-size: 16px !important;
  font-weight: 400 !important;
  color: #272727 !important;
}

.issueReff {
  color: #272727;
  font-size: 12px;
  font-weight: 400;
}

.issueReffCont {
  color: #272727;
  font-size: 14px;
  font-weight: 500;
}

.childOfChild {
  display: flex;
  justify-content: space-between;
  float: left;
  width: 88%;
}

.childOfChild1 {
  overflow: hidden;
  border-radius: 12px;
  border: 1px solid #eef6fb;
  background: #eef6fb;
  display: flex;
  text-align: center;
  padding: 5px;
  height: 83px;
  min-width: 68%;
}

.childOfChild2 {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  border-radius: 12px;
  border: 1px solid #eef6fb;
  background: #eef6fb;
  padding: 5px 15px;
}

.gChildOfChild {
  color: #000;
  font-size: 12px;
  font-weight: 400;
}

.timerInCard {
  color: #272727;
  font-size: 20px;
  font-weight: 600;
}

.estimateHead {
  color: #636363;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
}

.estimateCont {
  color: #272727;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
}

.issue {
  background-color: #ffe9d0;
  color: #272727;
}

.break {
  background-color: rgb(255, 241, 233) !important;
  color: #272727;
  border: 2px solid rgba(156, 59, 3, 0.5);
}

.meeting {
  background-color: rgba(226, 252, 233);
  color: #272727;
  border: 2px solid rgba(20, 153, 56, 0.5);
}

.meetingBtn {
  border: 2px solid #3c5aaa;
  color: #3c5aaa;
  width: 35%;
}

.titleMain {
  color: #272727;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.dialogCard {
  border-radius: 12px !important;
  border: 2px solid #e3e3e3;
  background: #fff;
}

.insideCard {
  border-radius: 12px;
  border: 1px solid #c1e8fc;
  background: #d7eaf4;
  width: 143px;
}

.writeNote {
  color: #01060e;
  /* font-size: 13px; */
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}
.mainNote {
  color: #1258ab;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
}

.instruction {
  color: #000;
  text-align: center;
  font-size: 10px;
  font-weight: 300;
}

.timerC {
  color: #272727;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}</style>