<template>
  <v-card width="100%" style="border-radius: 8px; overflow: auto">
    <v-form @submit.prevent="save" ref="form" v-model="value">

      <v-card-item>
        <v-card-title>
          {{
            viewType == "edit" ? "Edit" : viewType == "view" ? "View" : "Add"
          }}
          <b> UserScoreCard</b>
          <v-btn @click="close()" elevation="0" density="comfortable" icon="mdi-close" class="x-closeIcon"></v-btn>
        </v-card-title>
      </v-card-item>

      <v-card-text class="pr-7">
        <v-row no-gutters v-for="item in inputData" :key="item.id">

          <v-col cols="12" class="px-0 py-0" style="display: grid">
            <v-radio-group :disabled="showReadOnly" v-model="formData.active" inline>
              <v-radio color="primary" label="Active" :value="1"></v-radio>
              <v-radio color="error" label="Inactive" :value="0"></v-radio>
            </v-radio-group>
          </v-col>

          <v-col cols="6">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">{{ item.name }}</v-label>
            <TextFieldComponent v-if="item.field_type === 'textfield'" :placeholder="item.name" :disabled="showReadOnly"
              v-model="formData[item.name]" />
            <TextFieldComponent v-if="item.field_type === 'datepicker'" type="datetime-local" showtime hoursFormat="24"
              :placeholder="item.name" :disabled="showReadOnly" v-model="formData[item.name]" />
            <TextFieldComponent v-if="item.field_type === 'date'" type="date" :placeholder="item.name"
              :disabled="showReadOnly" v-model="formData[item.name]" />

            <v-select v-if="item.field_type === 'select'" density="compact" variant="outlined"
              v-model="formData[item.name]" :items="item.source_type" :item-title="'name'" :item-value="'id'"
              :placeholder="item.name"></v-select>

            <AutoComplete v-if="item.field_type === 'autocomplete'" v-model="formData[item.name]"
              :items="item.source_type" :item-title="'name'" :item-value="'id'" :placeholder="item.name" />

            <CheckBox v-model="formData[item.name]" v-if="item.field_type === 'checkbox'" :placeholder="item.name"
              :color="'primary'" />

            <v-textarea v-if="item.field_type === 'textarea'" v-model="formData[item.name]"
              :maxlength="maxlength ? maxlength : 120" single-line density="compact" variant="outlined"
              class="topsearch"></v-textarea>

            <v-switch class="Switch" color="primary" v-model="formData[item.name]" v-if="item.field_type === 'switch'"
              :placeholder="item.name"></v-switch>

            <div v-if="item.field_type === 'radiobutton'">
              <RadioButton v-for="(option, optionIndex) in item.options" :key="optionIndex" v-model="formData[item.name]"
                :value="option.id" :label="option.name" :color="'primary'" />
            </div>
          </v-col>

          <v-col cols="6" class="pl-6" style="display: flex;align-items: center;">
            <v-rating class="pt-1" v-model="item.score" density="compact"></v-rating>
          </v-col>

        </v-row>
      </v-card-text>

      <!-- <v-divider class="mb-4"></v-divider> -->
      <v-card-action class="mr-3">
        <v-row justify="end" class="dialogBottom" v-if="!showReadOnly">
          <v-btn class="mr-2 cancel" @click="dialog = close()">Cancel</v-btn>
          <v-btn class="save" :loading="saveClicked" type="submit">Save</v-btn>
        </v-row>
      </v-card-action>

    </v-form>
  </v-card>
</template>
<script>
import TextFieldComponent from "@/components/TextFieldComponent.vue";
import AutoComplete from "@/components/ui/AutoComplete.vue";
import RadioButton from "frontendcomponent/src/ui/RadioButton.vue";
import CheckBox from "frontendcomponent/src/ui/CheckBox.vue";
import ScoreCardService from "@/api/service/ScoreCard/ScoreCardService";
import ScoreCardTemplateService from "@/api/service/ScoreCardTemplate/ScoreCardTemplateService";
import UserScoreCardService from "@/api/service/UserScoreCards/UserScoreCardsService";

export default {
  props: ["data", "viewType"],
  components: {
    TextFieldComponent,
    AutoComplete,
    RadioButton,
    CheckBox
  },

  data() {
    return {
      showReadOnly: false,
      saveClicked: false,
      dialog: false,
      type: null,
      inputData: [],
      templateData:null,
      // rating: 3,
      formData: {
        score_id: null,
        user_id: null,
        kra_review_id: null,
        score: null,
        review_date: null,
        fy: null,
        reviewer_id: null,
        assigned_id: null
      },
    };
  },

  watch: {
    id() {
      if (this.id) {
        this.formData = {};
        this.getById(this.id);
      }
    },
  },
  created() {
    this.getScoreCards()
    if (this.id) {
      this.getById(this.id);
    }
    if (this.viewType) {
      this.showReadOnly = this.viewType == "edit" ? false : true;
    }
  },
  methods: {
    close() {
      this.$emit("closeDialog");
    },

    async getScoreCards() {
      let tempId = this.data.template_id
      
      let response = await ScoreCardTemplateService.findOne({ id: tempId });
      let tempData = response[0];
      this.templateData =response && response.length>0?response[0]:null

      let tempSdata = JSON.parse(tempData.score_ids)
      const ids = tempSdata.filter(item => item.status === true).map(item => item.id);


      let res = await ScoreCardService.findAll();
      this.inputData = res.filter(item => ids.includes(item.id));
      console.log(this.inputData, 'kasssshhhhhhhhhhh')
    },



    async getById(id) {
      console.log(id)
      let res = await UserScoreCardService.findOne(id);
      this.formData = res[0];
    },

    async save() {
      this.saveClicked = true
      for(let key of this.inputData){
        let obj={
          score_id:key.id,
          user_id:this.data.user_id,
          reviewer_id:this.data.reviewer_id,
          kra_review_id:this.data.id,
          fy:this.data.fy,
          assigned_id:this.data.assigned_by,
          review_date:this.data.review_date,
          score:key.score
          
        }
        await UserScoreCardService.create(obj);

        console.log(obj,"balaaaaaaaaaa")
      }
      this.dialog = false;
        this.close();
        this.$emit("reload", true);
        this.formData = {};

      
      // this.formData.kra_review_id = this.data.id
      // this.formData.user_id = this.data.user_id
      // this.formData.fy = this.data.fy
      // this.formData.review_date = this.data.review_date
      // this.formData.reviewer_id = this.data.reviewer_id 
      // this.formData.assigned_id = this.data.assigned_id 
      // if (this.formData.id) {
      //   await UserScoreCardService.update(this.formData);
      //   this.dialog = false;
      //   this.close();
      //   this.$emit("reload", true);
      //   this.formData = {};
      // } else {
      //   await UserScoreCardService.create(this.formData);
     
      // }

    },
  },
};
</script>
  