import ProcessLead from '@/modules/dashboard/ProcessLead/ProcessIndex.vue'
// import Dashboard from '../modules/dashboard/ResourceUtilizationBoard/DashBoard.vue'
// import UnAssignedIssues from '@/modules/dashboard/ProcessLead/UnAssignedIssues.vue'
// import AllWorkSpaceUser from '@/modules/dashboard/ProcessLead/AllWorkSpaceUser.vue'
// import UserByshifts from '@/modules/dashboard/ProcessLead/UserByShifts.vue'
export default [
    {  
        
        path: 'processLead',
        component: ProcessLead, 
    },
    // {  
        
    //     path: 'dashboard',
    //     component: Dashboard, 
    // },
    // {  
        
    //     path: 'unassigned',
    //     component: UnAssignedIssues, 
    // },
    // {  
        
    //     path: 'allworkspace',
    //     component: AllWorkSpaceUser, 
    // },
    // {  
        
    //     path: 'userbyshifts',
    //     component: UserByshifts, 
    // },
    
]
    