<template>
    <v-dialog persistent v-model="showDialogForUser" max-width="400px" max-height="600px">
    <v-card>
        <v-card-title>
            <v-row no-gutters>
                <v-col>
                    Impersonate User
                </v-col>
                <v-col class="text-right">
                    <v-icon  @click="closeDialog" class="text-xs-right">mdi mdi-close</v-icon>
                </v-col>
            </v-row>
            <v-spacer />
        </v-card-title> 
        <v-card-text>
            <ListViewSearch 
                :modelColumns="users" 
                @item-active="itemActive"
                @item-selected="itemSelected"
                placeholder="Select User to impersonate"
                showLabel=false
                value="id"
                text="name"
                returnObject=true
                addOnClick = true
                :computedColumns="[]"
            >
        </ListViewSearch>
           />       
         </v-card-text>
    </v-card>
</v-dialog>
</template>
<script>
import {ListViewSearch} from 'frontendcomponent'
import UserService from "@/api/service/UserService";

export default {
    props:["showDialog","Button"],
    components:{
        ListViewSearch
    },
    data() {
        return {
            users:[],
            showDialogForUser:false,
        }
    },
    created() {
        this.showDialogForUser=this.showDialog
        this.getUsers()
    },
    watch:{
        showDialog() {
            this.showDialogForUser=this.showDialog
            this.getUsers()
        }
    },
    methods:{
        closeDialog() {
            this.showDialogForUser=false
            this.$emit('dialog-closed')
        },
        async itemActive($item) {
            let $token  = await UserService.impersonate({"id":$item.id})
            let $oldToken = localStorage.getItem('TOKEN')
            let $oldUserDetails = localStorage.getItem('user_details')
            localStorage.setItem('TOKEN',$token.desks[0].token)
            localStorage.setItem('user_details',JSON.stringify($token.userData))
            localStorage.setItem('OLD_TOKEN',$oldToken)
            localStorage.setItem('old_user_details',$oldUserDetails)
            this.closeDialog();
        },
        itemSelected($item) {
            console.log($item)
        },
        async getUsers() {
            try{
            this.users = await UserService.findAll();
            console.log(this.users)
            }
            catch(e){
                this.users =[]
                console.log(e)
            }
        }
    }
}
</script>