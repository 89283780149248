<template>
  <v-row no-gutters dense justify="space-between" class="pl-1 pr-4 py-0">
    <v-col style="display: flex;
    align-items: center;">
      <span v-if="selectedUser.id != defaltUser.id">
        Showing details of
        <b>{{ selectedUser ? selectedUser.first_name : "" }}&nbsp;{{
          selectedUser ? selectedUser.last_name : ""
        }}</b>
      </span>
      <span v-if="selectedUser.id == defaltUser.id">
        Hi&nbsp;
        <b>
          {{ userData ? userData.first_name : "" }}&nbsp;{{
            userData ? userData.last_name : ""
          }}</b>
      </span>
      &nbsp;
      <v-icon id="switch-user-menu-activator" style="cursor: pointer" color="#636363" size="14px">mdi mdi-pencil</v-icon>
    </v-col>
    <v-col align="end">
      <span style="font-size: 14px">
        <Calendar ref="dateSelect" v-model="dates" @dateSelect="onSelectDate()" :maxDate="new Date()"
          @update:model-value="dateChanged()" selectionMode="range" :manualInput="false" />

        {{ workTiming.name }} &nbsp; &nbsp;
        <v-icon class="pr-2">mdi-calendar</v-icon>
        &nbsp;
        <span v-if="dates && dates.length >= 1">
          {{ moment(dates[0]).format("MM/DD/YYYY") }}
        </span>
        <span v-if="dates && dates.length == 2">

          <span> &nbsp;- &nbsp;
            {{
              dates[1] &&
              moment(dates[0]).format("MM/DD/YYYY") !=
              moment(dates[1]).format("MM/DD/YYYY")
              ? moment(dates[1]).format("MM/DD/YYYY")
              : moment(dates[0]).format("MM/DD/YYYY")
            }}</span>
        </span>
        <span>
          <v-btn class="ma-0" variant="text" icon="mdi-chevron-left" size="medium"
            @click="changeDate('previous')"></v-btn>
          <v-btn class="ma-0" variant="text" icon="mdi-chevron-right" size="medium" @click="changeDate('next')"></v-btn>
        </span>
      </span>
    </v-col>
  </v-row>

  <SkeletonForDashboard v-if="loading === true" />

  <div v-else>
    <v-row class="pl-4 pr-4 mt-2 pb-3" style="height: 100vh;">

      <v-col cols="4" style="height: 100%; overflow: auto;" class="pa-0 pr-3">
        <!-- <OpenIssuesPL v-if="userData && userData.roles && userData.roles && userData.roles.code == 'PL'" class="pl-0 py-0" style="padding-right: 6px;"/> -->
        <!-- <AssignedToMe v-else   :userId="selectedUser.id" /> -->
        <AssignedToMe    :userId="selectedUser.id" />
      </v-col>


      <v-col cols="3" style="height: 100%;" class="pa-0">
        <div style="height: 58%;padding-bottom: 12px;border-radius: 4px;">
          <div class="cardTitle pl-3 pt-3" :style="showTimer ? 'height:39.4% ; margin-bottom:12px' : 'height:100%'">
            Upcoming Meetings
          </div>

          <Timer v-if="showTimer && userData.id == selectedUser.id" :showHomeCard="showTimer"
            @workInProgress="showTimer = false"></Timer>
        </div>

        <div style="height: 40.5%;" :class="showTimer? 'mt-4':''">
          <DoYouKnow :userId="selectedUser.id" />
        </div>
      </v-col>


      <v-col cols="5" class="pa-0 pl-3" style="height: 100%;">
        <v-row style="overflow: hidden; height: 66%;" class="mb-3">
          <v-col cols="7" style="overflow: auto;height: 100%;" class="py-0 mt-3">
            <TimeSheet :timeSheetData="timeSheetData" :userId="selectedUser.id" :id="userId"
              :fromDate="moment(this.dates[0]).format('YYYY-MM-DD')"
              :toDate="moment(this.dates[1]).format('YYYY-MM-DD')" />
          </v-col>


          <v-col cols="5" class="pl-0" style="overflow: auto;height: 100%;">
            <div style="display: grid;gap: 12px;">
              <div cols="3" md="4" lg="3" sm="5" v-for="item in dashBoardCardData" :key="item">
                <v-hover v-slot="{ isHovering, props }" open-delay="200">
                  <v-card rounded v-bind="props" :elevation="isHovering ? 16 : 0" :class="{ 'on-hover': isHovering }">
                    <v-row>
                      <v-col class="pa-7">{{ item.name }}
                        <v-col class="pa-0 pt-2">
                          <h2>
                            <!-- {{ item.hour}} -->
                            {{ timeConvert(item.name, item.hour) ? timeConvert(item.name, item.hour) : 0 }}<span
                              class="DashbordCartText">&nbsp;Hours</span>
                          </h2>
                        </v-col>
                      </v-col>
                      <v-col align="center" class="pt-10 pl-5">
                        <v-sheet rounded :color="item.bgcolor" :height="42" class="imageInSheet" :width="42">
                          <img :src="item.src" />
                        </v-sheet>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-hover>
              </div>
            </div>
          </v-col>
        </v-row>

        <v-row style="overflow: auto;height: 34%;width: 100%;">
          <ErrorTrends :userId="selectedUser.id" />
        </v-row>
      </v-col>

    </v-row>

  </div>

  <MenuOptions @menu-selected="userSelected" showAvatar="true" :options="users" activator="#switch-user-menu-activator" />

  <!-- <div v-if="showDialog"> -->

  <!-- <v-dialog v-model="dialog" :width="size.width">
    <createActivity :via="'dashbord'" ref="createActivity" @reload="reload()" />
    </div> -->
  <!-- </v-dialog> -->
</template>

<script>
import TimeSheet from "./TimeSheet.vue";
// import OpenIssuesPL from "./AllIssuesPl.vue";
import lodash from 'lodash';
// import Activity from "@/components/Activity.vue";
// import AddCreateTask from '@/components/AddCreateTask.vue';
// import ErrorCard from "./ErrorCard.vue";
import ErrorTrends from "./ErrorTrends.vue";
import commonService from "@/common.service";
import Calendar from "primevue/calendar";
//import TaskInProgress from "@/components/TaskInProgress.vue";
import MenuOptions from "@/menus/MenuOptions.vue";
import UserService from "@/api/service/UserService";
import Timer from "@/menus/Timer.vue";
// import createActivity from "@/modules/settings/CreateActivity.vue";
// import PrimeDataTable from "../../../components/PrimeDataTable.vue";
import TimeSheetService from "@/api/service/TimeSheet/TimeSheetService";
import moment from "moment-timezone";
import DoYouKnow from "./DoYouKnow.vue";
import AssignedToMe from "@/modules/issues/AssignedToMe.vue";
import SkeletonForDashboard from "./SkeletonForDashboard.vue";
// import { withScopeId } from "vue";
export default {
  components: {
    SkeletonForDashboard,
    TimeSheet,
    Calendar,
    AssignedToMe,
    // OpenIssuesPL,
    //TaskInProgress,
    // ErrorCard,
    MenuOptions,
    ErrorTrends,
    Timer,
    DoYouKnow,
    // Activity,
    // createActivity,
    // PrimeDataTable,
  },
  data() {
    return {
      loading: true,
      workTiming: {
        name: null,
      },
      users: [],
      dates: [],
      userId: null,
      selectedUser: {},
      dialog: false,
      showTimer: false,
      reloadTheActivity: false,
      size: {},
      defaltUser: {},
      showDialog: false,
      issueId: null,
      doubleCounter: null,
      columns: [],
      userData: null,
      timeSheetData: [],
      totalHours: [],

      date: new Date(),
      dashBoardCardData: [
        {
          name: "Productivity",
          hour: 0,
          code: "production",
          bgcolor: "#F8ECCE",
          src: require("@/assets/image/clock.svg"),
        },
        {
          name: "Meeting",
          hour: 0,
          code: "meeting",
          bgcolor: "#D7EAF4",
          src: require("@/assets/image/command.svg"),
        },
        {
          name: "Break",
          code: "break",
          hour: 0,
          bgcolor: "#F9E3F5",
          src: require("@/assets/image/cupcake.svg"),
        },
        {
          name: "Others",
          code: "others",
          hour: 0,
          bgcolor: "#F8F6CE",
          src: require("@/assets/image/color.svg"),
        },
      ],
    };
  },
  watch: {
    
    
    reloadTheData(){
          let timerData = JSON.parse(localStorage.getItem("timer"));
        if (timerData && timerData.type == "issue") {
          this.showTimer = true;
        } else {
          this.showTimer = false;
        }
    },
    getRefresh() {
      if (this.getRefresh) {
        this.getTimeCardDetails();
      } else {
        this.getTimeCardDetails();
      }
    },
    refreshProject() {
      if (this.$store.getters.getStartIssueId) {
        localStorage.setItem(
          "currentIsseId",
          this.$store.getters.getStartIssueId
        );
        this.getIssueTimerData();
      }
    },
    getTimerHide() {
      if (this.getTimerHide) {
        this.showTimer = false;
      } else {
        this.checkTimer();
      }
    },
    changeLocalStore() {
      console.log("timer");
    },
  },
  created() {
    let timerData = JSON.parse(localStorage.getItem("timer"));
    if (timerData && timerData.type == "issue") {
      this.showTimer = true;
    } else {
      this.showTimer = false;
    }
    // this.workTiming = JSON.parse(localStorage.getItem('_WORK_TIMING_'))
    let $val = localStorage.getItem("_WORK_TIMING_");
    if ($val) {
      this.workTiming = JSON.parse($val);
    }

    // this.dates.push(moment(new Date()).subtract(1,'days'))
    let d2 = new Date();
    d2.setDate(d2.getDate() - 1);
    this.dates.push(d2);
    this.dates.push(new Date());
    this.userData = JSON.parse(localStorage.getItem("user_details"));
    this.selectedUser = this.userData;
    this.defaltUser = JSON.parse(localStorage.getItem("user_details"));
    this.loadColumns();
    this.download = true;
    this.search = true;
    this.checkbox = true;
    this.getTimeCardDetails();
    this.getTimeSheetData();
    // this.getUsers()
    // let timerData = localStorage.getItem("timer") ? JSON.parse(localStorage.getItem("timer")) : null;
    // console.log(timerData, "bala")
    // this.issueId = timerData ? timerData.issueId : null
    // console.log(this.issueId, "gabnesh")
    //this.getTotalHours();
    this.getReporterUserData();
  },

  methods: {
    timeConvert(type, min) {
      let hours = (min) / 60;
      hours = hours.toFixed(2)
      return hours;
    },

    changeDate(direction) {
      let startDate = moment(this.dates[0]);
      let endDate = this.dates[1] ? moment(this.dates[1]) : moment(this.dates[0]);
      const maxDate = moment();
      const maxGap = maxDate.diff(endDate, 'days');
      const rangeGap = endDate.diff(startDate, 'days');

      if (rangeGap > 0) {
        if (direction === 'previous') {
          startDate.subtract(rangeGap, 'days');
          endDate.subtract(rangeGap, 'days');
        } else if (direction === 'next') {
          if (maxGap > 0) {
            startDate.add(rangeGap, 'days');
            endDate.add(rangeGap, 'days');
          }
        }

        this.dates = [startDate.toDate(), endDate.toDate()];
        this.getTimeSheetData();
        this.getTimeCardDetails();
      }
      else {
        if (direction === 'previous') {
          startDate.subtract(1, 'days');
          endDate.subtract(1, 'days');
        } else if (direction === 'next') {
          if (maxGap > 0) {
            startDate.add(1, 'days');
            endDate.add(1, 'days');
          }
        }

        this.dates = [startDate.toDate(), endDate.toDate()];
        this.getTimeSheetData();
        this.getTimeCardDetails();
      }
    },


    onSelectDate() {
      const calendar = this.$refs.dateSelect ?? null;
      if (
        Array.isArray(calendar.modelValue) &&
        lodash.some(calendar.modelValue, (item) => lodash.isNull(item))) { calendar.overlayVisible = false; }
    },
    moment,
    async getReporterUserData() {
      try {
        let res = await UserService.getReporterUserData();
        this.users = res ? res : [];
        this.users.unshift({ id: this.selectedUser.id, name: "My Space" });
        this.loading = false
      } catch (e) {
        this.users = [];
        console.log(e);
      }
    },
    dateChanged() {
      this.getTimeSheetData();
      this.getTimeCardDetails();
    },
    checkTimer() {
      let timerData = JSON.parse(localStorage.getItem("timer"));
      if (timerData && timerData.type == "issue") {
        this.showTimer = true;
      } else {
        this.showTimer = false;
      }
    },
    userSelected($item) {
      this.selectedUser = $item;
      this.getTimeSheetData();
      this.getTimeCardDetails();
    },
    async getUsers() {
      let $wsDefaults = localStorage.getItem("_WS_DEFAULTS_");
      if ($wsDefaults) {
        $wsDefaults = JSON.parse($wsDefaults);
      }
      if ($wsDefaults && $wsDefaults.workspace && $wsDefaults.workspace.id) {
        this.users = await UserService.getUsersForReview({
          work_space_id: $wsDefaults.workspace.id,
        });
      }
    },
    async getTimeCardDetails() {
      let $condn = {};
      if (this.dates.length == 2) {
        $condn.from_date = moment(this.dates[0]).format("YYYY-MM-DD");
        $condn.to_date = moment(this.dates[1]).format("YYYY-MM-DD");
      } else if (this.dates.length == 1) {
        $condn.from_date = moment(this.dates[0]).format("YYYY-MM-DD");
        $condn.to_date = moment(this.dates[0]).format("YYYY-MM-DD");
      }

      // if (this.userId) {
      //   $condn.user_id = this.selectedUser.id
      // }
      $condn.user_id = this.selectedUser.id;
      let res = [];
      try {
        res = await TimeSheetService.getDashboardPersonal($condn);
      } catch {
        res = [];
      }
      if (res.length > 0) {
        this.dashBoardCardData.map(k => k.hour = 0)
        this.dashBoardCardData.forEach((f) => {
          let data = res.filter(
            (t) => t.task_type.toLowerCase() == f.code.toLowerCase()
          );
          if (data.length > 0) {
            f.hour = data[0].hours && data[0].hours.toFixed(2);
          }
        });
      } else {
        this.dashBoardCardData.forEach((f) => {
          f.hour = 0;
        });
      }
    },
    async getTimeSheetData() {
      let $condn = {};
      if (this.dates.length == 2) {
        $condn.from_date = moment(this.dates[0]).format("YYYY-MM-DD");
        $condn.to_date = moment(this.dates[1]).format("YYYY-MM-DD");
      } else if (this.dates.length == 1) {
        $condn.from_date = moment(this.dates[0]).format("YYYY-MM-DD");
        $condn.to_date = moment(this.dates[0]).format("YYYY-MM-DD");
      }
      $condn.resource_id = this.selectedUser.id;
      let $dashboardData = await TimeSheetService.getAll($condn);
      this.timeSheetData = $dashboardData;
      // for(let $i=0;$i<this.dashBoardCardData.length;$i++) {
      //    $dashboardData.map((object,index) => {
      //     console.log(index)
      //         if(object.task_type == this.dashBoardCardData[$i].code) {
      //           this.dashBoardCardData.hour = object.hours
      //         }
      //   })
      //}
    },

    async getTotalHours() {
      let res = await TimeSheetService.findAll();
      for (let i = 0; i < res.length; i++) {
        var now = new Date(res[i].end_date).getTime();
        var then = new Date(res[i].start_date).getTime();

        var diff = now - then;

        var seconds = Math.floor(diff / 1000) % 60;
        var minutes = Math.floor(diff / (1000 * 60)) % 60;
        var hours = Math.floor(diff / (1000 * 60 * 60));

        var formattedTime =
          hours.toString().padStart(2, "0") +
          ":" +
          minutes.toString().padStart(2, "0") +
          ":" +
          seconds.toString().padStart(2, "0");
        var format =
          formattedTime == "NaN:NaN:NaN" ? "00:00:00" : formattedTime;
        this.totalHours.push(format);

        this.timeSheetData.forEach((e, index) => {
          e.totalHours = this.totalHours[index];
        });
      }
    },

    loadColumns() {
      this.columns = [
        {
          field: "created",
          header: "Date",
          style: { width: "100px", color: "" },
          toolTip: "Code",
          sortfield: true,
          type: "datetime",
        },
        {
          field: "project_name",
          header: "Project",
          style: { width: "100px", color: "" },
          toolTip: "Name",
          sortfield: true,
        },
        {
          field: "task_name",
          header: "Task",
          style: { width: "100px", color: "" },
          toolTip: "Modified",
          sortfield: true,
        },

        {
          field: "start_date",
          header: "Started by",
          style: { width: "100px", color: "" },
          toolTip: "Modified",
          sortfield: true,
          type: "datetime",
        },

        {
          field: "end_date",
          header: "Finished at",
          style: { width: "100px", color: "" },
          toolTip: "Code",
          sortfield: true,
          type: "datetime",
        },

        {
          field: "actual_hrs",
          header: "Total hours",
          style: { width: "100px", color: "" },
          toolTip: "Modified",
          sortfield: true,
        },
      ];
    },

    addActivity() {
      this.dialog = true;
      this.size = commonService.largeDialog();
    },
    getIssueTimerData() {
      this.issueId = localStorage.getItem("currentIsseId");
    },
    reload() {
      this.dialog = false;
      this.reloadTheActivity = true;
    },
  },
  computed: {
    
    reloadTheData() {
              return this.$store.getters.getReloadTheIssue;
          }, 
    getRefresh() {
      return this.$store.getters.getRefreshTimeSheet;
    },
    changeLocalStore() {
      return localStorage.getItem("hello");
    },
    getTimerHide() {
      return this.$store.getters.getHideTimer;
    },
    formattedDate() {
      return this.dates;
      // const options = { day: "numeric", month: "short", year: "numeric" };
      // return this.dates.toLocaleDateString("en-US", options);
    },
    formattedTime() {
      const options = { hour: "numeric", minute: "numeric", hour12: true };
      return this.date.toLocaleTimeString("en-US", options);
    },
    refreshProject() {
      return this.$store.getters.getStartIssueId;
    },
  },
};
</script>

<style scoped>
.imageInSheet {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cardTitle {
  background-color: #fff;
  color: #272727;
  font-size: 18px;
  font-weight: 700;
}

>>>.p-calendar .p-inputtext {
  flex: 0;
  width: 2%;
  position: relative;
  left: 258px;
  z-index: 1;
  background: transparent;
  border: none;
}

>>>.p-inputtext:enabled:focus {
  outline: none;
  outline-offset: 0;
  border: none;
  box-shadow: none;
  background-color: transparent;
  background: transparent;
}
</style>
