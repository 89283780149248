<template>
  <div>
    <v-row class="pa-5">
      <v-col>
        <v-row class="pt-3">
          <v-col cols="12">
            <v-row>
              <v-col cols="6" class="pb-0 pt-0" style="display: grid">
                <v-label
                  class="pb-1"
                  style="font-size: 14px; color: #272727; font-weight: 600"
                  >User Name
                  <span
                    v-if="!showReadOnly"
                    style="color: #cf0707; padding-left: 2px; font-weight: 700"
                    >*</span
                  ></v-label
                >
                <TextFieldComponent
                  placeholder="User Name"
                  v-model="users.username"
              /></v-col>
            </v-row>
            <v-row>
              <v-col cols="6" class="pb-0" style="display: grid">
                <v-label
                  class="pb-1"
                  style="font-size: 14px; color: #272727; font-weight: 600"
                  >Email
                  <span
                    v-if="!showReadOnly"
                    style="color: #cf0707; padding-left: 2px; font-weight: 700"
                    >*</span
                  ></v-label
                >
                <TextFieldComponent
                  placeholder="Email"
                  :disabled="true"
                  v-model="users.email"
                  :rules="emailRules"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" class="pb-0 pt-0" style="display: grid">
                <v-label
                  class="pb-1"
                  style="font-size: 14px; color: #272727; font-weight: 600"
                  >Mobile
                  <span
                    v-if="!showReadOnly"
                    style="color: #cf0707; padding-left: 2px; font-weight: 700"
                    >*</span
                  ></v-label
                >
                <TextFieldComponent
                  @keypress="allowNumbers()"
                  :disabled="showReadOnly"
                  placeholder="Mobile"
                  v-model="users.mobile"
                  :rules="mobileRules"
              /></v-col>
            </v-row>
            <v-row>
              <v-col cols="6" class="pb-0 pt-0" style="display: grid">
                <v-label
                  class="pb-1"
                  style="font-size: 14px; color: #272727; font-weight: 600"
                  >First Name
                  <span
                    v-if="!showReadOnly"
                    style="color: #cf0707; padding-left: 2px; font-weight: 700"
                    >*</span
                  ></v-label
                >
                <TextFieldComponent
                  placeholder="First Name"
                  :disabled="showReadOnly"
                  v-model="users.first_name"
                  :rules="firNameRules"
              /></v-col>
            </v-row>

            <v-row>
              <v-col cols="6" class="pb-0 pt-0" style="display: grid">
                <v-label
                  class="pb-1"
                  style="font-size: 14px; color: #272727; font-weight: 600"
                  >Last Name
                  <span
                    v-if="!showReadOnly"
                    style="color: #cf0707; padding-left: 2px; font-weight: 700"
                  ></span
                ></v-label>
                <TextFieldComponent
                  placeholder="Last Name"
                  :disabled="showReadOnly"
                  v-model="users.last_name"
              /></v-col>
              <v-col offset="12" cols="12">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Color
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700"></span></v-label>
            <br>
            <input class=" colorPick" type="color"  v-model="users.theme" >
          </v-col>
              </v-row>
            <v-row>
              <v-col align="end" class="dialogBottom">
                <v-btn class="mr-2 cancel"  @click="$router.go(-1)">Cancel</v-btn>
                <v-btn class="save" @click="save()">Save</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import randomColor from 'randomcolor'
import TextFieldComponent from "@/components/TextFieldComponent.vue";
// import AutoComplete from "@/components/ui/AutoComplete.vue";
import UserService from "@/api/service/UserService";
export default {
  props:['profileId'],
  components: {
    TextFieldComponent,
  },
  data() {
    return {
      showImage: true,
      showAvatar: false,
      showIcon: true,
      showReadOnly: false,
      showButton: false,
      userData: [],
      type: "edit",
      firstName: "",
      lastName: "",
      users: {
        email: null,
        mobile: null,
        first_name: null,
        last_name: null,
        username: null,
        image_profile_id: null,
      },
      emailRules: [(value) => !!value || "E-mail is Required."],
      mobileRules: [(value) => !!value || "Mobile is Required."],
      firNameRules: [(value) => !!value || "First Name is Required."],
      dateFormats: ["mm/dd/yyyy", "dd/mm/yyyy", "yyyy/mm/dd"],
      // projects: ["Xtend 3.0", "TVS", "Synergy", "manulife"],
      datas: [
        { id: 1, label: "Chip 1" },
        { id: 2, label: "Chip 2" },
        { id: 3, label: "Chip 3" },
        { id: 4, label: "Chip 4" },
      ],
      userDetails: JSON.parse(localStorage.getItem("user_details")),
    };
  },
  watch:{
    profileId(){
      if(this.profileId){
        this.users.image_profile_id =this.profileId
      }
    }
  },
  created() {
    if (this.$route.query.id) {
      this.getUserData(this.$route.query.id);
      this.type = this.$route.query.type == "edit" ? "edit" : "view";
    } else {
      this.getUserData(this.userDetails.id);
    }
  },
  methods: {
    randomColor,
    close() {
      this.$router.go(-1);
    },
    showEdit() {
      this.showReadOnly = false;
      this.showButton = true;
      this.showIcon = false;
    },
    allowNumbers(evt) {
      evt = evt ? evt : window.event;
      let expect = evt.target.value.toString() + evt.key.toString();
      if (!/^[+]?[0-9]*\.?[0-9]*$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    async getUserData(id) {
      let res = await UserService.findOne({ id: id });
      this.userData = res[0];
      console.log(this.userData, "check22222222222");
      this.users.id = this.userData.id;
      this.users.email = this.userData.email;
      this.users.first_name = this.userData.first_name;
      this.users.last_name = this.userData.last_name;
      this.users.username = this.userData.username;
      this.users.mobile = this.userData.mobile;
      this.users.image_profile_id = this.userData.image_profile_id;
      this.firstName = this.users.first_name;
      this.lastName = this.users.last_name;
      this.users.theme = this.userData.theme ? this.userData.theme : 
      randomColor({
            seed: Math.floor(256 * Math.random()),
            luminosity: 'dark',
            format: 'hex' // e.g. 'rgba(9, 1, 107, 0.6482447960879654)'
      });
      console.log(this.firstName, "check111111111111");
    },
    async save() {
      if (
        this.users.email &&
        this.users.first_name &&
        this.users.mobile &&
        this.users.username
      ) {
        try {
          this.users.name = `${this.users.first_name} ${this.users.last_name}`;
          this.showButton = false;
          this.showIcon = true;
          this.showReadOnly = true;
          let output = await UserService.update(this.users);
          let logUserData = output.data.data
          localStorage.setItem('user_details' , JSON.stringify(logUserData))
          this.$store.dispatch("setSnackbar", { text: "Updated Successfully" });
          this.$router.go(0)
          this.$emit("reload", true);
        } catch (e) {
          this.$store.dispatch("setSnackbar", {
            color: "error",
            text: "Something Went Wrong",
          });
        }
      }
    },
  },
};
</script>

<style scoped>
.boderbottom {
  border-bottom: 1px solid rgb(233 233 233);
}
.dialogBottom {
  bottom: 12px;
  z-index: 100;
  width: 100%;
  right: 12px;
  padding: 10px;
  white-space: break-spaces;
  border-radius: 0px 0px 0px 10px;
  background: none !important;
}
</style>
