<template>
    <v-card flat class="pa-3 " min-height="90vh">
       <v-card-title>
        Accounts
       </v-card-title>
       <v-card-text>
        <v-row no-gutters dense class="rowheader pa-2">
            <v-col cols="5" class="text-left">
                Name
            </v-col>
            <v-col cols="3" class="text-center">
                Open Issues
            </v-col>
            <v-col cols="2" class="text-left">
                Last Issue 
            </v-col>
        </v-row>
        <div class="ifNoAccount" v-if="accounts.length === 0">
            <h3 >No account available in selected Project</h3>
        </div>
        <!-- <div > -->
        <v-row dense v-else  v-for="account in accounts" :key="account">
           
            <v-col cols="5" class="text-left ">
                <span class="link" @click="getLink(account)">
                    <b>{{ account.code }}</b> - {{ account.name }}
                </span> 
            </v-col>
            <v-col cols="3" class="text-center">
                <v-chip class="open-issues">
                   {{ account.no_of_issues }}
                </v-chip>
            </v-col>
            <v-col cols="2" class="text-left date">
                {{ account.last_updated ? moment(account.last_updated).format('MM/DD/YY') : ''}}
    
                {{ account.last_updated ? moment(account.last_updated).fromNow() : ''}}
                
            </v-col>
            <v-col cols="12">
                <v-divider />
            </v-col> 
        </v-row>
    <!-- </div> -->
        
                <!-- <router-link :to="getLink(account)" class="link">
                   
                </router-link> -->
            <!-- </v-col>
           
            <v-divider />
        </v-row> -->
        <!-- </v-col></v-row> -->
       </v-card-text>
    </v-card>
    </template>
    <script>
    import WorkSpaceService from '@/api/service/WorkSpace/WorkSpaceService'
    import moment from 'moment'
    export default {
        components:{
               // PrimeDataTable
        },
        data() {
           
            return {
                projectId:null,
                workSpaceId:1,
                workspace:{},
                accounts:[],
                columns:[
                {
                    field: "code",
                    header: "Code",
                    style: { width: "100px", color: "" },
                    toolTip: "id",
                    sortfield: true,
                },
                {
                    field: "name",
                    header: "Name",
                    style: { width: "100px", color: "" },
                    toolTip: "name",
                    sortfield: true,
                },
                {
                field: "Open",
                header: "Open Issues",
                style: { width: "100px", color: "" },
                toolTip: "Open",
                sortfield: true,
                },
                {
                field: "updated",
                header: "Last Updated",
                style: { width: "100px", color: "" },
                toolTip: "Last Updated",
                sortfield: true,
                }
            ]
            }
        },
        computed: {
            workSpaceChanged() {
             return this.$route.query.workSpaceId
            }
        },
        watch:{
            workSpaceChanged() {
                let $wsDefaults = localStorage.getItem("_WS_DEFAULTS_")
                $wsDefaults  = JSON.parse($wsDefaults)
                this.workSpaceId = $wsDefaults.workspace.id
                this.projectId = $wsDefaults&& $wsDefaults.project && $wsDefaults.project.id?$wsDefaults.project.id : null;
                this.getAccounts()
            }
        },
        created() {
            this.$store.commit("SET_REFRESH_BREADCRUMP",false)
            let $wsDefaults = localStorage.getItem("_WS_DEFAULTS_")
            $wsDefaults  = JSON.parse($wsDefaults)
            this.workSpaceId = $wsDefaults&& $wsDefaults.workspace && $wsDefaults.workspace.id?$wsDefaults.workspace.id : null;
            this.projectId = $wsDefaults&& $wsDefaults.project && $wsDefaults.project.id?$wsDefaults.project.id : null;
            delete $wsDefaults.account
           // delete $wsDefaults.project
            delete $wsDefaults.category
            delete $wsDefaults.issue
    
            localStorage.setItem("_WS_DEFAULTS_",JSON.stringify($wsDefaults))
    
            this.$store.commit("SET_REFRESH_BREADCRUMP",true)
            this.getAccounts()
          
        },
        mounted() {
    
        },
        methods: {
            moment,
            getLink($account) {
                let $wsDefaults = localStorage.getItem("_WS_DEFAULTS_")
                $wsDefaults = JSON.parse($wsDefaults)
                
                $wsDefaults.account ={"id":$account.id,"name":$account.name}
                localStorage.setItem("_WS_DEFAULTS_",JSON.stringify($wsDefaults))
                this.$store.commit("SET_REFRESH_BREADCRUMP",true)
                this.$router.push(`/issueConsolidated`)
                //return 
            },
            getAccounts() {
                let obj ={
                    workSpaceId:this.workSpaceId
                }
                if(this.projectId && this.projectId>0){
                    obj.projectId =this.projectId
                }
                WorkSpaceService.getAccounts(obj)
                .then($projects =>{
                    this.workspace = $projects.workspace
                   const totalIssues = $projects.accounts.reduce((obj, { no_of_issues }) => obj + no_of_issues, 0);
                    console.log(totalIssues)
                    let $account = {"id":0,"code":"All","name":"All Accounts","no_of_issues":totalIssues}
                    this.accounts =$projects.accounts.length>1? [$account].concat($projects.accounts):[...$projects.accounts]
                    // console.log(this.accounts , 'catchh')
                    }).catch(error =>{
                    console.log(error)
                })
            }
        }
    }</script>
    <style>
        .rowheader {
            background-color:#D7EAF4;
            font-weight:600;
        }
        .link {
            color:#000;
            text-decoration: none;
        }
        .link:hover {
            color:#000;
            text-decoration: underline;
            cursor: pointer;
        }
        .open-issues {
            background-color:#E2D6F8;
            color:#272727;
            font-weight:10px;
            font-weight:400;
        }
        .date {
            color:orange;
        }
        .ifNoAccount{
            display: grid;
        place-items: center;
        height: 100px;
        }
    
    </style>