import ApiService from '../../service/apiService.js';
const querystring = require('querystring')
var DashboardWidgetService = {

    findAll: function ($data) {
        return new Promise(function (resolve, reject) {
            let $options = {};
            $options.url = "dashboardwidget"
            $options.method = "get"

            if ($data) {
                $options.url = "dashboardwidget?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
                .then(response => {
                    console.log('test');
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error);
                })
        });

    },

   
    findOne: function ($data) {
        return new Promise(function (resolve, reject) {
            let $options = {};
            $options.url = "dashboardwidget/" + $data
            console.log($options.url)
            $options.method = "get"
            if (!$data) {
                $options.data = $data
            }
            ApiService.request($options)
                .then(response => {
                    // console.log('Gpt the data');
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error);
                })
        });

    },
    update: function ($data) {
        return new Promise(function (resolve, reject) {
            let $options = {};
            $options.data = $data
            $options.url = "dashboardwidget/" + $data.id;
            $options.method = "post"
            console.log($options)
            ApiService.request($options)
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                });
        })
    },
    save: function ($data) {
        return new Promise(function (resolve, reject) {
            var $options = {};
            $options.data = $data
            $options.url = "dashboardwidget" + $data.id;
            $options.method = "post"
            console.log($options)
            ApiService.request($options)
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                });
        });
    },

    create: function ($data) {
        return new Promise(function (resolve, reject) {
            var $options = {};
            $options.data = $data
            $options.url = "dashboardwidget";
            $options.method = "post"
            console.log($options)
            ApiService.request($options)
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                });
        });
    },
    getWidgetData: function($data) {
        return new Promise(function(resolve, reject) {
            var $options = {};
            $options.data = $data
            $options.url = "widgetData";
            $options.method = "post"
            console.log($options)
            ApiService.request($options)
                .then(response => {
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error)
                });
        });
    },
   
};
export default DashboardWidgetService;