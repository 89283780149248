<template>
  <v-card style="border-radius: 8px">
    <v-row class="diolog-header">
      <v-card-title>{{viewType == 'edit' ?'Edit': viewType=='view' ? 'View':"Add" }} ErrorCode</v-card-title>
      <v-btn
        @click="close()"
        elevation="0"
        density="comfortable"
        icon="mdi-close"
        class="x-closeIcon"
      ></v-btn
    ></v-row>
    <v-form @submit.prevent="save()" ref="form">
    <v-container class="mt-8 mb-0 pa-6">
      <v-col cols="12" class="px-0 py-0" style="display: grid">
            <v-radio-group :disabled="showReadOnly" v-model="formData.active" inline>
              <v-radio color="primary" label="Active" :value="1"></v-radio>
              <v-radio color="error" label="Inactive" :value="0"></v-radio>
            </v-radio-group>
          </v-col>
        <v-col cols="4" class="pb-0 pl-0">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Code
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <TextFieldComponent density="compact" @keyup="checkCode()" placeholder="Code" :rules="codeRule"
              v-model="formData.code" :disabled="showReadOnly" @keydown="prevent($event)" />
            <v-error v-if="codeError">Already Exists!</v-error>
          </v-col>
        <v-col cols="9" class="pa-0">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Name
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <TextFieldComponent density="compact" placeholder="Name" :rules="nameRule"
              v-model="formData.name" :disabled="showReadOnly" />
        </v-col>
        <v-col cols="9" class="dropdown pa-0">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Error Group [E]
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <EnumAutoComplete density="compact" :showReadOnly="showReadOnly"  placeholder="Error Group" :selected="formData.error_group" :domain="'ISSUE-RESOLUTION'"
              @changeEnumData="changeErrorGroup($event)" :rules="errorGroupRule"/>
        </v-col>
        <v-col cols="9" class="dropdown pa-0">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Impact [E]
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <EnumAutoComplete density="compact" :showReadOnly="showReadOnly"  placeholder="Impact" :selected="formData.impact" :domain="'IMPACT'"
              @changeEnumData="changeImpact($event)" :rules="impactRule"/>
        </v-col>
        <v-col cols="9" class="pa-0">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Score
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <TextFieldComponent density="compact" placeholder="Score" :rules="scoreRule"
              v-model="formData.score" :disabled="showReadOnly" />
        </v-col>
    </v-container>
    <v-card-actions class="mr-3">
        <v-row justify="end" class="dialogBottom" v-if="!showReadOnly">
          <v-btn class="mr-2 cancel" @click="close()">Cancel</v-btn>
          <v-btn class="save" type="submit">Save</v-btn>
        </v-row>
    </v-card-actions>
  </v-form>
  </v-card>
</template>
<script>
import TextFieldComponent from "@/components/TextFieldComponent.vue";
// import AutoComplete from "@/components/ui/AutoComplete.vue";
import ErrorCodeService from "@/api/service/ErrorCode/ErrorCodeService";
import EnumAutoComplete from "@/components/others/EnumAutoComplete.vue";

export default {
  props: ["id","viewType"],
  components: {
    TextFieldComponent,
    EnumAutoComplete
    // AutoComplete
  },

  data() {
    return {
      codeRule:[(value) => (!!value) || "Code Is Required"],
      nameRule:[(value) => (!!value) || "Name Is Required"],
      errorGroupRule:[(value) => (!!value) || "Error Group Is Required"],
      impactRule:[(value) => (!!value) || "Impact Is Required"],
      scoreRule:[(value) => (!!value) || "Score Is Required"],
      showReadOnly: false,
      dialog: false,
      type: null,
      formData: {
          id:null,
          code:null,
          name:null,
          error_group:null,
          impact:null,
          score:null
      },
      codeError: false,
    };
  },

  watch: {
    id() {
      if (this.id) {
        this.formData = {};
        this.getById(this.id);
      }
    },
  },
  created() {
    if (this.id) {
      this.getById({id : this.id});
    }
    if (this.viewType) {
        this.showReadOnly = this.viewType == "edit" ? false : true;
      }
  },
  methods: {
    changeErrorGroup($event){
    this.formData.error_group = $event;
    },
    changeImpact($event){
    this.formData.impact = $event;
    },
    prevent(e) {
  if (!/^[a-zA-Z0-9-_ \t]+$/.test(e.key) && e.key !== "Backspace") {
    e.preventDefault();
  }
},
    checkCode() {
      if (this.formData.code && this.formData.code.length > 0) {
        this.formData.code = this.formData.code.toUpperCase();
        ErrorCodeService.findAll({ code: this.formData.code }).then((res) => {
          let $exist = res.filter((key) => key.code == this.formData.code);

          this.codeExistFlag =
            $exist.length > 0
              ? this.formData.id && $exist[0].id == this.formData.id
                ? false
                : true
              : false;

          if (this.codeExistFlag) {
            this.codeError = true;
          } else {
            this.codeError = false;
          }
        });
      }
    },
    close() {
      this.$emit("closeDialog");
    },
    async getById(id) {
      let res = await ErrorCodeService.findOne(id);
      this.formData = res[0];
    },

    async save() {
      let validation = await this.$refs.form.validate();
      if(!validation.valid || this.codeError){
        console.log("Not Valid")
      }
      else{
        if (this.formData.id) {
          await ErrorCodeService.save(this.formData);
          this.dialog = false;
          this.close();
          this.$emit("reload", true);
          this.formData = {};
        } else {
          await ErrorCodeService.create(this.formData);
          this.dialog = false;
          this.close();
          this.$emit("reload", true);
          this.formData = {};
        }
      }
    },
  },
};
</script>
