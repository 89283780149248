<template>
    <PrimeDataTable
      :title="'User'"
      :showFilter="true"
      :searchOption="true"
      :selectAll="true"
      :showDownload="true"
      :showUpload="true"
      :showAdd="true"
      :columnData="columns"
      :data="data"
      @addDiolog="addDiolog()"
      @rowAction="rowActions($event)"
      activity="user"
     
    />
  
    <v-dialog persistent v-model="dialog" width="700px">
      <CreateUser
        @closeDialog="close()"
        @reload="getUser()"
        :id="editId"
        :viewType="viewType"
        
      />
    </v-dialog>
  </template>
  
  <script>
  import CreateUser from "./CreateUser.vue";
  import PrimeDataTable from "@/components/PrimeDataTable";
  import UserService from "@/api/service/User/UserService";
  // import commonService from "@/common.service";
  //import RolePermissionService from '@/api/service/RolePermission/RolePermissionService'
  export default {
    components: {
        CreateUser,
      PrimeDataTable,
    },
    data() {
      return {
        data: [],
        columns: [],
        date: new Date(),
        editId:null,
        dialog: false,
        viewType:false
      };
    },
    created() {
      this.loadColumns();
      this.download = true;
      this.search = true;
      this.checkbox = true;
      this.getUser();
    },
    methods: {
  
      loadColumns() {
        this.columns = [
          {
            field: "name",
            header: "Name",
            style: { width: "100px", color: "" },
            toolTip: "name",
            sortfield: true,
          },
          {
            field: "username",
            header: "Username",
            style: { width: "100px", color: "" },
            toolTip: "username",
            sortfield: true,
          },
          {
            field: "email",
            header: "Email",
            style: { width: "100px", color: "" },
            toolTip: "email",
            sortfield: true,
          },
                   
          {
            field: "modified",
            header: "Modified",
            type:'datetime',
            style: { width: "100px", color: "" },
            toolTip: "mobile",
            sortfield: true,
          },
          {
          field: "status",
          header: "Status",
          style: { width: "100px", color: "" },
          toolTip: "Status",
          sortfield: true,
        },
        {
          field: "action",
          header: "Action",
          style: { width: "100px", color: "" },
          toolTip: "Action",
          sortfield: true,
          rowActions: [
            { action: "view" },
            { action: "edit" },
          ],
        },

        ];
      },
  
      close() {
        this.dialog = false;
      },
      rowActions($event) {
        if ($event.type == "view") {
          // this.dialog = true;
          this.editId = $event.data.id
          this.viewType=$event.type;
          this.$router.push({ path: '/setting/identity/userProfile', query: {id:$event.data.id,type:$event.type}  });
        }
        if ($event.type == "edit") {
          // this.dialog = true;
          this.editId = $event.data.id;
          this.viewType=$event.type;
          this.$router.push({ path: '/setting/identity/userProfile', query: {id:$event.data.id,type:$event.type}  });
        }   
  
        if ($event.type == "select") {
          // this.dialog = true;
          this.editId = $event.data.id;
          this.viewType="view";
          this.$router.push({ path: '/setting/identity/userProfile', query: {id:$event.data.id,type:$event.type}  });
        }
      },
      async getUser() {
        //let $access = await RolePermissionService.isAllowed('role','manage')
        //if($access) {
          let res = await UserService.findAll();
          this.data = res;
        //}
      },
      addDiolog() {
        this.dialog = true;
        this.editId = null;
      }
    },
  };
  </script>
  
  
  