<template>
    <v-layout>
        <v-navigation-drawer v-model="drawer" location="right" temporary :width="550"
            style="background: white; position: fixed; top: 71px;width: fit-content;" class="side-menu">

            <v-card density="compact" flat class="mainCard pa-4 pt-0 ">
                <div class="pt-4 cardTitle">
                    <div class="title pl-3 pb-2">Team Status</div>
                    <div style="display: flex;gap: 10px;">
                        <AutoComplete :items="workSpaces" item-title="name" v-model="obj.work_space_id" item-value="id"
                            placeholder="Select Workspace" @update:modelValue="getFilterData" />
                        <AutoComplete :items="workTiming" item-title="name" v-model="obj.shift_id" item-value="id"
                            placeholder="Select Shift" @update:modelValue="getFilterData" />
                    </div>
                </div>

                <v-card-item style="overflow: auto;" class="cardContents">
                    <div class="cardItem" v-for="user in users" :key="user.id">
                        <div style="display: flex;gap: 12px;">
                            <div>
                                <CustomAvatar2 :size="'medium'" 
                                :fName="user.first_name" 
                                :lName="user.last_name" 
                                :theme="user.theme" 
                                :imgUrl="user.image_url ? user.image_url: null"/>
                            </div>

                            <div class="userName">
                                {{ user.name }}
                            </div>
                        </div>

                        <div style="display: flex;gap: 30px;">
                            <div class="chip" v-if="user.work_timings.work_timing">
                                {{ user && user.work_timings && user.work_timings.work_timing ?
                                    user.work_timings.work_timing : '' }}
                            </div>

                            <div class="status d-flex align-center justify-center" :style="getColor(user.stage)" >
                                <i :class="getIcon(user.stage)" style="font-size: 6px;font-weight: 700;" />
                            </div>
                        </div>
                    </div>
                </v-card-item>
            </v-card>
        </v-navigation-drawer>
    </v-layout>
</template>

<script>
import AutoComplete from '@/components/ui/AutoComplete.vue';
import UserService from '@/api/service/UserService';
import WorkTimingService from '@/api/service/WorkTiming/WorkTimingService';
import WorkSpaceService from '@/api/service/WorkSpace/WorkSpaceService';
import CustomAvatar2 from '@/components/ui/CustomAvatar2.vue';
import commonService from "@/common.service";
export default {
    components: {
        AutoComplete,
        CustomAvatar2,
    },
    data() {
        return {
            drawer: false,
            users: [],
            workTiming: [],
            workSpaces: [],
            backupUserData: null,
            obj: {
                work_space_id: null,
                shift_id: null,
            }
        }
    },
    created() {
        this.getWorkTiming()
        this.getWorkSpaces()
        this.getUsers()
    },

    methods: {
        async getUsers() {
            try {
                let res = await UserService.getTeamStatus()
                let users = res && res.length > 0 ? res : []
                this.users  = await commonService.getSort(users, "name", "asc");
                this.backupUserData = JSON.parse(JSON.stringify(this.users))
            }
            catch (e) {
                console.log(e)
            }

        },

        async getWorkTiming() {
            let res = await WorkTimingService.findAll();
            this.workTiming = [{ name: 'All WorkTimings', id: null }, ...res];
        },

        getColor(status) {
            if (status === 'login') {
                return { background: '#2eac2e' }
            } 
            else if(status === 'in break'){
                return { background: '#fdda08' }
            }
            else if(status === 'in meeting'){
                return { background: '#ff0000' }
            }
            else {
                return { background: '#fff',color: '#000' }
            }
        },
        getIcon(status) {
            if (status === 'login') {
                return 'pi pi-check'
            } 
            else if(status === 'in break'){
                return 'pi pi-chevron-left'
            }
            else if(status === 'in meeting'){
                return 'pi pi-minus'
            }
            else {
                return 'pi pi-times'
            }
        },
        async getWorkSpaces() {
            try {
                let res = await WorkSpaceService.findAll();
                this.workSpaces = [{ name: 'All WorkSpaces', id: null }, ...res];
            } catch (error) {
                console.error('Error fetching workspaces:', error);
            }
        },
        async getFilterData() {
            let data = []
            if (this.obj.work_space_id || this.obj.shift_id) {
                for (let i = 0; i < this.backupUserData.length; i++) {
                    if (this.obj.work_space_id && this.obj.shift_id &&
                        this.backupUserData[i].work_space.includes(this.obj.work_space_id) &&
                        this.backupUserData[i].work_timing_id == this.obj.shift_id) {
                        data.push(this.backupUserData[i])
                    }
                    else if (this.obj.work_space_id && this.backupUserData[i].work_space.includes(this.obj.work_space_id) && !this.obj.shift_id) {
                        data.push(this.backupUserData[i])
                    }
                    else if (this.obj.shift_id && this.backupUserData[i].work_timing_id == this.obj.shift_id && !this.obj.work_space_id) {
                        data.push(this.backupUserData[i])

                    }
                }
                this.users = JSON.parse(JSON.stringify(data))

            }
            else {
                this.users = JSON.parse(JSON.stringify(this.backupUserData))
            }
        }


    }
}

</script>

<style scoped>
>>>.v-navigation-drawer__scrim {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 117vh;
    background: black;
    opacity: 0.2;
    transition: opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    z-index: 1;
}

>>>.v-navigation-drawer__content {
    flex: 0 1 auto;
    height: 93vh;
    padding-bottom: 12px;
    /* overflow: hidden; */
}

>>>.v-text-field .v-input__details {
    padding-inline-start: 16px;
    padding-inline-end: 16px;
    display: contents;
}
.mainCard {
    width: 400px;
    overflow: hidden;
}

.cardTitle {
    position: fixed;
    top: 0px;
    background-color: rgb(255, 255, 255);
    z-index: 999;
    right: 0px;
    width: 100%;
    padding: 0 19px;
    padding-bottom: 8px;
}

.cardContents {
    overflow: auto;
    position: relative;
    top: 105px;
    margin-bottom: 120px;
    padding: 0px 4px;
}

.title {
    color: #000;
    font-size: 18px;
    font-weight: 700;
}

.cardItem:nth-child(odd) {
    background: #edf5ff;
}

.cardItem:nth-child(even) {
    background: #e0e0e0;
}

.cardItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px;
    border-radius: 4px;
    margin-bottom: 3px;
}

.userName {
    color: #000;
    font-size: 12px;
    font-weight: 700;
    display: flex;
    align-items: center;
}

.chip {
    width: fit-content;
    height: fit-content;
    padding: 2px 5px;
    border-radius: 4px;
    border: 1px solid #FFCF53;
    background: #FEF0CC;
    color: #000;
    font-size: 10px;
    font-weight: 400;
    display: flex;
    align-items: center;
}

.status {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    color: #fff
}
</style>