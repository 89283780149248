<template>
  <v-card width="100%" style="border-radius: 12px; overflow: auto">
    <v-form @submit.prevent="save" ref="form" v-model="value">
      <v-card-item>
        <v-card-title>{{
          viewType == "edit" ? "Edit" : viewType == "view" ? "View" : "Add"
        }}
          <b> Work Space</b>
          <v-btn @click="close()" elevation="0" density="comfortable" icon="mdi-close" class="x-closeIcon"></v-btn>
        </v-card-title>
      </v-card-item>

      <v-card-text>
        <v-row no-gutters class="mt-3 pa-2">
          <v-col cols="12" class="px-0 py-0" style="display: grid">
            <v-radio-group :disabled="showReadOnly" v-model="formData.active" inline>
              <v-radio color="primary" label="Active" :value="1"></v-radio>
              <v-radio color="error" label="Inactive" :value="0"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="7">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Code
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <TextFieldComponent density="compact" placeholder="Code" :rules="codeRule" v-model="formData.code"
              :disabled="showReadOnly" @keyup="checkCode()" @keydown="prevent($event)" />
            <v-error v-if="codeError">Already Exists!</v-error>
          </v-col>
          <v-col offset="1" cols="4">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Color
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700"></span></v-label>
            <br>
            <input class=" colorPick" type="color"  v-model="formData.color_picker" >
          </v-col>
          <!-- <v-col cols="8"> &nbsp; </v-col> -->

          <v-col cols="12">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Name
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <TextFieldComponent density="compact" placeholder="Name" :rules="nameRule" v-model="formData.name"
              :disabled="showReadOnly" />
          </v-col>

          <v-col cols="12" class="dropdown">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Accounts
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700"></span></v-label>

            <AutoComplete :items="accountDetails" item-value="id" item-title="name" density="compact"
              placeholder="Select Accounts" :rules="AccountsRule" v-model="formData.account_id"
              @update:modelValue="accountChanged" :disabled="showReadOnly" />
            <v-chip class="selectedChip" closable label v-for="item in selectedAccounts" :key="item" :close="false"
              :disabled="showReadOnly" @click:close="cancelAccounts(item)">
              {{ item.name }}
            </v-chip>
          </v-col>

          <v-col cols="12" class="pt-3 dropdown">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Projects
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700"></span></v-label>

            <AutoComplete :items="projectDetails" item-value="id" item-title="name" density="compact"
              placeholder="Select Projects" :rules="ProjectsRule" v-model="formData.project_id"
              @update:modelValue="projectChanged" :disabled="showReadOnly" />

            <v-chip class="selectedChip" label closable v-for="item in selectedProjects" :key="item"
              :disabled="showReadOnly" @click:close="cancelProject(item)">
              {{ item.name }}
            </v-chip>
          </v-col>

          <v-col cols="12" class="pt-3 dropdown">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Stage
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700"></span></v-label>

            <AutoComplete :items="stageDetails" item-value="code" item-title="name" density="compact"
              placeholder="Select Stage" :rules="StageRule" v-model="formData.stage" @update:modelValue="stageChanged"
              :disabled="showReadOnly" />

            <v-chip class="selectedChip" label closable v-for="item in selectedStages" :key="item"
              :disabled="showReadOnly" @click:close="cancelStage(item)">
              {{ item.name }}
            </v-chip>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-action class="mr-3">
        <v-row justify="end" class="mr-6 dialogBottom" v-if="!showReadOnly">
          <v-btn class="mr-2 cancel" @click="dialog = close()">Cancel</v-btn>
          <v-btn class="save" :loading='saveClicked' type="submit">Save</v-btn>
        </v-row>
      </v-card-action>
    </v-form>
  </v-card>
</template>
<script>
import TextFieldComponent from "@/components/TextFieldComponent.vue";
// import EnumService from "@/api/service/Enum/EnumService";
import AutoComplete from "@/components/ui/AutoComplete.vue";
import WorkSpaceService from "@/api/service/WorkSpace/WorkSpaceService";
import AccountService from "@/api/service/Account/AccountService";
import ProjectService from "@/api/service/Project/ProjectService";
import commonService from "@/common.service";
import StageService from "@/api/service/Stage/StageService";
export default {
  props: ["id", "viewType"],
  components: {
    TextFieldComponent,
    AutoComplete,
  },
  data() {
    return {
      saveClicked: false,
      codeError: false,
      selectData: [],
      selectedAccountId: [],
      selectedAccounts: [],
      backupAccounts: [],
      accountDetails: [],
      selectedProjectsId: [],
      selectedProjects: [],
      backupProjects: [],
      projectDetails: [],
      selectedStageValue: [],
      selectedStages: [],
      backupStages: [],
      stageDetails: [],
      showReadOnly: false,
      dialog: false,
      type: null,
      codeRule: [(value) => !!value || "Code is Required."],
      nameRule: [(value) => !!value || "Name is Required."],
      // AccountsRule: [(value) => !!value || "Accounts is Required."],
      // ProjectsRule: [(value) => !!value || " Projects is Required."],

      formData: {
        id: null,
        code: null,
        name: null,
        account_id: null,
        project_id: null,
        stage: null,
        color_picker:'#ECECEC'
      },

      chips: [],
    };
  },
  computed: {
  defaultColor() {
    return this.formData.color_picker;
  },
},
  watch: {
    id() {
      if (this.id) {
        this.getById(this.id);
        this.getByIds(this.id);
      }
    },
  },
  async created() {
    // await this.getStageData()
    await this.getAccounts();
    await this.getProjects();
    await this.getStages();
    if (this.id) {
      await this.getById(this.id);
      // await this.getByIds(this.id);
    }
    if (this.viewType) {
      this.showReadOnly = this.viewType == "edit" ? false : true;
    }
  },
  methods: {
    prevent(e) {
  if (!/^[a-zA-Z0-9-_ \t]+$/.test(e.key) && e.key !== "Backspace") {
    e.preventDefault();
  }
},
    checkCode() {
      if (this.formData.code && this.formData.code.length > 0) {
        this.formData.code = this.formData.code.toUpperCase();
        WorkSpaceService.findAll({ code: this.formData.code }).then((res) => {
          let $exist = res.filter(key => key.code == this.formData.code)
          this.codeExistFlag = $exist.length > 0 ? (this.formData.id && $exist[0].id == this.formData.id) ? false : true : false;
          if (this.codeExistFlag) {
            this.codeError = true
          }
          else {
            this.codeError = false
          }
        });
      }
    },

    projectChanged($value) {
      this.selectedProjectsId.push($value);

      this.setData();
    },

    accountChanged($value) {
      this.selectedAccountId.push($value);
      this.setDatas();
    },

    stageChanged($value) {
      this.selectedStageValue.push($value);
      this.setDataStage();
    },

    setData() {
      this.formData.project_id = null;
      let chipRes = commonService.getMultipleChip(
        this.backupProjects,
        this.selectedProjectsId,
        "id"
      );

      this.projectDetails = commonService.getObjectSliceMethod(
        JSON.stringify(this.backupProjects),
        this.selectedProjectsId,
        "id"
      );
      this.selectedProjects = chipRes;
      this.projectDetails = [...this.projectDetails];
    },

    setDatas() {
      this.formData.account_id = null;
      let chipRes = commonService.getMultipleChip(
        this.backupAccounts,
        this.selectedAccountId,
        "id"
      );
      this.accountDetails = commonService.getObjectSliceMethod(
        JSON.stringify(this.backupAccounts),
        this.selectedAccountId,
        "id"
      );
      this.selectedAccounts = chipRes;
      this.accountDetails = [...this.accountDetails];
    },

    setDataStage() {
      this.formData.stage = null;
      let chipRes = commonService.getMultipleChip(
        this.backupStages,
        this.selectedStageValue,
        "code"
      );
      this.stageDetails = commonService.getObjectSliceMethod(JSON.stringify(this.backupStages),this.selectedStageValue,"code"
      );
      this.selectedStages = chipRes;
      this.stageDetails = [...this.stageDetails];
    },

    async getProjects() {
      await ProjectService.findAll().then(async ($options) => {
        this.projectDetails = $options.length > 0 ? await commonService.getSort($options,'name','asc') : [];
        this.backupProjects = JSON.parse(JSON.stringify(this.projectDetails));
      });
    },

    async getAccounts() {
      await AccountService.findAll().then(async ($options) => {
        this.accountDetails = $options.length > 0 ? await commonService.getSort($options,'name','asc') : [];
        this.backupAccounts = JSON.parse(JSON.stringify(this.accountDetails));
      });
    },

    async getStages() {
      
      try{
         let stages =await StageService.findAll()         
      this.stageDetails = stages.length > 0 ? await commonService.getSort(stages,'name','asc') : [];
      this.backupStages = JSON.parse(JSON.stringify(this.stageDetails));
      }
      catch(e){
        console.log(e)
      }
      },

    close() {
      this.$emit("closeDialog");
    },

    cancelProject(data) {
      let index = this.selectedProjectsId.findIndex((k) => k == data.id);
      if (index > -1) {
        this.selectedProjectsId.splice(index, 1);
      }
      this.setData();
    },

    cancelAccounts(data) {
      let index = this.selectedAccountId.findIndex((k) => k == data.id);
      if (index > -1) {
        this.selectedAccountId.splice(index, 1);
      }
      this.setDatas();
    },

    cancelStage(data) {
      let index = this.selectedStageValue.findIndex((k) => k == data.code);
      if (index > -1) {
        this.selectedStageValue.splice(index, 1);
      }
      this.setDataStage();
    },

    accountDetailFunction($value) {
      if ($value) {
        this.selectData.push($value);
      }
    },
    async getById(id) {
      let res = await WorkSpaceService.findOne(id);

      this.formData = res[0];

      this.selectedProjectsId =
        this.formData && this.formData.project_id
          ? JSON.parse(this.formData.project_id)
          : [];
      this.selectedAccountId =
        this.formData && this.formData.account_id
          ? JSON.parse(this.formData.account_id)
          : [];
      this.selectedStageValue =
        this.formData && this.formData.stage
          ? JSON.parse(this.formData.stage)
          : [];
      this.setData();
      this.setDatas();
      this.setDataStage();
    },

    async save() {
      let validation = await this.$refs.form.validate()
      if (!validation.valid || this.codeError) {
        console.log(validation.valid)
      }
      else {
        //  return arr.map(x => x[column])
        this.saveClicked=true
        this.formData.project_id = JSON.stringify(this.selectedProjectsId);
        this.formData.account_id = JSON.stringify(this.selectedAccountId);
        this.formData.stage = JSON.stringify(this.selectedStageValue);
        if (this.formData.id) {
          await WorkSpaceService.update(this.formData);
          this.$store.dispatch("setSnackbar", { text: "Updated Successfully" });
          this.dialog = false;
          this.close();
          this.$emit("reload", true);
          this.formData = {};
        } else {
          try {
            await WorkSpaceService.create(this.formData);
            this.$store.dispatch("setSnackbar", { text: "Saved Successfully" });
            this.dialog = false;
            this.close();
            this.$emit("reload", true);
            this.formData = {};
          } catch (e) {
            this.$store.dispatch("setSnackbar", {
              color: "error",
              text: "Something Went Wrong",
            });
          }
        }
      }
    },
  },
};
</script>
<style>
 .colorPick {
  /* width: 100%; */
  height: 40px;
 
} 

.selectedChip {
  background-color: #fae2ee;
  color: #ffffff;
  margin-right: 5px;
  margin-top: 5px;
  color: #333333;
}
</style>
  