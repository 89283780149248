<template>
  <v-container class="dialog" justify-center>
    <v-card elevation="0">
      <v-form @submit.prevent="save" ref="form">
        <v-card-item>
          <v-card-title class="ml-5">{{
            viewType == "edit"
            ? "Edit Quick"
            : viewType == "select"
              ? "View"
              : viewType == "view"
                ? "View Quick"
                : viewType == "child"
                  ? "Add Child"
                  : "Add Quick"
          }}
            Task
            <v-btn style="float: right" @click="close()" elevation="0" density="comfortable" icon="mdi-close"></v-btn>
          </v-card-title>
        </v-card-item>

        <v-card-text class="pa-5 ml-5">
          <v-row>

            <v-col cols="10" class="pt-0" v-if="showMoreDetails">
              <ButtonGroup :items="enumData" text="display_value" value="data_value" :selected="activePriority"
                returnObject=false @item-selected="(obj) => { setActiveButton(obj) }" />
            </v-col>

            <v-col cols="10" class="py-0" v-if="accountshow && showMoreDetails">
              <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Account
                <span style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
              <AutoComplete :items="accountListData" :placeholder="'Account'" :item-title="'name'"
                :disabled="showReadOnly" :item-value="'id'" v-model="formData.account_id"
                @update:modelValue="getDefaltSkillData" :rules="accountRule" />
            </v-col>

            <v-col cols="10" class="pt-0">
              <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Subject
                <span style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
              <TextFieldComponent placeholder="Subject" :rules="subjectRule" :disabled="showReadOnly"
                v-model="formData.name" />
            </v-col>

            <v-col cols="10" class="pt-0 dropdown" v-if="showMoreDetails">
              <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Sub Task Group</v-label>
              <AutoComplete :items="taskGroupData" :placeholder="'Sub TaskGroup'" :item-title="'name'"
                :disabled="showReadOnly" :item-value="'id'" v-model="taskgroupId" @update:modelValue="selectTaskGroup" />
            </v-col>
          </v-row>

          <v-row class="py-0 my-0">
            <v-col cols="5" class="py-0 my-0 dropdown">
              <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Next Action
                <span style="color: #cf0707; padding-left: 2px; font-weight: 700"></span></v-label>
              <AutoComplete :items="userData" :placeholder="'Next Action'" :item-title="'name'" :disabled="showReadOnly"
                :item-value="'id'" v-model="formData.next_action" />
            </v-col>

            <v-col cols="5" class="pt-0" v-if="selectedProject && selectedProject.skill == 1 && showMoreDetails">
              <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Skills
                <span style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
              <AutoComplete :items="skills" :placeholder="'Skill'" :item-title="'name'" :rules="selectedProject && selectedProject.skill == 1 ? skillRule : ''
                " :disabled="showReadOnly" v-model="formData.skill_id" :item-value="'id'" />
            </v-col>

            <v-col cols="5" class="py-0 my-0">
              <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Estimate</v-label>
              <TextFieldComponent placeholder="Estimate" :disabled="showReadOnly" @keydown="prevent1($event)"
                v-model="formData.estimate" />
            </v-col>

            <v-col cols="5" class="py-0 my-0 dropdown" v-if="showMoreDetails">
              <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Due Date
              </v-label>
              <TextFieldComponent density="compact" placeholder="Due Date" type="datetime-local" showtime hoursFormat="12"
                v-model="formData.end_date" :min="getCurrentDateTime()" />
            </v-col>

            <v-col cols="5" class="pt-0 dropdown" v-if="!showMoreDetails">
              <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Sub Task
                <span style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
              <AutoComplete :items="taskData" :placeholder="'Sub Task'" :item-title="'name'" :disabled="showReadOnly"
                :item-value="'id'" v-model="formData.task_id" :rules="taskRule" />
            </v-col>

            <v-col cols="5" class="pt-0" v-if="selectedProject && selectedProject.quantity == 1 && !showMoreDetails">
              <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Quantity</v-label>
              <TextFieldComponent placeholder="Quantity" :disabled="showReadOnly" @keydown="prevent($event)"
                v-model="formData.quantity" />
            </v-col>
          </v-row>



          <div v-if="showMoreDetails">
            <v-col cols="10" class="py-0" style="display: flex;justify-content: end;">
              <button type="button" @click="editFormDetails">
                <v-icon>mdi-pencil</v-icon></button>
              <v-tooltip v-if="editDetails" activator="parent">save with default task group</v-tooltip>
            </v-col>
            <v-row>
              <v-col cols="5" class="pt-0 dropdown">
                <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Sub Task
                  <span v-if="editDetails" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>

                <div v-if="!editDetails">{{ groupedData.task_name }}</div>
                <v-icon v-if="editDetails" size="25px" class="pl-4" color="#3C5AAA"
                  @click="addTask()">mdi-plus-circle</v-icon>
                <AutoComplete v-if="editDetails" :items="taskData" :placeholder="'Sub Task'" :item-title="'name'"
                  :disabled="showReadOnly || showReadOnly1" :item-value="'id'" v-model="formData.task_id"
                  :rules="taskRule" />
              </v-col>

              <v-col cols="5" class="pt-0 dropdown">
                <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Task Type
                  <span v-if="editDetails" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>

                <div v-if="!editDetails">{{ groupedData.issueType_name }}</div>
                <v-icon v-if="editDetails" size="25px" class="pl-4" color="#3C5AAA"
                  @click="addIssue()">mdi-plus-circle</v-icon>

                <AutoComplete v-if="editDetails" :items="issueTypeData" :placeholder="'Task Type'" :item-title="'name'"
                  :disabled="showReadOnly || showReadOnly1" :item-value="'id'" v-model="formData.issue_type_id"
                  :rules="issueTypeRule" />
              </v-col>
            </v-row>

            <v-row v-if="selectedProject &&
              (selectedProject.category_required == 1 ||
                selectedProject.sub_category == 1)
              ">
              <v-col cols="5" class="pt-0 dropdown" v-if="selectedProject && selectedProject.category_required == 1">
                <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Category
                  <span v-if="editDetails" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
                <div v-if="!editDetails">{{ groupedData.category_name }}</div>
                <v-icon v-if="editDetails" size="25px" class="pl-4" color="#3C5AAA"
                  @click="addCategory()">mdi-plus-circle</v-icon>

                <AutoComplete v-if="editDetails" :items="categoryData" :placeholder="'Category'" :item-title="'name'"
                  :disabled="showReadOnly" :item-value="'id'" v-model="formData.category_id"
                  @update:modelValue="selectCategory()" :rules="selectedProject && selectedProject.category_required == 1
                    ? categoryRule
                    : ''
                    " />


              </v-col>

              <v-col cols="5" class="pt-0 dropdown" v-if="selectedProject && selectedProject.sub_category == 1">
                <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Sub Category
                  <span v-if="editDetails" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>


                <div v-if="!editDetails">{{ groupedData.sub_category_name }}</div>
                <v-icon v-if="editDetails" size="25px" class="pl-4" color="#3C5AAA"
                  @click="addSubCategory()">mdi-plus-circle</v-icon>
                <AutoComplete v-if="editDetails" :items="subCategoryData" :placeholder="'Sub Category'"
                  :item-title="'name'" @update:modelValue="selectedSubCategory()"
                  :disabled="showReadOnly || showReadOnly1" :item-value="'id'" v-model="formData.sub_category_id" :rules="selectedProject && selectedProject.sub_category == 1
                    ? subCategoryRule
                    : ''
                    " />

              </v-col>
            </v-row>
          </div>

          <UdfsInputs v-if="showMoreDetails" class="pt-3" :status="toggle" :data="filteredUdfData"
            @formChanged="formChanged" />

            <div :class="!showDragContainer ? 'my-3' : ''" accept=null :style="showDragContainer ? 'height:100px;border:2px dashed #adadad' : ''" style="display: flex;">
            <v-row class="py-0 my-0" @dragleave="toggleGridLines" @dragover.prevent @dragenter="toggleGridLines" @drop="handleDrop">
              <h3 class="py-0 pl-3"><b>Attachment</b></h3>
              <v-col cols="1" class="py-0 pr-4" >
                <uploadFiles @documentsData="documentsData($event)" keyType="IS" :fileForUpload="fileForUpload" :inputType="InputType" />
              </v-col>
            </v-row>
          </div>
            <v-row class="py-0 my-0" style="margin-left: 0%" v-if="attachmentData && attachmentData.length > 0">
              <v-cols  v-for="(file, index) in attachmentData" :key="index">
                <v-chip  v-if="!showDragContainer" class="selectedChip" :close="true" closable close-icon="mdi-close-circle"
                close-transition="scale-transition" @click.stop="remove(index)">
                <span @click.stop="downloadDoc(file)">{{ file.doc_name }}</span>
              </v-chip>
            </v-cols>
          </v-row>

          <v-row :class="showDragContainer ? 'mt-3' : ''">
            <v-col cols="10" class="pt-0">
              <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Description
                <span style="color: #cf0707; padding-left: 2px; font-weight: 700"></span></v-label>
              <TextArea :data="formData.description" :showReadOnly="showReadOnly"
                @textData="changeDescription($event)"></TextArea>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="mr-3 ml-5">
          <v-row justify="end" class="dialogBottom pr-2" v-if="!showReadOnly">
            <v-btn class="mr-2 cancel" @click="close()">Cancel</v-btn>

            <v-btn class="save" :loading='saveClicked' type="submit">
              <span>Save</span>
            </v-btn>
          </v-row>
        </v-card-actions>
        <!-- </div> -->
        <!-- </v-container> -->
        <v-dialog persistent v-model="taskDialog" width="700px">
          <AddTask ref="AddTask" @closeDialog="setFalse()" @reload="getTaskData()" />
        </v-dialog>
        <!-- <v-dialog persistent v-model="taskGroupDialog" width="700px">
          <CreateTaskGroup
            ref="CreateTaskGroup"
            @closeDialog="setFalse()"
            @reload="getTaskGroupData()"
          />
        </v-dialog> -->

        <v-dialog v-model="issueDialog" width="700px" persistent>
          <AddIssueType ref="AddIsuueType" @closeDialog="setFalse()" @reload="getIssueTypeData()" />
        </v-dialog>

        <v-dialog persistent v-model="categoryDialog" max-width="500px">
          <AddCategory ref="AddCategory" @closeDialog="setFalse()" @reload="getDefaltValue()" :project_id="projectId"
            :category="this.categoryData" />
        </v-dialog>

        <v-dialog persistent v-model="subCategoryDialog" width="700px">
          <AddSubCategory :viewType="viewType" @closeDialog="setFalse()" @reload="getSubCategoryData()"
            ref="AddSubCategory" />
        </v-dialog>
      </v-form>
    </v-card>
  </v-container>
</template>
<script>
import UdfsInputs from "./UdfsInputs.vue";
import { ButtonGroup } from "frontendcomponent"
// import CreateTaskGroup from "../settings/Project/TaskGroup/CreateTaskGroup.vue";
import RiskService from "@/api/service/Risk/RiskService";
import uploadFiles from "@/components/uploadFiles.vue";
import TextFieldComponent from "@/components/TextFieldComponent.vue";
import UserService from "@/api/service/UserService";
import AutoComplete from "@/components/ui/AutoComplete.vue";
import ProjectService from "@/api/service/Project/ProjectService";
import AccountService from "@/api/service/Account/AccountService";
import TaskService from "@/api/service/Task/TaskService";
import TaskGroupService from "@/api/service/TaskGroup/TaskGroupService";
import IssueTypeService from "@/api/service/IssueType/IssueTypeService";
import IssueService from "@/api/service/Issue/IssueService";
import CategoryService from "@/api/service/Category/CategoryService";
import SubCategoryService from "@/api/service/SubCategory/SubCategoryService";
import TextArea from "@/components/ui/TextArea.vue";
import StageService from "@/api/service/Stage/StageService";
import documentService from "@/api/service/Document/DocumentService";
import commonService from "@/common.service";
import WsSettingService from "@/api/service/WsSetting/WsSettingService";
import AddTask from "../settings/Project/Task/AddTask.vue";
import AddIssueType from "../settings/Project/IssueType/AddIssueType.vue";
import AddCategory from "../settings/Project/Category/AddCategory.vue";
import AddSubCategory from "../settings/Project/SubCategory/AddSubCategory.vue";
import udfservice from "@/api/service/Udfs/UdfsService.js";
import EnumService from "@/api/service/Enum/EnumService";
import UserSkillService from "@/api/service/UserSkill/UserSkillService.js"

export default {
  name: "addProject",
  components: {
    TextFieldComponent,
    uploadFiles,
    AutoComplete,
    TextArea,
    ButtonGroup,
    AddTask,
    AddIssueType,
    AddCategory,
    AddSubCategory,
    UdfsInputs
    // CreateTaskGroup
  },
  props: ["id", "via", "childIssue", "defaultData", "viewType","parentSubject"],
  data() {
    return {
      saveClicked:false,
      showDragContainer:false,
      fileForUpload:null,
      InputType:null,
      selectedProject: null,
      projectRule: [(value) => !!value || "Project is Required"],
      accountRule: [(value) => !!value || "Account is Required"],
      taskRule: [(value) => !!value || "Task is Required"],
      // dueDateRule: [(value) => !!value || "Date is Required"],
      subjectRule: [(value) => !!value || "Subject is Required"],
      skillRule: [(value) => !!value || "Skill is Required"],
      issueTypeRule: [(value) => !!value || "IssueType is Required"],
      categoryRule: [(value) => !!value || "Category is Required"],
      subCategoryRule: [(value) => !!value || "Sub-Category is Required"],
      stageRule: [(value) => !!value || "Priority is Required"],
      taskDialog: false,
      // taskGroupDialog: false,
      issueDialog: false,
      categoryDialog: false,
      subCategoryDialog: false,
      add: false,
      showReadOnly1: true,
      type: "null",
      showReadOnly: false,
      defaultTaskGroupId: null,
      taskgroupId: null,
      size: {},
      workSpaceId: "",
      stageData: [],
      userData: [],
      issueData: [],
      accountListData: [],
      skills: [],
      formData: {
        quantity: 1,
        estimate: null,
        description: null,
        priority: "low",
        udf: null
      },
      common: null,
      items: [],
      selectSubCategory: [],
      groupedData: [],
      filteredUdfData: [],
      projectId: null,
      accountId: null,
      projectData: [],
      attachmentId: [],
      attachmentData: [],
      showMoreDetails: true,
      taskData: [],
      taskGroupData: [],
      categoryData: [],
      subCategoryData: [],
      udfData: [],
      accountshow: false,
      issueTypeData: [],
      suggestedIssue: [],
      udfFormData: {},
      defaultWsData: null,
      enumData: [],
      editDetails: false,
      toggle: false,
      shiftTimeData: [
        { value: "morning", display: "Morning-Shift" },
        { value: "mid", display: "Mid-Shift" },
        { value: "night", display: "Night-Shift" },
      ],
      projects: {
        project_type: "SC",
        execution_method: "S",
        stagings: [],
        customer_id: null,
        mgr_id: "",
        mgr_id2: null,
        track_stagings: "N",
      },
    };
  },

  async created() {    
    if(this.parentSubject){
        this.formData.name =this.parentSubject
      }
    await this.getSelectValue();
    this.getSkills();
    this.common = commonService;
    let workSpaceId = localStorage.getItem("workSpaceId");
    this.getStageDate();
    this.getUserData(workSpaceId);
    this.getTaskData();
    await this.getTaskGroupData();
    this.getIssueTypeData();
    this.getDefaltValue();
    this.getAccountListData();
    this.getIssueID();
    this.getSkills();
    await this.selectTaskGroup()
    await this.getUdfData();
    await this.getEnumData();
    this.formData.priority = "low";
    // let user = JSON.parse(localStorage.getItem("user_details"));
    // this.formData.next_action = user.id
    this.getUserData()
  },
  watch: {
    id() {
      if (this.id) {
        this.getById(this.id);
      }
    },
    parentSubject(){
      if(this.parentSubject){
        this.formData.name =this.parentSubject
      }
    },
    getComputedUdfData() {
      this.getUdfData()
    },
    childIssue() {
      if (this.childIssue) {
        console.log(this.defaultData);
      }
    },
    accountshow() {
      if (this.accountshow) {
        this.getAccountData();
      }
    },
    refreshProject() {
      if (this.$store.getters.getProjectId) {
        this.projectId = localStorage.getItem("_PROJECT_ID_");
        this.getProjectData();
      }
    },
    refreshAccount() {
      if (this.$store.getters.getAccountId) {
        let $wsDetails = localStorage.getItem("_WS_DEFAULTS_");
        $wsDetails = $wsDetails ? JSON.parse($wsDetails) : $wsDetails;
        this.accountId = $wsDetails.account.id;
        this.getAccountData();
      }
    },
  },
  computed: {
    getComputedUdfData() {
      return this.formData.sub_category_id
    },
    refreshProject() {
      return this.$store.getters.getProjectId;
    },
    refreshAccount() {
      return this.$store.getters.getAccountId;
    },
  },
  methods: {
    toggleGridLines() {
        this.showDragContainer=!this.showDragContainer
      },
      async handleDrop($event) {
        $event.preventDefault()
        this.showDragContainer=false
        this.fileForUpload = $event.dataTransfer.files
        this.InputType = 'drop'
        await this.getAttachementData();
        console.log(this.fileForUpload ,this.type  , 'started')
      },
    async getEnumData() {
      let res = await EnumService.findAll();
      this.enumData = res.filter((k) => {
        return k.domain === "PRIORITY";
      });
      this.activePriority = this.enumData[1].data_value
    },
    editFormDetails() {
      // this.editDetails =  !this.editDetails;
      if (this.editDetails) {
        this.formData.issue_type_id = this.groupedData.issue_type_id
        this.formData.task_id = this.groupedData.task_id;
        this.formData.category_id = this.groupedData.category_id;
        this.formData.sub_category_id = this.groupedData.sub_category_id;
        // alert('ji')
        this.editDetails = !this.editDetails;
      } else {
        this.editDetails = !this.editDetails;
      }

    },
    setActiveButton(button) {
      this.formData.priority = button;
      console.log(button, 'hihihihihh')
    },
    async getUdfData() {
      if (this.formData.sub_category_id) {
        let id = this.formData.sub_category_id;
        let response = await SubCategoryService.findOne(id);
        let selectSubCategory = response[0];
        console.log(selectSubCategory.udf_required, 'kkkkkkkkkkkkkkkkkkkkkk')
        let res = await udfservice.findAll();
        let data = res
        let udfData = data

        if (!selectSubCategory && !selectSubCategory.udf_required) {
          this.filteredUdfData = [];
          return [];
        } else {
          const udfRequiredIds = selectSubCategory && selectSubCategory.udf_required ? selectSubCategory.udf_required.slice(1, -1).split(',').map(item => parseInt(item.trim(), 10)) : '';
          console.log("udfRequiredIds:", udfRequiredIds);

          this.filteredUdfData = udfData.filter(item => udfRequiredIds.includes(item.id));
          console.log("FilteredudfData:", this.filteredUdfData);

          return this.filteredUdfData;
        }
      } else {
        console.log('no udf available for selected SUb-Category')
      }
    },

    formChanged($data) {
      this.udfFormData = $data
      console.log(this.udfFormData, 'udfffffffffff')
    },

    async selectTaskGroup($value) {
      if ($value) {
        try {
          let res = await TaskGroupService.findAllgroup({ taskGroupId: $value });
          this.groupedData = res[0];
          this.formData.issue_type_id = this.groupedData.issue_type_id
          this.formData.task_id = this.groupedData.task_id;
          this.formData.category_id = this.groupedData.category_id;
          this.formData.sub_category_id = this.groupedData.sub_category_id;
          if (this.groupedData.category_id) {
            this.formData.sub_category_id = this.groupedData.sub_category_id;
            // this.getUdfBySubCategory(true)
            this.getSubCategoryData(true)
            this.selectedSubCategory(true)
          }
          this.getDefaltSkillData()

        } catch (error) {
          console.error(error);
        }
      }
    },
    async getDefaltSkillData() {
      let userData = JSON.parse(localStorage.getItem("user_details"));
      try {
        let res = await UserSkillService.findAll({
          project_id: this.projectId,
          account_id: this.formData.account_id ? this.formData.account_id : this.accountId,
          category_id: this.formData.category_id,
          user_id: userData.id
        })
        if (res && res.length > 0) {
          this.getSkills(res[0].risk_id)
        }
        else {
          this.getSkills(null)

        }
      } catch (e) {
        console.log(e)
      }
    },

    getSelectValue() {
      let $wsDetails = localStorage.getItem("_WS_DEFAULTS_");
      $wsDetails = $wsDetails ? JSON.parse($wsDetails) : $wsDetails;
      this.defaultWsData = $wsDetails;
      if ($wsDetails && Object.keys($wsDetails).length > 0) {
        this.section = [];
        if (!$wsDetails.workspace) {
          this.$router.push(`/showWorkspaces`);
        }
        if (!$wsDetails.account) {
          this.$router.push("/showAccountForWorkspaces");
        } else if (!$wsDetails.project) {
          this.$router.push("/showProjectForWorkspaces");
        } else {
          if ($wsDetails.account.id !== 0) {
            this.accountId = $wsDetails.account.id;
            this.projectId = $wsDetails.project.id;
            this.workSpaceId = $wsDetails.workspace.id;
          } else {
            this.projectId = $wsDetails.project.id;
            this.workSpaceId = $wsDetails.workspace.id;
            this.accountshow = true;
          }
        }
      } else {
        this.$router.push("/showWorkspaces");
      }
      localStorage.setItem("_WS_REDIRECT_", "/addQuickIssue?from=topMenu");
    },
    prevent1(e) {
      if (!/^\d*\.?\d{0,2}$/.test(e.key) && e.key !== "Backspace") {
        e.preventDefault();
      }
    },
    getCurrentDateTime() {
      const now = new Date();
      const year = now.getFullYear();
      const month = (now.getMonth() + 1).toString().padStart(2, "0");
      const day = now.getDate().toString().padStart(2, "0");
      const hours = now.getHours().toString().padStart(2, "0");
      const minutes = now.getMinutes().toString().padStart(2, "0");
      return `${year}-${month}-${day}T${hours}:${minutes}`;
    },
    prevent(e) {
      if (!/^\d*(\.\d{1,2})?$/.test(e.key) && e.key !== "Backspace") {
        e.preventDefault();
      }
    },

    addTask() {
      this.taskDialog = true;
    },
    // addTaskGroup() {
    //   this.taskGroupDialog = true;
    // },
    addIssue() {
      this.issueDialog = true;
    },
    addCategory() {
      this.categoryDialog = true;
    },

    addSubCategory() {
      this.subCategoryDialog = true;
    },
    redirectToWorkspaces($type) {
      this.$store.dispatch("setSnackbar", {
        text: "Please Select Workspace/Account/Project first",
      });

      this.$router.push($type);
    },

    async getDefaltValue() {
      try {
        let data;
        if (this.childIssue) {
          this.showMoreDetails = false;
          data = this.defaultData;
          let $projects = await ProjectService.findAll({ id: data.project_id });
          this.selectedProject =
            $projects && $projects.length > 0 ? $projects[0] : {};
          let $categoryId = $projects[0].category_id;
          let res = await CategoryService.categoryById({
            categoryId: $categoryId ? $categoryId : "[]",
          });
          this.categoryData =
            res.length > 0
              ? await commonService.getSort(res, "name", "asc")
              : [];
          // console.log(this.categoryData,"category11111111111")

          this.formData.parent_issue_id = data.issue_parent_id;
        } else {
          let $projects = await ProjectService.findAll({ id: this.projectId });
          this.selectedProject =
            $projects && $projects.length > 0 ? $projects[0] : {};
          let $categoryId =
            $projects[0] && $projects.length > 0
              ? $projects[0].category_id
              : null;
          if ($categoryId) {
            let res = await CategoryService.categoryById({
              categoryId: $categoryId ? $categoryId : "[]",
            });
            this.categoryData =
              res.length > 0
                ? await commonService.getSort(res, "name", "asc")
                : [];
            // console.log(this.categoryData,"cate222222222")

          }
          let res = await WsSettingService.findAll({
            work_space_id: this.workSpaceId,
            project_id: this.projectId,
            account_id: this.accountId,
          });
          data =
            res && res.length > 0 && res[0].default_name
              ? JSON.parse(res[0].default_name)
              : {};
          this.showReadOnly1 = false;
        }
        if (this.formData.parent_issue_id) {
          this.formData.account_id = data && data.account_id ? data.account_id : null;
          this.formData.end_date = data && data.end_date ? data.end_date : null;
          this.formData.skill_id = data && data.skill_id ? data.skill_id : null;
          this.formData.task_id = data && data.task_id ? data.task_id : null;
          this.formData.issue_type_id = data && data.issue_type_id ? data.issue_type_id : null;
          this.formData.category_id = data && data.category_id ? data.category_id : null;
          if (this.formData.category_id) {
            await this.getSubCategoryData("refresh");
          }

          this.formData.sub_category_id = data && data.sub_category_id ? data.sub_category_id : null;
          this.formData = { ...this.formData };
        }
      } catch (e) {
        console.log(e);
      }
    },

    selectDate($event) {
      this.formData.date = $event;
    },

    changePriority($event) {
      this.formData.priority = $event;
    },
    getBreadcrumbs() {
      this.getProjectData();
      this.getAccountData();
    },
    async documentsData($event) {
      console.log($event.id ,$event ,'kya chal rha hai')
      this.attachmentId.push($event.id);
      await this.getAttachementData();
    },
    async getAttachementData() {
      if (this.attachmentId.length > 0) {
        try {
          let res = await documentService.getAllDocuments({
            doc_id: JSON.stringify(this.attachmentId),
          });
          this.attachmentData = res;
          this.$forceUpdate();
        } catch (e) {
          console.log(e);
        }
      }
    },
    downloadDoc(item) {
      documentService.download({ id: item.id }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("name", "downloadLink");
        link.setAttribute("download", response.headers["x-suggested-filename"]);
        document.body.appendChild(link);
        link.click();
      });
    },
    changeDescription($event) {
      this.formData.description = $event;
    },

    async remove(index) {
      this.attachmentId.splice(index, 1)
      this.attachmentId = [...this.attachmentId]
      console.log(this.attachmentId, "hello")
      await this.getAttachementData();
      console.log(index, "hello atta ", this.attachmentId)

    },

    async getStageDate() {
      try {
        let res = await StageService.findAll();
        this.stageData = res;
      } catch (e) {
        this.stageData = [];
        console.log(e);
      }
    },
    selectCategory() {
      if (this.formData.category_id) {
        this.getSubCategoryData();
      }
    },
    async selectedSubCategory() {
      if (this.formData.sub_category_id) {
        this.toggle = !this.toggle
        await this.getUdfData()
      }
    },

    getTextData($event) {
      this.formData.description = $event;
    },
    setFalse() {
      this.$emit("closeDialog");
      this.$emit("reload", true);
      this.taskDialog = false;
      // this.taskGroupDialog = false;
      this.issueDialog = false;
      this.categoryDialog = false;
      this.subCategoryDialog = false;
    },
    async getById(id) {
      let res = await IssueService.findOne(id);
      this.formData = res[0];
      this.attachmentId =
        this.formData && this.formData.attachment
          ? JSON.parse(this.formData.attachment)
          : "";
      this.getAttachementData();
    },
    async getTaskData() {
      let res = await TaskService.findAll({ task_type: "production" });
      this.taskData =
        res.length > 0 ? await commonService.getSort(res, "name", "asc") : [];
    },
    async getTaskGroupData() {
      let projectId = JSON.parse(localStorage.getItem('_WS_DEFAULTS_'))
      let res = await TaskGroupService.findAll({ project_id: projectId.project.id });

      if (res && res.length > 0) {
        this.taskGroupData = res.length > 0 ? await commonService.getSort(res, "name", "asc") : [];
        this.defaultTaskGroupId = this.taskGroupData[0].id
        this.taskgroupId = this.defaultTaskGroupId
        this.selectTaskGroup(this.taskgroupId)
      }
    },
    async getProjectData() {
      try {
        let res = await ProjectService.findOne({ id: this.projectId });
        this.projectData = res && res.length > 0 ? res[0] : {};
        let $obj = {
          title: this.projectData.name,
          disabled: false,
          href: "projectList",
        };
        this.items[0] = $obj;
      } catch (e) {
        this.projectData = {};
      }
    },

    async getAccountListData() {
      try {
        let res = await AccountService.findOne(this.accountId);
        this.accountData = res && res.length > 0 ? res[0] : {};
        let $obj = {
          title: this.accountData.name,
          disabled: false,
          href: "accountList",
        };
        this.items[1] = $obj;
      } catch (e) {
        this.accountData = {};
      }
    },
    async getAccountData() {
      try {
        let res = await AccountService.findAll(this.accountId);
        this.accountListData = res
      } catch (e) {
        this.accountListData = [];
      }
    },
    async getUserData() {
      try {
        let res = await UserService.getNextAction({
          work_space_id: this.defaultWsData.workspace.id,
          account_id: this.defaultWsData.account.id,
          project_id: this.defaultWsData.project.id,
        });
        this.userData =
          res.length > 0 ? await commonService.getSort(res, "name", "asc") : [];
      } catch (e) {
        this.userData = [];
        console.log(e);
      }
    },
    async getSubCategoryData(type) {
      let res = await SubCategoryService.findAll({
        category_id: this.formData.category_id,
      });

      if (!type) {
        this.formData.sub_category_id = null;
      }
      this.subCategoryData =
        res.length > 0 ? await commonService.getSort(res, "name", "asc") : [];
    },

    async getIssueTypeData() {
      let res = await IssueTypeService.findAll();
      this.issueTypeData =
        res.length > 0 ? await commonService.getSort(res, "name", "asc") : [];
      console.log(this.issueTypeData, 'bala')

    },
    async getIssueID() {
      let res = await IssueTypeService.findAll({ code: "D2D" });
      this.suggestedIssue = res[0]
      this.formData.issue_type_id = this.suggestedIssue && this.suggestedIssue.id?this.suggestedIssue.id:null
    },
    close() {
      if (this.childIssue) {
        this.$emit("closeDialog", true);
      } else {
        this.$router.push("/issueconsolidated");
      }
    },

    async getSkills(riskId) {
      let res = await RiskService.findAll();
      this.skills =
        res.length > 0 ? await commonService.getSort(res, "name", "asc") : [];
      let fData
      if (riskId) {
        fData = this.skills.filter(k => k.id == riskId)

      }
      else {
        fData = this.skills.filter(k => k.code.toUpperCase() == 'BEGINNER')
      }
      this.formData.skill_id = fData && fData.length > 0 ? fData[0].id : null
    },

    async save() {
      if (!this.editDetails) {
        this.formData.issue_type_id = this.groupedData.issue_type_id
        this.formData.task_id = this.groupedData.task_id;
        this.formData.category_id = this.groupedData.category_id;
        this.formData.sub_category_id = this.groupedData.sub_category_id;
      }
      this.formData.attachment = JSON.stringify(this.attachmentId);
      this.formData.udf = JSON.stringify(this.udfFormData);
      this.workSpaceId = localStorage.getItem("workSpaceId");
      let userData = JSON.parse(localStorage.getItem("user_details"));
      console.log(userData.id, 'kas')
      let validation = await this.$refs.form.validate();
      if (!validation.valid) {
        console.log(validation.valid);
      } else {
        this.saveClicked=true
        this.formData.end_date = await commonService.changeTheDateToUtc(this.formData.end_date, Intl.DateTimeFormat().resolvedOptions().timeZone);
        if (this.formData.id) {
          await IssueService.update(this.formData);
          this.dialog = false;
          this.close();
          this.setFalse();
          this.$emit("reload", true);
          this.formData = [];
          if (this.via) {
            this.$store.commit("RELOAD_ACTIVITY", true);
          }
        } else {
          this.formData.owner_id = userData.id;
          this.formData.project_id = this.projectId;
          if (!this.formData.account_id) {
            this.formData.account_id = this.accountId;
          }
          this.formData.work_space_id = this.workSpaceId;
          this.formData.user_id = userData.id;
          this.formData.watchers = JSON.stringify([]);
          if (this.$route.query.parent_id) {
            this.formData.parent_issue_id = this.$route.query.parent_id;
          }
          this.formData.stage = "open";
          let output = await IssueService.create(this.formData);
          console.log(output, "kkkkk");
          await documentService.bulkUpdate({
            docId: this.formData.attachment,
            key_id: output.data.data.id,
          });
          this.dialog = false;
          this.close();
          this.setFalse();
          this.$emit("reload", true);
          this.formData = [];
          if (this.via) {
            this.$store.commit("RELOAD_ACTIVITY", true);
          }
        }
      }
    },
    cancel() {
      this.$emit("closeDialog");
      // this.issuetype=[];
    },
  },
};
</script>


<style scoped>
>>>.v-text-field .v-input__details {
  padding-inline-start: 0px;
  padding-inline-end: 16px;
}

.dialog {
  overflow-y: scroll;
}

.bred {
  top: 3px;
  position: absolute;
  padding-left: 1%;
}

.more {
  text-decoration: underline;
  color: rgb(85 26 139);
  cursor: pointer;
}

.box {
  background-color: #eef0ff;
  list-style: none;
  padding: 0px 20px;
  border-radius: 12px;
  margin-top: 10px;
  height: 50px;
}

>>>.dropdown .v-messages__message {
  line-height: 12px;
  color: red;
  word-break: break-word;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  hyphens: auto;
  padding-left: 0px;
  transition-duration: 150ms;
  text-align: left;
  margin-left: 0px;
}</style>