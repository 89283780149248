<template>
    <PrimeDataTable class="pl-0" :title="'Time Sheets'" :showFilter="true" :searchOption="true" :selectAll="true"
        :showDownload="true" :showUpload="false" :showAdd="true" :columnData="columns" :data="timeSheetData"
        @addDiolog="addDiolog()"  @rowAction="rowActions($event)" activity="timesheet"/>
        <v-dialog v-model="dialog" :width="900" persistent>
    <AddTimeSheets
      ref="TimeSheets"
      @closeDialog="close()"
      @reload="getTimeSheetData(this.userDetails.id)"
      :id="timeSheetId"
      :viewType="viewType"
      :taskType1="taskType"
      :timeSheetDefaults="timeSheetData"
    />
  </v-dialog>
</template>

<script>
import TimeSheetService from "@/api/service/TimeSheet/TimeSheetService";
import PrimeDataTable from "@/components/PrimeDataTable.vue";
import AddTimeSheets from  "../../modules/TimeSheet/AddTimeSheets.vue";
import commonService from "@/common.service";
export default {
    components: {
        PrimeDataTable,
        AddTimeSheets
    },

    data() {
        return {
            dialog:false,
            download: false,
            search: false,
            checkbox: false,
            timeSheetData: [],
            timeSheetId:'',
            taskType:'',
            taskData:[],
            userDetails: JSON.parse(localStorage.getItem("user_details")),
        }
    },
    created() {
        this.loadColumns();
        this.download = true;
        this.search = true;
        this.checkbox = true;
        this.getTimeSheetData(this.userDetails.id);
       
        // this.getUsers()
    },
    methods: {
        addDiolog(){
            this.dialog=true
            this.timeSheetId=''
            this.viewType= false

        },
        close(){
            this.dialog=false
            this.getTimeSheetData(this.userDetails.id)
        },
        rowActions($event){
            console.log($event,'chkkkkk')
            if ($event.type == "view") {
        this.dialog = true;
        this.viewType = $event.type;
        this.timeSheetId=$event.data.id
        this.getOneTimesheetData(this.timeSheetId)
        console.log("view");
      }
      if ($event.type == "select") {
           this.dialog = true;
           this.viewType = 'view';
         this.timeSheetId=$event.data.id
         this.getOneTimesheetData(this.timeSheetId)
        console.log("select");
      }
        },

        async getOneTimesheetData(id){
          let res = await TimeSheetService.getTimesheetId({timesheet_id:id})
          this.taskData=res
          console.log(this.taskData[0].task_type,'alert4444')
          this.taskType=this.taskData[0].task_type
          

        },
        async getTimeSheetData(id) {
           
            try{
            let $dashboardData = await TimeSheetService.getTimeSheetByUser({'resource_id':id});
            this.timeSheetData = await commonService.getSort($dashboardData, 'created', 'desc');
            }
            catch(e){
            this.timeSheetData=[]
            console.log(e);

            }
        },

        loadColumns() {
            this.columns = [
                {
                    field: "created",
                    header: "Date",
                    style: { width: "100px", color: "" },
                    toolTip: "Code",
                    sortfield: true,
                    type: "datetime",
                },
                {
                    field: "project_name",
                    header: "Project",
                    style: { width: "100px", color: "" },
                    toolTip: "Name",
                    sortfield: true,
                },
                {
                    field: "task_name",
                    header: "Task",
                    style: { width: "100px", color: "" },
                    toolTip: "Modified",
                    sortfield: true,
                },

                {
                    field: "start_date",
                    header: "Started at",
                    style: { width: "100px", color: "" },
                    toolTip: "Modified",
                    sortfield: true,
                    type: "datetime",
                },

                {
                    field: "end_date",
                    header: "Finished at",
                    style: { width: "100px", color: "" },
                    toolTip: "Code",
                    sortfield: true,
                    type: "datetime",
                },

                {
                    field: "actual_hrs",
                    header: "Total hours",
                    style: { width: "100px", color: "" },
                    toolTip: "Modified",
                    sortfield: true,
                },
                {
          field: "action",
          header: "Action",
          style: { width: "100px", color: "" },
          toolTip: "Action",
          sortfield: true,
          rowActions: [
            
            { action: "view" },
            // { action: "edit" },  
            // { action: "delete" },
          ],
        },
            ];
        },
    }
}
</script>
<style></style>